import Bugsnag, { BreadcrumbType } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import { getUser } from "./helperMethods";
import { APP_VERSION } from "../constants";

const BugSnagTypes = { STATE: "state", ERROR: "error" };

export const bugSnagInititalize = () => {
  const isEnabledReleaseStage = Boolean(
    process.env.REACT_APP_BUG_SNAG_IS_ENABLED_RELEASE_STAGE
  );
  const enabledReleaseStages = isEnabledReleaseStage
    ? [process.env.REACT_APP_ENV]
    : [];

  Bugsnag.start({
    apiKey: isEnabledReleaseStage
      ? process.env.REACT_APP_BUG_SNAG_API_KEY
      : "noAPIKeyNeeded",
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
      const user = getUser();
      event.setUser(user.id, user.email, user.full_name);
    },
    enabledReleaseStages,
    releaseStage: process.env.REACT_APP_ENV,
    appVersion: APP_VERSION,
  });
  return Bugsnag.getPlugin("react").createErrorBoundary(React);
};

export const bugSnagNotifyAPIError = (
  apiName: string,
  requestPayload: Object,
  responseErrorMessage: string
) => {
  Bugsnag.leaveBreadcrumb(
    `Failed API: ${apiName}`,
    requestPayload,
    BugSnagTypes.ERROR as BreadcrumbType
  );
  Bugsnag.notify(responseErrorMessage);
};
