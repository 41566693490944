import React from "react";
import { Link, useHistory } from "react-router-dom";
import styleClasses from "./NavbarComponent.module.scss";
import { NavLink } from "react-router-dom";
import * as CONSTANT from "../../constants";
import { CateringUsericon, UserCircleIcon, UserCircleIconMobile } from "../../images/svgIcons/svg";

type Props = {
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function GuestNavComponent({ setExpanded }: Props) {
  const history = useHistory();
  const routeToIndividualSignIn = () => {
    setExpanded(false)
    history.push(CONSTANT.ROUTE_LOGIN);
  }

  const routeToCateringSignIn = () => {
    window.location.href = CONSTANT.CATERING_CAFE_ZUPAS_LOGIN_URL
  }
  return (
    <>
      <div className="d-md-none pt-4">
        <p className="text-start font-Cls f-s18 clr-dark-gray">Please Select Account Type</p>
        <hr className="my-3" />
        <Link onClick={() => { setExpanded(false) }} to={CONSTANT.ROUTE_LOGIN}
          className="clr-lt-black no-underline">
          <h5 className="d-flex align-items-center text-start text-uppercase f-s18 clr-dark-gray ls-1 font-Visby-cf f-w8">
            <span className="d-md-none"><UserCircleIconMobile />  </span>  <span className="d-none d-md-block">  <UserCircleIcon /></span>  <div className="ms-2">individual Loyalty </div> </h5>
        </Link>
        <hr className="my-3" />
        <Link onClick={routeToCateringSignIn} to={CONSTANT.CATERING_CAFE_ZUPAS_LOGIN_URL}
          className="clr-lt-black no-underline">
          <h5 className="d-flex align-items-center text-start text-uppercase f-s18 clr-dark-gray ls-1 font-Visby-cf f-w8">
            <CateringUsericon />
            <div className="ms-2"> Catering rewards </div>
          </h5>
        </Link>
        <hr className="my-3" />
      </div>
      <p className="font-Cls f-s20 text-center my-4 f-w3">
        Join today to start receiving exclusive Café Zupas rewards, updates and seasonal menus.
      </p>
      <NavLink
        onClick={() => setExpanded(false)}
        to={CONSTANT.ROUTE_CREATE_ACCOUNT}
        className="btn-large mb-3 f-s14 lh-normal"
      >
        CREATE MY ACCOUNT
      </NavLink>
      <p className={`${styleClasses.login_btn_wrapper} f-s14 pb-3 mb-5`}>
        Already have an account?{" "}
        <Link
          to={CONSTANT.ROUTE_LOGIN}
          onClick={() => setExpanded(false)}
          className={`${styleClasses.login_btn} font-Vcf`}
        >
          Sign In
        </Link>
      </p>
    </>
  );
}
