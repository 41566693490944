import { ADD_ONS, CORE_MODIFIERS, CREATE_YOUR_OWN_SALAD_OPTIONALS, FULL_SIZE, HALF_SIZE, TRY_2_COMBO_ITEM_REQUIRED_MODIFIERS } from '../constants';
import { Codes } from '../models/item.model';
import { IItemExtraPriceWhileAdding } from '../models/priceCalculation.model';
import { itemBuckets } from '../priceCalculation/buckets';
import { basePrice, toFixedNumber } from '../priceCalculation/helper';

export const ingredientSelectionHelper = ({ selectedModifiers, payload }) => {
  if (
    payload?.type !== CREATE_YOUR_OWN_SALAD_OPTIONALS &&
    payload.modifier_group_max &&
    payload.modifier_group_max <=
      selectedModifiers.filter(
        (mod) =>
          mod.modifier_group_id === payload.modifier_group_id &&
          mod.modifier_id !== payload.modifier_id &&
          !mod.core
      ).length
  ) {
    const index = selectedModifiers.findIndex(
      (mod) => mod.modifier_group_id === payload.modifier_group_id && !mod.core
    );
    index !== -1 && selectedModifiers.splice(index, 1);
  }
  const existingEntryIndex = selectedModifiers.findIndex(
    (mod) => mod.modifier_id === payload.modifier_id && !mod.core
  );
  if (existingEntryIndex !== -1) {
    !payload.quantity
      ? selectedModifiers.splice(existingEntryIndex, 1)
      : (selectedModifiers[existingEntryIndex].quantity = payload.quantity);
  } else {
    selectedModifiers.push({ ...payload, code: Codes.ADD });
  }
  return selectedModifiers;
};

export const itemIngredientSelectionHelper = ({ selectedModifiers, payload }) => {
  if (
      payload?.type !== CREATE_YOUR_OWN_SALAD_OPTIONALS &&
      payload?.modifier_type !== TRY_2_COMBO_ITEM_REQUIRED_MODIFIERS &&
      payload?.modifier_type !== ADD_ONS &&
      payload?.modifier_type !== CORE_MODIFIERS &&
      payload.extendableLimitValue &&
      payload.extendableLimitValue <=
      selectedModifiers.filter(
          (mod) =>
          mod.modifier_group_id === payload.modifier_group_id &&
          mod.modifier_id !== payload.modifier_id
      ).length
  ) {
      const index = selectedModifiers.findIndex(
          (mod) => mod.modifier_group_id === payload.modifier_group_id
      );
      index !== -1 && selectedModifiers.splice(index, 1);
  }
  const existingEntryIndex = selectedModifiers.findIndex(
      (mod) => {
          return mod.modifier_id === payload.modifier_id && mod.modifier_group_id === payload.modifier_group_id
      }
  );
  if (existingEntryIndex !== -1) {
      !payload.quantity
        ? selectedModifiers.splice(existingEntryIndex, 1) 
        : (selectedModifiers[existingEntryIndex].quantity = payload.quantity);
  } else {
    if( payload.quantity !== 0 ){
      selectedModifiers.push({
        ...payload,
        code: Codes.ADD
    });
    }
  }
  return selectedModifiers;
};

export const coreIngredientSelectionHelper = ({ selectedModifiers, payload }) => {
  const existingEntryIndex = selectedModifiers.findIndex(
    (mod) => {
      return mod.modifier_id === payload.modifier_id
    }
  );
  if (existingEntryIndex !== -1) {
    !payload.quantity
      ? selectedModifiers.splice(existingEntryIndex, 1)
      : (selectedModifiers[existingEntryIndex].quantity = payload.quantity);
  } else {
    selectedModifiers.push({ ...payload, code: Codes.ADD });
  }
  return selectedModifiers;
};

export const ingredientSubstitutionHelper = ({ initialState, payload }) => {
  const cloneItemCustomization = { ...initialState };
  if (payload.modifier_id !== payload.replaceWithId) {
    const ingredientIndex = cloneItemCustomization.modifiers.findIndex(
      (ing) => ing.modifier_id === payload.modifier_id
    );
    if (ingredientIndex !== -1) {
      if (payload.code === Codes.NO) {
        cloneItemCustomization.modifiers[ingredientIndex].sub_with_id = -1;
        cloneItemCustomization.modifiers[ingredientIndex].code = Codes.NO;
        cloneItemCustomization.modifiers[ingredientIndex].substituted_modifier =
          {};
      } else {
        cloneItemCustomization.modifiers[ingredientIndex].sub_with_id =
          payload.replaceWithId;
        cloneItemCustomization.modifiers[ingredientIndex].quantity =
          payload.quantity > payload.max
            ? payload.max
            : payload.quantity < 0
            ? 0
            : payload.quantity;
        cloneItemCustomization.modifiers[ingredientIndex].code = Codes.SUB;
        cloneItemCustomization.modifiers[ingredientIndex].substituted_modifier =
          payload.substituted_modifier;
      }
    }
  } else {
    const ingredientIndex = cloneItemCustomization.modifiers.findIndex(
      (ing) => ing.modifier_id === payload.modifier_id
    );
    if (ingredientIndex !== -1) {
      delete cloneItemCustomization.modifiers[ingredientIndex].sub_with_id;
      delete cloneItemCustomization.modifiers[ingredientIndex].code;
      delete cloneItemCustomization.modifiers[ingredientIndex]
        .substituted_modifier;
      cloneItemCustomization.modifiers[ingredientIndex].quantity =
        payload.quantity > payload.max
          ? payload.max
          : payload.quantity < 0
          ? 0
          : payload.quantity;
    }
  }
  return cloneItemCustomization;
};

export const removeDuplicateModifiers = (groups, modifiers) => {
  return groups
    .map((group) => ({
      ...group,
      modifiers_groups_modifiers: group.modifiers_groups_modifiers.filter(
        (modifier) => !modifiers.find((core_mod) => core_mod.id === modifier.id)
      ),
    }))
    .filter(
      (group) =>
        group.modifiers_groups_modifiers &&
        group.modifiers_groups_modifiers.length > 0
    );
};

export const categoryObject = (item) => {
  return {
    item:item,
    category_id: null,
    id:null,
    name: null,
    price: 0,
    modifiers: []
  }
}

export const complementaryObject = (item) => {
  return {
    item:item,
    modifiers: [],
    price:0
  }
}

export const itemExtraPriceWhileAdding = ({basePrice, selectedItemPrice, item, itemName}: IItemExtraPriceWhileAdding) => {
  let additionalPrice = 0;
  const currentBucket = itemBuckets.getSingleItem({itemType: itemName, itemNo: item})?.bucket
  const bucketTypes = Object.keys(currentBucket);
  for( let i = 0; i< bucketTypes.length; i++) {
    const activeSubBucket = currentBucket[bucketTypes[i]];
    additionalPrice += activeSubBucket?.additionalPrice
  }
  const itemPrice = toFixedNumber(selectedItemPrice - basePrice);
  if(itemPrice > 0){
    return toFixedNumber(additionalPrice + itemPrice)
  }else{
    return additionalPrice
  }
}

export const price = (basePrice:number, selectedItemPrice:number, originalPrice:number, updatedPrice:number, item) => {
  const additionalPrice = toFixedNumber(updatedPrice - originalPrice - item.price);
  const itemPrice = toFixedNumber(selectedItemPrice - basePrice);
  if(itemPrice > 0){
    return toFixedNumber(additionalPrice + itemPrice)
  }else{
    return additionalPrice
  }
}

export const itemExtraPrice = (basePrice:number, itemPrice:number) => {
  const price = toFixedNumber(itemPrice - basePrice);
  if(price > 0){
    return price
  }else{
    return 0
  }
}


export const itemSize = (half_required_data) => {
  if(!half_required_data){
    return FULL_SIZE.id
  }else{
    return HALF_SIZE.id
  }
}

// Will remove that in last build
export const testFunctionForPriceCalculation = (activeItem, itemOriginalPrice) => {
  const items = itemBuckets.getBuckets();
  let newPrice = 0;
  items.forEach((item,itemIndex) => {
    const keys = Object.keys(item?.bucket);
    keys.map((key,keyIndex) => {
      if(item?.bucket[key]?.additionalPrice >= 0) toFixedNumber(newPrice += item?.bucket[key]?.additionalPrice);
    });
    if(activeItem[itemIndex]?.basePrice) toFixedNumber(newPrice += activeItem[itemIndex]?.price)
  })
  return newPrice + itemOriginalPrice
}