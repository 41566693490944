import { createSlice } from '@reduxjs/toolkit'
import {appliedPaymentMethods} from "../../helpers/checkout/PaymentHelper";
import {floorNumberToDecimal, roundToTwo} from "../../priceCalculation/helper";

const initialStateValues = {
    paymentMethods: [],
    giftCard: [],
    isSplitPayment: false,
    isFundRaiser: false
}

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: initialStateValues,
    reducers: {
        addPaymentMethod: (state, action) => {
            state.paymentMethods.push(action.payload)
        },
        addGiftCard: (state, action) => {
            state.giftCard.push(action.payload)
        },
        updatePaymentCard: (state, action) => {
            state.paymentMethods = action.payload
        },
        updateGiftCard: (state, action) => {
            state.giftCard = action.payload
        },
        updatePaymentCardAmount: (state, action) => {
            if(state.paymentMethods.length > 0)
            {
                const duplicatePayments = [...state.paymentMethods]
                duplicatePayments[action.payload.index].amount = action.payload.amountAdded
                state.paymentMethods = duplicatePayments
            }
        },
        updateGiftCardAmount: (state, action) => {
            if(state.giftCard.length > 0)
            {
                const duplicateGiftCards = [...state.giftCard]
                duplicateGiftCards[action.payload.index].amount = action.payload.amountAdded
                state.giftCard = duplicateGiftCards
            }
        },
        updateIsSplitPayment: (state, action) => {
            state.isSplitPayment = action.payload
        },
        updateAmountOnSplit: (state, action) => {
            const totalCards = appliedPaymentMethods(state.paymentMethods, state.giftCard)
            const lastNumber = action.payload.totalAmount.toString()
            const isOddAmount = (Number(lastNumber[lastNumber.length - 1]) % 2) != 0 && totalCards.length > 0
            let isRounded = false
            let paymentCards = []
            let giftCards = []
            if(totalCards.length > 0)
            {
                totalCards.map((payment) => {
                    payment.amount = floorNumberToDecimal(action.payload.totalAmount/totalCards.length)
                    if(payment.gift_card_no) giftCards.push(payment)
                    else paymentCards.push(payment)
                })
            }
            if(isOddAmount)
            {
                if(paymentCards.length > 0) paymentCards[0].amount = roundToTwo(action.payload.totalAmount/totalCards.length)
                else giftCards[0].amount = roundToTwo(action.payload.totalAmount/totalCards.length)
            }
            state.paymentMethods = paymentCards
            state.giftCard = giftCards
        },
        resetCheckout: () => initialStateValues,
        updateIsFundRaiser: (state, action) =>{
            state.isFundRaiser = action.payload
        }
    }
})

export const {addPaymentMethod, addGiftCard, updatePaymentCard, updateGiftCard, updatePaymentCardAmount, updateAmountOnSplit,
    updateGiftCardAmount, resetCheckout, updateIsSplitPayment,updateIsFundRaiser} = checkoutSlice.actions

export const checkoutReducer = checkoutSlice.reducer
