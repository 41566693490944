import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  CATERING_CAFE_ZUPAS_DEV_URL,
  CATERING_CAFE_ZUPAS_URL,
  DELIVERY_ORDER_TYPE,
  GUEST_USER,
  ROUTE_LOGIN,
} from "../../constants";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { CateringDirectionIcon } from "../../images/svgIcons/svg";
import { useAppSelector } from "../../redux/store/store";
import styles from "./HeaderTop.module.scss";

interface HeaderTopProps {
  locationLabel?: any;
  handleLocationButton?: () => void;
  userType?: any;
  handleShowLiveTracker?: () => void;
  shouldShowLiveTracker?: boolean;
}
const HeaderTop: React.FC<HeaderTopProps> = ({
  locationLabel,
  handleLocationButton,
  userType,
  handleShowLiveTracker,
  shouldShowLiveTracker,
}) => {
  const [active, setActive] = useState(styles.activeLink);
  const Cart = useAppSelector((state) => state.cart);
  const isItMobile = useCheckMobileScreen();

  const getBaseUrl = () => {
    return process.env.REACT_APP_ENV === "production"
      ?  CATERING_CAFE_ZUPAS_URL
      : CATERING_CAFE_ZUPAS_DEV_URL;
  };

  return (
    <div className={`${styles.topHeaderWrapper}`}>
      <div className="container-fluid px-md-4 d-flex align-items-center flex-column-reverse flex-md-row justify-content-between">
        <div className="d-flex align-items-center mt-1 mt-md-0 justify-content-md-start justify-content-between w-100">
          <div className={styles.switcher}>
            <a href="/" className={`${styles.switcherLink} ${active}`}>
              {" "}
              Individual{" "}
            </a>
            <a href={getBaseUrl()} className={styles.switcherLink}>
              {" "}
              Catering{" "}
            </a>
          </div>
          <div
            className={`d-md-flex d-none ${styles.cateringText} align-items-center`}
          >
            <CateringDirectionIcon />
            <p className="mb-0 ms-2 clr-white-clr font-Visby-cf f-w7 f-s16 text-capitalize">
              {" "}
              Looking for catering?
            </p>
          </div>
          <div className="d-md-none ">
            {userType !== GUEST_USER ? (
              <button
                type="button"
                className={`${
                  styles.locationNavSection +
                  " " +
                  (Cart.orderType == DELIVERY_ORDER_TYPE
                    ? styles.online_location_icon
                    : "")
                } d-flex justify-content-center ms-auto align-items-center`}
                onClick={handleLocationButton}
              >
                {locationLabel()}
              </button>
            ) : (
              <div className="ms-auto">
                <NavLink to={ROUTE_LOGIN} className={styles.SignInBtn}>
                  Sign In
                </NavLink>
              </div>
            )}
          </div>
        </div>
        <div className="w-100">
          {!isItMobile && shouldShowLiveTracker ? (
            <div
              className={`${styles.orderTrackBar} bg-clr-dark-blue w-100  d-flex justify-content-center  justify-content-md-end align-items-center ms-auto`}
            >
              <p className="pe-1 ps-2 fw-bold f-s14 mb-0 text-uppercase font-Visby-cf ls-1  clr-white-clr ">
                Your Order Is In Progress
              </p>
              <button
                className="f-s14  font-Visby-cf border-0 text-decoration-underline px-0 bg-transparent fw-bold lh-lg clr-white-clr"
                onClick={handleShowLiveTracker}
              >
                Track Here
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
