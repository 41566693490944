import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import styleClasses from "../../Header/Header.module.scss";
import CartItemsList from "../CartItems/CartItemsList";
import SuggestedItemsList from "../SuggestedItems/SuggestedItemsList";
import Billing from "../Billing/Billing";
import { useStoreLocation } from "../../../context/StoreLocation";
import useGetRewards from "../../../react-query-hooks/useGetRewards";
import { useAuthState } from "../../../context/UserAuthentication";
import { Link } from "react-router-dom";
import { DELIVERY_DESTINATION_ID, IN_STORE_DESTINATION_ID, DELIVERY_ORDER_TYPE, GUEST_USER, ROUTE_MENU } from "../../../constants";
import { useAppSelector } from "../../../redux/store/store";
import { useDispatch } from "react-redux";
import {
  setAutoRedeem,
  deleteItemFromOrder,
  updateItemQuantity,
  resetCartOffer,
  updateItemKey,
  updateRedirectOfferId,
} from "../../../redux/slices/cartSlice";
import { useQueryClient } from "react-query";
import useDeleteItemFromCart from "../../../react-query-hooks/Cart/useDeleteItem";
import useUpdateItemQuantity from "../../../react-query-hooks/Cart/useUpdateItemQuantity";
import {
  IDeleteOrderItemPayload,
  IUpdateOrderQuantityPayload,
  IUseCoupon,
} from "../../../models/order.model";
import { getUser, getVisitorId, shouldRelease } from "../../../helpers/helperMethods";
import useToggleAutoRedeem from "../../../react-query-hooks/Cart/useToggleAutoRedeem";
import { Toast_Func } from "../../../helpers/toast.helper";
import UpsellItemsLists from "../UpsellItems/UpsellItemsList";
import useSuggestedItems from "../../../react-query-hooks/useSuggestedItems";
import useSyncCart from "../../../hooks/useSyncCart";
import promoStyleClasses from './Cart.module.scss';
import useCoupon from "../../../react-query-hooks/Cart/useCoupon";
import OfferItemsList from "../Offers/OfferItemsList";
import useGetOffers from "../../../react-query-hooks/useGetOffers";
import useRedeemOffer from "../../../hooks/useRedeemOffer";
import {customerBusinessService} from "../../../services";
import {updateTempLoyalityPoints} from "../../../redux/slices/itemSlice";
import { getPointsRedeemedInCart } from "../../../helpers/cartHelperMethods";
import {IOfferItem, cartItem} from "../../../models/cart.model";
import OfferEligibilityModal from "../Offers/OfferEligibilityModal";

interface ICartModal {
  showModal: boolean;
  closeModal: () => void;
  addToOrder: any;
}

export default function CartModal({
  showModal,
  closeModal,
  addToOrder
}: ICartModal) {
  const { authInfo } = useAuthState();
  const { locationInfo } = useStoreLocation();

  const cart = useAppSelector((state) => state.cart);
  const cartItems = cart.items;
  const isCartEmpty = !cartItems.length;
  const autoRedeem = cart.auto_redeem_rewards;

  let { mutate: deleteItem, isLoading: isDeleting } = useDeleteItemFromCart();
  let { mutate: applyCoupon, isLoading: isApplyingCoupon } = useCoupon();
  let { mutate: updateQuantity, isLoading: isUpdatingQuantity } = useUpdateItemQuantity();
  const [rewardsDiscountedSubTotal, setRewardsDiscountedSubTotal] = useState<number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const visitor_id = getVisitorId()
  const { syncCart, loading: syncingCart } = useSyncCart();
  const location_id = locationInfo.selectedStore?.id
  const queryClient = useQueryClient();
  const today = new Date();
  const year = today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const day = ("0" + today.getDate()).slice(-2);
  const businessDate = `${year}-${month}-${day}`;
  const getLikeUpSell = () => {
    let String = '&';
    const res = cartItems?.map((item, index) => {
      return String.concat(
        `items[${index}][id]=${item.item_id}&items[${index}][size]=${item?.item_size}`,
      );
    });
    return res?.join()?.replace(/,/g, '')
  }

  useEffect(() => {
    if (authInfo.userId || location_id) {
      syncCart(location_id)
    }
  }, [location_id])
  

  const { data, refetch: fetchUpsell, isFetching: isFetchingUpsell } = useSuggestedItems({
    business_date: businessDate,
    hour: '12',
    location_id: locationInfo.selectedStore.id,
    items: getLikeUpSell()
  });


  const { data: allRewards = {}, isFetching: isFetchingRewards } = useGetRewards(
    authInfo.userId,
    locationInfo.selectedStore?.id,
    1,
    1,
    1,
    authInfo?.type
  );
  const [myRewards, setMyRewards] = useState([]);
  useEffect(() => {
    if (!isFetchingRewards) {
      setMyRewards(allRewards?.rewards?.filter(reward => {
        return !reward.is_redeemed && !reward.is_expired
      }) || []);
    }
  }, [isFetchingRewards]);
  
  const [offerEligibilityModal, setOfferEligibilityModal] = useState<{
    show: boolean;
    failedOfferCriteria: string;
    redeemedItemNames: string[];
  }>({
    show: false,
    failedOfferCriteria: "",
    redeemedItemNames: [],
  });
  const [isPromoOfferAdded, setIsPromoOfferAdded] = useState<boolean>(false);
  const [onCartOpenSelectOffer, setOnCartOpenSelectOffer] = useState<boolean>(true);
  const [previousSelectedOffer, setPreviousSelectedOffer] = useState<IOfferItem>(null);
  const { redeemOffer, removeOffer } = useRedeemOffer()
  const {
    data: allOffers = {},
    isFetching,
    refetch: refetchOffers,
    isRefetching: isRefetchingOffers,
  } = useGetOffers(getUser().type === GUEST_USER, "CartModal");
  
  const offers = allOffers?.rewards || [];

  const cartRedirectOfferId = cart.redirect_offer_id;
  const cartRedirectOffer : IOfferItem = offers?.find(
    (offer: IOfferItem) => offer.id === cartRedirectOfferId
  );

  const latestOffer = offers?.[0];
  const selectedOfferItem: IOfferItem = offers?.find((offer: IOfferItem) => offer.in_cart);
  
  useEffect( () => {
    if (!isFetching && !syncingCart && (isPromoOfferAdded || onCartOpenSelectOffer)) {
      const offerToBeSelected = cartRedirectOffer || latestOffer;
      if (!offerToBeSelected) return;
      const {
        shouldShowOfferEligibilityModal,
        failedOfferCriteria,
        redeemedItemNames,
      } = redeemOffer({
        offerItem: offerToBeSelected,
        cartItems,
        selectedOfferItem,
        rewardsDiscountedSubTotal,
      });
      if ((!onCartOpenSelectOffer || cartRedirectOffer) && shouldShowOfferEligibilityModal) {
        const findPreviousOffer = offers?.find((offer: IOfferItem) => offer.id === previousSelectedOffer?.id);
        if (findPreviousOffer) {
          findPreviousOffer.in_cart = true;
          findPreviousOffer.redeemed_by_offer_discount = previousSelectedOffer.redeemed_by_offer_discount;
          findPreviousOffer.cart_item_index = previousSelectedOffer.cart_item_index;
        }
        setOfferEligibilityModal({show: true, failedOfferCriteria, redeemedItemNames});
      }
      setPreviousSelectedOffer(null);
      setIsPromoOfferAdded(false);
      setOnCartOpenSelectOffer(false);
    }
  }, [isFetching, syncingCart])


  const [stickyModal, setstickyModal] = useState<boolean>(true);
  const modalBodyRef = useRef(null);
  const config = { subtree: true, childList: true };
  const dispatch = useDispatch();
  const { mutate: toggleAutoRedeem, isLoading: isTogglingAutoRedeem } = useToggleAutoRedeem()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isCouponCodeEntered = couponCode?.toString()?.length > 0 ? false : true;
  const handleApplyCoupon = async (showToast:boolean) => {
    const payload: IUseCoupon = {
      promo_code: couponCode
    };
    applyCoupon(payload, {
      onSuccess: async (res:any) => {
        if(res?.data?.rewards?.length)
        {
          refetchOffers();
          setIsPromoOfferAdded(true);
          setPreviousSelectedOffer(selectedOfferItem);
        }
      },
      onError: (error:any) => {
        showToast && Toast_Func({ status: false, message: error?.response?.data?.errors[0] });
      },
    });
  };

  const { loyalityPoints, tempLoyalityPoints }=useAppSelector(state=>state.Items.item)

  useEffect(() => {
    dispatch(resetCartOffer());
  }, [])

  useEffect(() => {
    const pointsRedeemedInCart = getPointsRedeemedInCart(cartItems);
    dispatch(updateTempLoyalityPoints(loyalityPoints - pointsRedeemedInCart));
  }, [loyalityPoints, cartItems]);
  
  const buttonForCouponField = () => {
    if (isApplyingCoupon || isRefetchingOffers) {
      return (
        <div className={`${promoStyleClasses.loader_wrapper}`}>
          <Spinner
            animation="border"
            variant="dark"
            size="sm"
            className={promoStyleClasses.spinner}
          />
        </div>
      );
    }
    return (
      <button
        onClick={() => handleApplyCoupon(true)}
        type="button"
        className={`${promoStyleClasses.arrow_btn} btn btn-link`}
        disabled={isCouponCodeEntered}
      ></button>
    );
  };

  const onCouponInputChange = (event:any) => {
    setCouponCode(event?.target?.value)
  }

  const handleAutoUseReward = async () => {
    if (isUpdatingQuantity) return;
    setIsLoading(true)
    dispatch(setAutoRedeem(!autoRedeem));
    setMyRewards(rewards => rewards.map(reward => { return { ...reward, is_active_in_cart: !autoRedeem} }));
    let mappedRewards = {}
    cartItems.map((item: cartItem) => {
      if(item?.reward_redeemable && item?.quantity === 1)
      { mappedRewards[item.id] = item?.reward_id }
    })
    toggleAutoRedeem({
      rewards: mappedRewards,
      auto_redeem_rewards: !cart.auto_redeem_rewards,
      customer_id: authInfo.userId, location_id }, {
      onSuccess: async () => {
        setIsLoading(false)        
      },
      onError: (error: any) => {
        Toast_Func({ status: false, message: error.message })
        dispatch(setAutoRedeem(autoRedeem));
        setMyRewards(rewards => rewards.map(reward => { return { ...reward, is_active_in_cart: autoRedeem} }));
        setIsLoading(false)
      }
    })
  };
  const isSticky = (parentElement: any) => {
    const windowHeight = window.innerHeight;
    const childCount = parentElement.children.length;
    let childrenTotalHeight = 0;
    //Ignoring first and last item in loop because those are paddings for chekcout button and heading
    for (let i = 1; i < childCount - 1; i++) {
      childrenTotalHeight =
        childrenTotalHeight + parentElement.children[i].clientHeight;
    }
    return windowHeight <= childrenTotalHeight;
  };
  const observer = new MutationObserver((mutations: any) => {
    setstickyModal(isSticky(modalBodyRef.current));
  });


  useEffect(() => {
    if (autoRedeem) {
      queryClient.refetchQueries(["myRewards", authInfo.userId], null, { throwOnError: true });
    }
    function handleCartSize() {
      if (showModal && modalBodyRef.current) {
        document.documentElement.style.setProperty(
          "--get-modal-height",
          `${modalBodyRef.current.clientHeight}`
        );
        setstickyModal(isSticky(modalBodyRef.current));
      }
    }
    handleCartSize();

    //Adding observer for the size changes of the Cart contents
    showModal &&
      observer.observe(document.getElementById("cart_Modal_body"), config);

    //Adding event listener to be called wheneve the window size changes
    window.addEventListener("resize", handleCartSize);

    //Cleanup for event listeners
    return () => {
      window.removeEventListener("resize", handleCartSize);
      observer.disconnect();
    };
  }, [showModal]);
  const handleItemRemove = async (item_id, iteratingIndex, closeModal) => {
    const payload: IDeleteOrderItemPayload = {
      item_id: item_id
    };
    if (authInfo?.userId) payload.customer_id = authInfo.userId;
    else payload.visitor_id = visitor_id;
    deleteItem(payload, {
      onSuccess: () => {
        if (cartItems[iteratingIndex].is_redeemed_by_offer) {
          selectedOfferItem.in_cart = false;
          const { failedOfferCriteria } = redeemOffer({
            offerItem: selectedOfferItem,
            cartItems,
            selectedOfferItem, 
            rewardsDiscountedSubTotal,
            deletedItemIndex: iteratingIndex,
          })
          if (failedOfferCriteria)
            removeOffer(selectedOfferItem, true)
        }
        if(cartItems[iteratingIndex]?.reward_redeemed)
        {
          customerBusinessService.updateReward(cartItems[iteratingIndex].reward_id, {is_active_in_cart: Number(!cartItems[iteratingIndex].reward_redeemed)})
        }
        if(cartItems[iteratingIndex]?.redemption_by_points)
        {
          dispatch(updateTempLoyalityPoints(tempLoyalityPoints + parseInt(cartItems[iteratingIndex]?.redeem_points)))
        }
        dispatch(deleteItemFromOrder(iteratingIndex));
        closeModal();
        queryClient.refetchQueries(["myRewards", authInfo.userId], undefined, { throwOnError: true });
        if(cartItems.length - 1 > 0){
          fetchUpsell()
        }
      },
      onError: (error) => {
        console.log(error, "ERROR ON DELETE");
        closeModal()
      },
    });
  };

  const updateItemRedeem = (iteratingIndex: number) => {
    const item = cartItems[iteratingIndex]
    if(item.reward_redeemable && item.reward_id) {
      dispatch(updateItemKey({ index: iteratingIndex, value: true, key: "reward_redeemed" }))
    }
  }
  const handleQuantityChange = async (
    item_id,
    iteratingIndex,
    itemQuantity: number
  ) => {
    const payload: IUpdateOrderQuantityPayload = {
      item_id,
      quantity: itemQuantity,
    };
    if (autoRedeem && itemQuantity === 1) updateItemRedeem(iteratingIndex)
    dispatch(
      updateItemQuantity({
        index: iteratingIndex,
        quantity: itemQuantity,
      })
    );
    if (authInfo?.userId) payload.customer_id = authInfo.userId;
    else payload.visitor_id = visitor_id;
    updateQuantity(payload, {
      onSuccess: () => {},
      onError: (error) => {
        console.log(error, "ERROR ON quantity change");
      },
    });
  };

  const shouldShowRewardsOfferPromo = () => {
    return authInfo.userId && 
    authInfo.type !== GUEST_USER && 
    shouldRelease('Features', 'redeem')
  };

  return (
    <>
      {(cartRedirectOffer || latestOffer) && <OfferEligibilityModal
        show={offerEligibilityModal.show}
        offerFailedCriteria={offerEligibilityModal.failedOfferCriteria}
        redeemedItemNames={offerEligibilityModal.redeemedItemNames}
        offerDetails={cartRedirectOfferId ? cartRedirectOffer?.data : latestOffer?.data}
        closeModal={() => 
          setOfferEligibilityModal({show: false, failedOfferCriteria: "", redeemedItemNames: []})
        }
      />}
      <Modal
        show={showModal}
        onHide={closeModal}
        id="cart_modal"
        className={`${styleClasses.my_card_modal
          } my-card-modal app_container_wrap right ${stickyModal ? styleClasses.sticky_modal_content : ""
          }`}
      >
        <Modal.Header className={`${styleClasses.modal_header} pt-3 pb-0`}>
          <div
            className={`${styleClasses.customize_card_header} w-100 ps-0 mb-0 text-md-end text-start`}
          >
            <button
              className={`${styleClasses.modal_close_button} close modal-close-button p-0 mb-3 d-none d-md-block ms-auto`}
              onClick={closeModal}
            >
              Close
            </button>
            <Link
              to={ROUTE_MENU}
              onClick={closeModal}
              className="btn btn-custom back-arrow-btn f-s14 px-0 mb-3 d-md-none"
            >
              Menu
            </Link>
            <h3
              className={`${styleClasses.card_title} text-md-center d-inine-block font-Cls mb-0 f-s22 lh-normal text-start`}
            >
              Cart Details
            </h3>
          </div>
        </Modal.Header>
        <Modal.Body
          id="cart_Modal_body"
          className={`${styleClasses.cart_Modal_body} ${isCartEmpty && styleClasses.emptyCart_Modal_body}`}
          ref={modalBodyRef}
        >
          <span className={`${styleClasses.spacing_top_span} mt-4`}></span>
          {isCartEmpty && <hr className="shadow-divider mb-4" />}
          <CartItemsList
            syncingCart={syncingCart}
            isAutoRedeeming={isLoading}
            selectedOfferItem={selectedOfferItem}
            rewardsDiscountedSubTotal={rewardsDiscountedSubTotal}
            handleItemRemove={handleItemRemove}
            loading={isDeleting || isUpdatingQuantity}
            handleQuantityChange={handleQuantityChange}
            allRewards={myRewards}
            cart={cartItems}
            scrollcart={styleClasses.scroll_body_cart}
            setMyRewards={setMyRewards}
          />
          {(!isCartEmpty && !syncingCart) || authInfo.type !== GUEST_USER ? <hr className="shadow-divider mb-4" />: null}
          {!isCartEmpty ?
            <>
              <UpsellItemsLists data={data} cart={cartItems} isFetchingUpsell={isFetchingUpsell} fetchUpsell={fetchUpsell} syncCart={syncCart} />
            </> : null
          }
          {shouldShowRewardsOfferPromo() && myRewards?.length > 0 ? (
            <>
              <div className={styleClasses.use_reward}>
                <div className="form-group theme_check_boxes Switch_btn rounded mb-0">
                  <input
                    disabled={isLoading}
                    type="checkbox"
                    id={`radiobox_cart`}
                    name="address_group"
                    checked={autoRedeem}
                    onChange={handleAutoUseReward}
                  />
                  <label
                    htmlFor={`radiobox_cart`}
                    className="d-flex align-items-center justify-content-center f-s18 f-sm-s16"
                  >
                    <span className="box_wrap mt-1"></span>
                    <span
                      className={`${styleClasses.info_label} d-flex justify-content-between info_label`}
                    >
                      <img
                        src={require("../../../images/Reward.svg")}
                        className="img-fluid me-2"
                        alt="reward"
                      />{" "}
                      Automatically Use My Rewards
                    </span>
                  </label>
                </div>
              </div>
              <hr className="shadow-divider mb-4" />
            </>
          ): null}
          {shouldShowRewardsOfferPromo() && myRewards.length ? (
            <>
              <SuggestedItemsList
                addToOrder={addToOrder}
                list={myRewards}
                closeModal={closeModal}
                title="Redeem a Reward"
                isRewardList={true}
                setMyRewards={setMyRewards}
              />
              <hr className="shadow-divider mb-4" />
            </>
          ): null }
              {shouldShowRewardsOfferPromo() && (
          <>
            <div
              className={`${promoStyleClasses.promo_code} new_form_design promo_code`}
            >
              <div className="form-group mb-0 ">
                <label className="mb-1">Enter Promo Codes Here</label>
                <div className={promoStyleClasses.promoInputBtn}>
                <input
                  type="text"
                  className={`form-control f-s24`}
                  value={couponCode}
                  onChange={(event) => onCouponInputChange(event)}
                />
                {buttonForCouponField()}
                </div>
              </div>
            </div>
            <hr className="shadow-divider mb-4" />
          </>
          )}
          {shouldShowRewardsOfferPromo() ? (
            <OfferItemsList
              addToOrder={() => {}}
              offers={offers}
              selectedOfferItem={selectedOfferItem}
              closeModal={closeModal}
              title="Offers"
              isRewardList={true}
              rewardsDiscountedSubTotal={rewardsDiscountedSubTotal}
            />) : null
          }
          { !isCartEmpty || authInfo.type !== GUEST_USER ? 
            <Billing
              cartItems={cart?.items}
              isOrderTypeSelected={cart?.isOrderTypeSelected}
              orderType={cart?.orderType}
              authInfo={authInfo}
              CartBtnCheckout={styleClasses.cart_btn_checkout}
              TotalList={styleClasses.total_list}
              offerDiscount={cart?.offerDiscount}
              disableCheckout={syncingCart}
              handleTax={false}
              setRewardsDiscountedSubTotal={setRewardsDiscountedSubTotal}
              offerDiscountType={cart?.offerDiscountType}
              />
            : null
          }
          <span className={`cz-pt-50 d-flex w-100 mt-4`}></span>
        </Modal.Body>
      </Modal>
    </>
  );
}