import { ORDER_MICRO_SERIVCE, ORDER_SERVICE } from "../constants";
import { IAddToOrderPayload, IApplyOfferOnCart, ICartParamsPayload, IGetCartPayload, IGetItemFromCartPayload, IOrderStatus, IRedeemPointsByOrderId, IUseCoupon } from "../models/order.model";
import { HttpService } from "./base.service";

class OrderMicroService extends HttpService {
  private readonly prefix: string = ORDER_SERVICE;
  baseURL = ORDER_MICRO_SERIVCE

  getCart = (data: IGetCartPayload): Promise<any> => this.get(this.prefix, data);
  getCartItem = (data: IGetItemFromCartPayload): Promise<any> => this.get(`${this.prefix}/item`, data);
  addItemToCart = (data: IAddToOrderPayload): Promise<any> => this.post(`${this.prefix}/item`, data);
  deleteItem = (itemId: string, data: ICartParamsPayload): Promise<any> => this.delete(`${this.prefix}/item/${itemId}`, data);
  updateItemQuantity = (itemId: string, data: ICartParamsPayload): Promise<any> => this.patch(`${this.prefix}/item/${itemId}`, data);
  updateItem = (itemId: string, data: any): Promise<any> => this.post(`${this.prefix}/item/${itemId}`, data);
  addToFavorites = (data: any): Promise<any> => this.post(`items/favorite`, data);
  removeFromFavorites = (userId: string, itemId: string | number): Promise<any> => this.delete(`items/remove-favorite/${userId}/${itemId}`);
  getCustomerFavorites = (userId: string, body): Promise<any> => this.get(`items/favorite/${userId}`,body);
  getOrderReceipt = (id: string): Promise<any> => this.get(`orders/${id}?details=payment,customer,store,pickup`);
  getOrderHistory = (id: string, data: any): Promise<any> => this.get(`orders/history/${id}`,data);
  createOrder = (data: any): Promise<any> => this.post(`orders`,data);
  updateOrderByOrderId = (orderId: number, data: any): Promise<any> => this.put(`orders/${orderId}`,data);
  getOrderByOrderId = (orderId: number | string): Promise<any> => this.get(`orders/${orderId}`);
  toggleRedeemForPoints=(id:any,data:any): Promise<any>=>this.patch(`cart/item/${id}`,data)
  toggleRedeemFromRewards=(id:any,data:any): Promise<any>=>this.patch(`cart/item/${id}`,data)
  toggleAutoRedeem=(data:any): Promise<any>=>this.put(`cart/items/rewards`,data)
  placeOrder=(data:any): Promise<any>=>this.post(`order/place`,data)
  notifyIAmHere=(orderId: number | string, data:any): Promise<any>=>this.put(`i-am-here/${orderId}`, data)
  notifyIAmHereUpdateStatus=(encryptedOrderId: any | string, data:any): Promise<any>=>this.put(`i-am-here-update-status/${encryptedOrderId}`, data)
  reOrder=(orderId: number): Promise<any>=>this.put(`re-order/${orderId}`)
  getCartInfo=(customer_id: number): Promise<any>=>this.get(`cart-info/${customer_id}`)
  cancelOrder = (orderId: number | string): Promise<any> => this.put(`order/${orderId}/cancel`);
  cancelEncryptedOrder = (encryptedOrderId: number | string): Promise<any> => this.put(`order/${encryptedOrderId}/order-cancel-here`)
  applyCoupon = (data:IUseCoupon): Promise<any> => this.post(`apply-coupon`,data)
  applyOfferOnCart = (data: IApplyOfferOnCart): Promise<any> => this.patch("loyalty/cart-checkout", data)
  redeemPointsByOrderId = (data: IRedeemPointsByOrderId): Promise<any> => this.post("loyalty/get-points-for-manual-check-in", data)
  getOrderStatus = ({orderId, trackingType} : IOrderStatus): Promise<any> => this.get(`get-order-status?order_id=${orderId}&type=${trackingType}`);
}

export const orderMicroService = new OrderMicroService();