import React, { useState } from "react";
import { Formik } from "formik";
import { FormField } from "../../FormFields/FormField";
import { ISelectedLocation } from "../../../models/forms.model";
import { setLocation, useStoreLocation } from "../../../context/StoreLocation";
import { IItemPathToRoute } from "../../../models/item.model";
import { useAuthState } from "../../../context/UserAuthentication";
import { selectLocationSchema } from "../../../validationSchemas/selectLocationSchema";
import { useAppDispatch } from "../../../redux/store/store";
import {
    setShowCart,
    updateIsOrderTypeSelected,
    updateOrderDetails,
    updateOrderType
} from "../../../redux/slices/cartSlice";
import { GUEST_USER, ROUTE_LOCATIONS, ROUTE_MENU, ROUTE_ORDER } from "../../../constants";
import { handleIsLiveLocationRedirection } from "../../../helpers/checkout/LocationHelper";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router";
import ConfirmLocationChangeModal from "./ConfirmLocationChangeModal";
import * as CONSTANT from "../../../constants";
import { Spinner } from "react-bootstrap";
import useGetFavoriteLocations from "../../../react-query-hooks/useGetNearbyLocations";

interface IItemPickup {
    itemPath: IItemPathToRoute,
    closeOpenOrderModal: () => void,
    showItemPickupDateTime?: boolean,
    handleShowPickupMethodModal?: (boolean) => void,
    saveSelectedOrderType?: string,
    handleSavedOrderType?: () => void,
    isItMobile: boolean;
}
function ItemPickup(props: IItemPickup) {
    const {
        itemPath,
        closeOpenOrderModal,
        showItemPickupDateTime,
        handleShowPickupMethodModal,
        saveSelectedOrderType,
        isItMobile,
    } = props
    const location = useLocation()
    const { locationInfo, dispatch: locationDispatch } = useStoreLocation();
    const queryClient = useQueryClient();
    const location_id = locationInfo.selectedStore?.id
    const [selectedLocation, setSelectedLocation] = useState(locationInfo.selectedStore);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const selectedLocationOption = { value: locationInfo.selectedStore?.name, label: selectedLocation.name, id: selectedLocation.id }
    const { authInfo } = useAuthState();

    const { data: favoriteLocations, isFetching: isFetchingFavoriteLocations } =
      useGetFavoriteLocations(authInfo.userId, authInfo.type === GUEST_USER);
    const allLocations = favoriteLocations?.map((market) => ({
      value: market.name,
      label: market.name,
      id: market.id,
      ...market,
    }));

    const history = useHistory();
    const orderTypeDispatch = useAppDispatch();
    const initialFormState: ISelectedLocation = {
        selected_location: selectedLocation.id ? selectedLocationOption : null
    };

    const handleLocationSearch = () => {
        history.push({
            pathname: ROUTE_LOCATIONS,
            state: { itemPath: itemPath }
        })
        orderTypeDispatch(setShowCart(false))
        closeOpenOrderModal()
    }

    const handleContinueButton = () => {
        if (!selectedLocation) {
            history.push({
                pathname: ROUTE_LOCATIONS,
                state: { itemPath: itemPath }
            })
            orderTypeDispatch(setShowCart(false))
        } 
        else {
            orderTypeDispatch(updateIsOrderTypeSelected(true))
            orderTypeDispatch(updateOrderType(CONSTANT.PICK_UP_ORDER_TYPE))
            orderTypeDispatch(updateOrderDetails(null))
        }
        closeOpenOrderModal()
    }

    const getSelectedLocation = (location) => {
        return allLocations.find((loc) => loc.id === location?.id) ? location : null
    }

    const handleSaveOrderType = (orderType: string) => {
        orderTypeDispatch(updateOrderType(orderType));
    };
    const routeToItem = () => {
        setIsSubmitting(true)
        if (selectedLocation.id !== location_id && location.pathname.includes(ROUTE_ORDER)) {
            setShowConfirmationModal(true)
        } else {
            confirmLocationChange()
        }
    }

    const confirmLocationChange = () => {
        if (!selectedLocation.is_live) {
            handleIsLiveLocationRedirection(saveSelectedOrderType, selectedLocation, isItMobile)
        } else {
            orderTypeDispatch(updateIsOrderTypeSelected(true))
            setLocation(locationDispatch, selectedLocation);
            queryClient.refetchQueries(["get-cart", selectedLocation.id]);
            orderTypeDispatch(updateOrderDetails(null))
            closeOpenOrderModal()
            if (saveSelectedOrderType) { handleSaveOrderType(saveSelectedOrderType) }
            if (showItemPickupDateTime) { handleShowPickupMethodModal(true) }
            if (showConfirmationModal) {
                setShowConfirmationModal(false)
                history.push(ROUTE_MENU)
            } else if (itemPath) {
                history.push(itemPath)
            }
        }
    }

    const onCloseConfirmationMoal = () => {
        setShowConfirmationModal(false);
        setIsSubmitting(false)
    }

    if (isFetchingFavoriteLocations)
      return (
        <div className="position-relative mt-5 py-5">
          <Spinner
            animation="border"
            className="position-absolute bottom-50 end-50"
          />
        </div>
      );

    return (
      <div className="mt-5">
        {allLocations?.length ? (
          <>
            <Formik
              initialValues={initialFormState}
              onSubmit={routeToItem}
              validationSchema={selectLocationSchema}
            >
              {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                <form
                  className="full_width_mob new_form_design w-75 my-0 mx-auto"
                  onSubmit={handleSubmit}
                >
                  <FormField
                    isSearchable={true}
                    options={allLocations}
                    labelText={"Favorite Locations"}
                    name="selected_location"
                    type={"select"}
                    placeholder={"Select Location"}
                    errors={errors}
                    touched={touched}
                    value={getSelectedLocation(values.selected_location)}
                    onChange={(location) => {
                      setFieldValue("selected_location", location);
                      setSelectedLocation(location);
                    }}
                    inputFieldClass={
                      "custom_select custom_multi_select mb-0 mt-2 mb-4  pb-md-0"
                    }
                  />
                  {selectedLocation && (
                    <div className="justify-start-center my-4">
                      <span className="d-block f-s14 font-Visby-cf f-w5">
                        {selectedLocation.name}
                      </span>
                      <span className="d-block f-s14 font-Visby-cf f-w5">
                        {selectedLocation.address}
                      </span>
                      <span className="d-block f-s14 font-Visby-cf f-w5">
                        {selectedLocation.city}, {selectedLocation.state}{" "}
                        {selectedLocation.zip}
                      </span>
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn btn-large py-2 lh-lg f-s16 w-100 mb-3"
                    disabled={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    Continue
                  </button>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <div className="mx-md-5 px-2">
            {selectedLocation && (
              <div className="justify-start-center my-4">
                <span className="d-block f-s14 font-Visby-cf f-w5">
                  {selectedLocation.name}
                </span>
                <span className="d-block f-s14 font-Visby-cf f-w5">
                  {selectedLocation.address}
                </span>
                <span className="d-block f-s14 font-Visby-cf f-w5">
                  {selectedLocation.city}, {selectedLocation.state}{" "}
                  {selectedLocation.zip}
                </span>
              </div>
            )}
            <button
              type="button"
              className="btn btn-large py-2 lh-lg f-s16 w-100 mb-3"
              onClick={handleContinueButton}
            >
              Continue
            </button>
          </div>
        )}
        <div className="w-75 mx-auto">
          <button
            type="button"
            className={`btn btn-custom p-0 f-w6 p-16 color-sky-blue-light text-center w-100 mb-5 font-Vcf`}
            onClick={handleLocationSearch}
          >
            {selectedLocation ? "Change Location" : "Search Locations"}
          </button>
        </div>
        <ConfirmLocationChangeModal
          showModal={showConfirmationModal}
          handleClose={() => onCloseConfirmationMoal()}
          confirmChange={confirmLocationChange}
          loading={false}
        />
      </div>
    );
}

export default ItemPickup;


