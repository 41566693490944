import PackageJson from "../package.json"
// Application Constants
export const APP_VERSION = PackageJson.version;
export const TOKEN = "AUTH_TOKEN";
export const AUTH_TOKEN_HEADER = "Authorization";
export const CURRENT_USER = "CurrentUser";
export const SELECTED_STORE = "SelectedLocation";
export const CART = "Cart";
export const MOBILE_APP_DEEPLINK = "com.cafezupasrewrite://newpassword";
export const GUEST_USER = "guest";
export const LOYALTY_USER = "loyalty";
export const AUTH_USER = "user";
export const SOURCE_WEB = "Web";
export const SOURCE_MOBILE_WEB = "MobileWeb";

// ORDER
export const SINGLE_ITEM_SELECTED_SECTION = 1;
export const COMPLEMENTARY_SELECTED_SECTION_FOR_SINGLE_ITEM = 2;
export const TRY_TO_COMBO_SELECTED_SECTION_FIRST_SECTION = 1;
export const TRY_TO_COMBO_SELECTED_SECTION = 2;
export const COMPLEMENTARY_SELECTED_SECTION_FOR_TRY_TO_COMBO_ITEM = 3;
export const KIDS_COMBO_SELECTED_SECTION = 3;
export const COMPLEMENTARY_SELECTED_SECTION_FOR_KIDS_COMBO_ITEM = 4;
export const NO = "No";
export const ADD = 'Add';
export const SUB = 'Sub';
export const BY_DEFAULT = "by_default";
export const DECREASE = "decrease";
export const INCREASE = "increase";
export const SELECTED = "selected";
export const REQUIRED_MODIFIERS = "required_modifiers";
export const CORE_MODIFIERS = "core_modifiers";
export const CORE_RELATED = "core_related";
export const CORE_RELATED_MODIFIERS = "core_related_modifiers";
export const COMPLIMENTARY_MODIFIER = "complementary_modifiers";
export const BUCKET_MODIFIERS_KEY = "modifiers";
export const OTHER_MODIFIERS = "other_modifiers";
export const ADD_ONS = "add_ons";
export const NO_DRESSING = "no dressing";
export const NO_DRESSING_CAPITALIZE = "No Dressing";
export const GOOGLE_REDIRECTION_URL = "https://www.google.com/maps/search/?api=1&query=";
export const CREATE_YOUR_OWN_SALAD = "CREATE YOUR OWN SALAD";
export const CREATE_YOUR_OWN_SALAD_OPTIONALS = "CREATE_YOUR_OWN_SALAD_OPTIONALS";
export const CREATE_YOUR_OWN_SALAD_REQUIRED = "CREATE_YOUR_OWN_SALAD_REQUIRED";
export const TRY_2_COMBO = 'TRY 2 COMBO';
export const TRY_2_COMBO_CAPITALIZE = 'Try 2 Combo';
export const KIDS_COMBO = "KID'S COMBO";
export const KIDS_COMBO_CAPITALIZE = "Kid's Combo";
export const MAKE_A_SELECTION = "Make A Selection";
export const SELECT_AN_ITEM = "Select an item";
export const TRY_2_COMBO_LOWER_CASE = "try 2 combo";
export const COMBO_TYPE_IS_KIDS_COMBO = "COMBO_TYPE_IS_KIDS_COMBO"
export const COMBO_TYPE_IS_TRY_2_COMBO = "COMBO_TYPE_IS_TRY_2_COMBO"
export const COMPLEMENTARY_ITEM = 'COMPLEMENTARY_ITEM';
export const KIDS_COMBO_COMPLEMENTARY_ITEM = "KIDS_COMBO_COMPLEMENTARY_ITEM"
export const TRY_2_COMBO_ITEM_CORE_MODIFIERS = 'TRY_2_COMBO_CORE_MODIFIERS';
export const TRY_2_COMBO_ITEM_REQUIRED_MODIFIERS = 'TRY_2_COMBO_REQUIRED_MODIFIERS';
export const PICK_UP_ORDER_TYPE = 'PICK_UP_ORDER';
export const DELIVERY_ORDER_TYPE = 'DELIVERY_ORDER';
export const PICK_UP_METHOD_IN_STORE = 'IN_STORE';
export const PICK_UP_METHOD_CURBSIDE = 'CURBSIDE';
export const PICK_UP_METHOD_GOOD_LIFE_LANE = 'GOOD_LIFE_LANE'

export const CURBSIDE = "Curbside"
export const CURBSIDE_DESTINATION_ID = 25
export const GOOD_LIFE_LANE = "Good Life Lane"
export const GOOD_LIFE_LANE_DESTINATION_ID = 26
export const IN_STORE = "In-Store"
export const IN_STORE_DESTINATION_ID = 12
export const DELIVERY = "Delivery"
export const CANCELLED = "Cancelled"
export const FAILED = "Failed"
export const DELIVERY_DESTINATION_ID = 15
export const DELIVERED = "delivered"
export const SCHEDULED = "scheduled"
export const ASSIGNED = "assigned"

//sqs order statuses
export const PROCESSING = 'Processing';
export const ACTIVE = 'Active';
export const PREPARED = 'Prepared';
export const ASSEMBLED = 'Assembled';
export const COMPLETE = 'Complete';
export const QUEUE_FOR_ORDER_STATUS = "orderStatusQueue"
export const QUEUE_FOR_DOOR_DASH = "doorDashQueue"


export const GIFT_CARD = 'GIFT_CARD';
export const PAYMENT_CARD = 'PAYMENT_CARD'
export const NO_TIME_SLOTS_PRESENT = "00:00:00"
export const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
// export const PICK_UP_METHOD_GOOD_LIFE_LANE = 'GOOD_LIFE_LANE';
export const DELIVERY_TIP_REG_EXPRESSION = new RegExp('^[+-]?([0-9]+([.][0-9]{0,2})?|[.][0-9]+)$');
export const CARD_AMOUNT_REG_EXPRESSION = new RegExp('^[+-]?([0-9]+([.][0-9]{0,2})?|[.][0-9]+)$');
export const PICK_UP_METHOD_DELIVERY = 'DELIVERY';
export const ADD_TO_ORDER = 'ADD TO ORDER';
export const CUSTOMIZE = 'CUSTOMIZE';
export const MAKE_IT_A_TRY_2_COMBO = 'MAKE IT A TRY 2 COMBO';
export const APPLY = 'APPLY';
export const MAX_PAYMENT_LIMIT = 2;
// API Contants
export const GRANT_TYPE_VALUE = "client_credentials";
export const CLIENT_ID_VALUE = 1;
export const CLIENT_SECRET_VALUE = "vYlgasAqX8krH7Mki4AtxDSy8P7z6OWvf5JLBQAC";
export const SCOPE = "*";
export const LOCATION_URL_INDICATOR = "location";
export const CUSTOMER_URL_INDICATOR = "customer"
export const ORDER_URL_INDICATOR = "order";
export const PAYMENT_URL_INDICATOR = "payment";
export const COMBO_ITEM_ONE = '1';
export const COMBO_ITEM_TWO = '2';
export const COMBO_ITEM_THREE = '3'
export const ERROR_CODE_FOR_CAPACITY = 406
export const MAX_COUNT_FOR_PROFILE_INFO = 5

// API Routes Contants

//catering cafe zupas links
export const CATERING_CAFE_ZUPAS="CATERING"
export const CATERING_CAFE_ZUPAS_URL= "https://catering.cafezupas.com";
export const CATERING_CAFE_ZUPAS_DEV_URL= "https://devcatering.cafezupas.com/";
export const CATERING_CAFE_ZUPAS_LOGIN_URL= "https://catering.cafezupas.com/login"

export const CONTACT_US = "/contact_us";
export const CATERIND_REWARD = "/catering_reward";
export const LOGIN = "/token";
export const HOME_PAGE_HERO_BANNER_CAROUSELS = "/carousel/HomePage/HeroBanner";
export const ONLINE_ORDER_PAGE_HERO_BANNER_CAROUSELS =
  "/carousel/OnlineOrder/HeroBanner";
export const CATERING_HERO_BANNER_CAROUSELS = "/carousel/Catering/HeroBanner";
export const CATERING_REQUEST_HERO_BANNER_CAROUSELS =
  "/carousel/CateringRequests/HeroBanner";
export const FUNDRAISING_HERO_BANNER_CAROUSELS =
  "/carousel/Fundraising/HeroBanner";
export const KITCHEN_HERO_BANNER_CAROUSELS = "/carousel/Kitchen/HeroBanner";
export const MENU_HERO_BANNER_CAROUSELS = "/carousel/Menu/HeroBanner";
export const CATERING_MENU_HERO_BANNER_CAROUSELS =
  "/carousel/CateringMenu/HeroBanner";
export const CATERING_ORDER_HERO_BANNER_CAROUSELS =
  "/carousel/CateringOrder/HeroBanner";
export const CAREER_HERO_BANNER_CAROUSELS = "/carousel/Careers/HeroBanner";
export const PRIVACY_POLICY_HERO_BANNER_CAROUSELS =
  "/carousel/PrivacyPolicy/HeroBanner";
export const REWARDS_DETAILS_HERO_BANNER_CAROUSELS =
  "/carousel/RewardDetails/HeroBanner";
export const TALK_TO_US_HERO_BANNER_CAROUSELS = "/carousel/TalkToUs/HeroBanner";
export const TERMS_HERO_BANNER_CAROUSELS = "/carousel/Terms/HeroBanner";
export const LOCATIONS_HERO_BANNER_CAROUSELS = "/carousel/Locations/HeroBanner";
export const FEATURED_ITEMS = "/featured-items";
export const FEATURED_LOCATIONS = "/markets/listing";
export const FEATURED_NEAR_LOCATIONS = "/locations/nearest/list";
export const MENU_CATEGORIES = "/categories/listing?items[flags][in][0]=";
export const NAV_MENU_CATEGORIES = "/categories/list";
export const LOCATIONS_DELIVERY = "/locations/delivery/url";
export const CATERING_REQUEST_FORM = "/catering_request";
export const ECLUB_CATERING_LOCATIONS_API = "/eclub/catering_locations";
export const GEO_LOC_CITIES_API = "/geoLocations/cities?size=-1";
export const GEO_LOC_STATES_API = "/geoLocations/states?size=-1";
export const NEARBY_LOCATIONS = "/locations/nearest/list";
export const SUGGESTED_ITEMS = "/upsell/fetch-items";
export const REWARD_CATALOG = "/rewardCatalog";
export const CUSTOMERS = "/customers";
export const VEHICLES = "/vehicles";
export const VEHICLES_INFO = "/vehicle-info/";
export const FORGOT_PASSWORD = "/forgot-password";
export const CHANGE_PASSWORD = "/change-password";
export const VERIFY_LINK = "/verify-link";
export const ORDERS = "/orders";
export const FETCH_USER_ORDER = "/orders/saved-carts/";
export const VIEW_MORE_RECENT_ORDER = "/profileDetails/more-recent-orders";
export const ALL_MARKET_LOCATIONS = "/getAllLocations"
// Site Materilas
export const ROUTE_SITE_MATERIALS = "/sitematerials";

export const SERVER_URL = process.env.REACT_APP_SERVER_API;
export const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const SERVER_URL_WITHOUT_API = process.env.REACT_APP_SERVER_URL;
export const REACT_APP_CLOUD_FRONT = process.env.REACT_APP_CLOUD_FRONT;
export const REACT_APP_IMAGES_VERSION = process.env.REACT_APP_IMAGES_VERSION;
export const REACT_APP_QA_CLOUD_FRONT = process.env.REACT_APP_QA_CLOUD_FRONT;
export const LOCATION_MENU_SERVICE = process.env.REACT_APP_LOCATION_MENU_SERVICE;
export const CUSTOMER_MICRO_SERVICE = process.env.REACT_APP_CUSTOMER_MICRO_SERVICE;
export const ORDER_MICRO_SERIVCE = process.env.REACT_APP_ORDER_MICRO_SERIVCE;
export const PAYMENT_METHOD_SERIVCE = process.env.REACT_APP_PAYMENT_METHOD_SERIVCE;
export const BARCODE_SERVICE = "https://customer-business-service.dev.zupas.net/api/v1";
export const LOYALTY_CHECKIN = "/loyalty-checkin-by-barcode";
export const FACEBOOK_SIGNIN = "/sign-in/connect-with-facebook";
export const APPLE_SIGNIN = "/sign-in/connect-with-apple"
export const FACEBOOK_PROFILE_INFO = "https://graph.facebook.com/v2.5/me?fields=email,birthday,first_name,last_name&access_token=";
export const GOOGLE_SIGNIN = "/sign-in/connect-with-google";
export const GOOGLE_PROFILE_INFO = "https://www.googleapis.com/oauth2/v1/userinfo?access_token=";
export const PICKUP_SLOT_SERVICE = process.env.REACT_APP_CAPACITY_SERVICE

//
export const HISTORY_STATE_FROM_PLACED_ORDER = "placedOrder"

// Routes Constants
export const ROUTE_SELECT_RESTAURANT = "/selectRestaurant";
export const ROUTE_PICKUP_ORDER_FORM = "/pickUpOrder";
export const ROUTE_DELIVERY_ORDER_FORM = "/deliveryOrder";
export const ROUTE_ERROR = "/error";
export const ROUTE_MENU = "/menu";
export const TRY_2_COMBO_ROUTE = "/menu/Try 2 Combo";
export const ROUTE_404 = "/404"
export const ROUTE_HOME = "/";
export const ROUTE_FAQ = "/loyalty-faq"
export const ROUTE_LOCATIONS = "/locations";
export const ROUTE_LOCATIONS_INFO = "/locationinfo";
export const ROUTE_LOCATION_COPY = "/locationcopy";
export const ROUTE_ALL_LOCATIONS = "/all_locations";
export const ROUTE_MENU_DETAILS = "/menu/menuDetails";
export const ROUTE_CATERING_MENU_DETAILS = "/catering_menu/menuDetails";
export const ROUTE_Item_Grid = "/ItemGrid";

export const ROUTE_CAREERS = "/careers";
export const ROUTE_TALK_TO_US = "/talk";
export const ROUTE_OUR_KITCHEN = "/ourKitchen";
export const ROUTE_CATERING = "/catering";
export const ROUTE_CATERING_REQUEST_FORM = "/catering/request";
export const ROUTE_FUNDRAISING = "/fundraising";
export const ROUTE_ORDER_ONLINE = "/orderOnline";
export const ROUTE_CATERING_ORDER = "/orderCatering";
export const ROUTE_VIP_URL = "https://vip.cafezupas.com/login";
export const ROUTE_SIGN_UP_URL = "https://api.punchh.com/customers/sign_up.iframe?slug=cafezupas&sso=1";
export const ROUTE_OLO_URL = "https://cafezupas.olo.com/";
export const ROUTE_GOOGLE_API_URL = "https://maps.googleapis.com/maps/api/geocode/json?address=";
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const GOOGLE_MAP_DEFAULT_ZOOM = 12
export const ROUTE_OLO_API_URL = "https://ordering.api.olo.com/v1.1/restaurants/near?";
export const CAREERS_APPLY_ROUTE_URL = "https://cafezupas.zenapply.com/apply#!/jobs/all";
export const PUNCHH_REGISTER_URL = "https://api.punchh.com/customers/sign_up.iframe?slug=cafezupas&sso=1";
export const PLAYSTORE_CAFEZUPAS_URL = "https://play.google.com/store/apps/details?id=com.zupasmealtime.app&hl=en_US&gl=US";
export const APPSTORE_CAFEZUPAS_URL = "https://apps.apple.com/us/app/caf%C3%A9-zupas/id1464869382";
export const CAFEZUPAS_FACEBOOK_URL = "https://www.facebook.com/";
export const CAFEZUPAS_INSTAGRAM_URL = "https://www.instagram.com/";
export const CAFEZUPAS_PINTEREST_URL = "https://www.pinterest.com/";
export const CAFEZUPAS_TWITTER_URL = "https://twitter.com/";
export const REWARDS_DETAILS_ROUTE = "/cateringRewards";
export const TERMS_ROUTE = "/terms";
export const TERMS_ROUTE_HTML = "/terms.html";
export const PRIVACY_POLICY_ROUTE = "/privacyPolicy";
export const DOWNLOAD_APP = "/download-app";
export const ROUTE_MENU_CATERING = "/cateringMenu";
export const ROUTE_ORDER_LANDING = "/orderLanding";
export const ROUTE_ORDER = "/order";
export const ROUTE_CUSTOMIZE = "/customize";
export const ROUTE_ACCOUNT = "/accountDetails";
export const ROUTE_LOGIN = "/login";
export const ROUTE_CREATE_ACCOUNT = "/createAccount";
export const ROUTE_FORGOT_PASSWORD = "/forgotPassword";
export const ROUTE_NEW_PASSWORD = "/newPassword";
export const ROUTE_PROFILE_DETAILS = "/profileDetails";
export const ROUTE_CHECKOUT = "/checkout";
export const ROUTE_CHECKOUT_DELIVERY = "/checkoutDelivery";
export const ROUTE_CHECKOUT_CURBSIDE = "/checkoutCurbside";
export const ROUTE_TRY2 = "/try2";
export const ROUTE_TRY2_SELECTION = "/try2Selection";
export const ROUTE_TRY2_MENU = "/try2Menu";
export const ROUTE_TRY2_ORDER = "/try2Order";
export const ROUTE_TRY2_COMBO = "/try2Combo";
export const ROUTE_TRY_2_ORDER_CUSTOMIZATION = "/try2OrderCustomization";
export const ROUTE_ADD_INGREDIENTS = "/addIngredients";
export const ROUTE_ORDER_CONFIRMATION = "/orderConfirmation";
export const ROUTE_ORDER_CONFIRMATION_DELIVERY = "/orderConfirmationDelivery";
export const ROUTE_ORDER_CONFIRMATION_GUEST = "/orderConfirmationGuest";
export const PUNCH_SIGNUP_FORM = "/userSignUpForm";
export const PUNCH_REGISTERATION = "/eclub/mobile_signup";
export const ROUTE_ORDER_TRACKING = "/orderTracking";
export const ROUTE_THANK_YOU = "/thankYou"

//For testing purpose, remove these constants when testing is done
export const ROUTE_CONFIRMATION_MODAL = "/confirmation-modal";
export const ROUTE_KIDS_COMBO = "/kidsCombo";
export const ROUTE_WELCOME_SCREEN = "/Welcome";
export const ROUTE_REWARDS_REDEEM = "/rewards";
export const ROUTE_ORDER_HISTORY = "/history";
export const CARD_PLACEHOLDER='xxxx-xxxx-xxxx-';
export const EDIT_VEHICLE_MODAL_TITLE="Vehicle Details";
export const EDIT_ADDRESS_MODAL_TITLE="Delivery Address";
export const ADD_ADDRESS_MODAL=" Delivery Address";
export const PRINT_RECEIPT = '/print-receipt';
export const RE_ORDERING_ROUTE='reordering'
//Query Key Constants
export const GET_REWARD_CATALOG = 'rewardCatalog';
export const GET_ALL_STATES='getAllStates'
export const CARD_NUMBER_PLACEHOLDER='XXXX-XXXX-XXXX-'

//SCROLL
export const SCROLL_TO_PROFILE_FAV_ITEMS = "sixth"
export const SCROLL_FAV_ITEMS_DIV_ID = "scrollToFavItems"

//CART
export const GET_CART = '/cart';
export const CART_ITEM = 'item';

//ITEM SIZES
export const FULL_SIZE = { id: "FULL", title_case: "Full", upper_case: "FULL"}
export const LARGE_SIZE = { id: "FULL", title_case: "Large", upper_case: "LARGE"}
export const REGULAR_SIZE = { id: "HALF", title_case: "Regular", upper_case: "REGULAR"}
export const HALF_SIZE = { id: "HALF", title_case: "Half", upper_case: "HALF"}

//SLOT TYPE
export const ASAP_TYPE = 'ASAP'

//ITEM TYPES
export const SINGLE_ITEM = 'single'
export const TRY_TO_COMBO_ITEM = 'try_2_combo'
export const KIDS_COMBO_ITEM = 'kids_combo'

//Service Prefixes
export const ORDER_SERVICE = 'cart';

//Menu 
export const SEASONAL = "Seasonal"

//Delivery Fee and charges
export const DELIVERY_FEE=4
export const SERVICE_FEE=2

export const MODIFIER_LOCATION = "modifier_location"


export const SORT_BY_ASC = "asc"
export const SORT_BY_DESC  = "desc"

export const PICK_UP_METHOD_ONLINE_TAKEOUT = "Online Takeout"
export const PICK_UP_METHOD_CZ_DELIVERY = "CZ Delivery"
export const DO_NOT_SHOW = 'do not show'

export const EXPIRATION_DATE_MASK = [/[0-9]/,/\d/,'/',/\d/,/\d/]

export const BOWLS = 'bowls'
export const SALADS = 'salads'
export const SANDWICHES = "sandwiches"
export const DRINKS = "drinks"

// API Refetch Interval
export const GET_ORDER_DETAILS_REFETCH_INTERVAL =
  process.env.REACT_APP_GET_ORDER_DETAILS_API_REFETCH_INTERVAL;

// Order status refetch interval time 
export const GET_ORDER_STATUS_REFETCH_INTERVAL = 120000;

// Buffer Times in Milliseconds
export const BUFFER_TIME_QUEUE_CREATION = 60000

export const ROUTE_CATERING_OLO = "https://cafezupascatering.olo.com/"
export const SPRINKLR_CONTACT_US_FORM = "https://forms-prod.sprinklr.com/guided-workflows/654542960ddd317914efd4d8?gwId=362510";
/*These are the brink locations with which we will hard code coming soon status*/
export const COMING_SOON_LOCATIONS = process.env.REACT_APP_COMING_SOON_LOCATIONS || "";

export const TRANSPARENCY_IN_COVERAGE = "https://www.cigna.com/legal/compliance/machine-readable-files";

// Braze Analytics Event Names
export const LOYALTY_SIGN_IN = "LOYALTY_SIGN_IN";
export const ITEM_ADDED_TO_CART = "ITEM_ADDED_TO_CART";
export const ORDER_PLACED = "ORDER_PLACED";
export const ORDER_CANCELLED = "ORDER_CANCELLED";
export const CHECKOUT_STARTED = "CHECKOUT_STARTED";
export const ADDED_TO_CART = "ADDED_TO_CART";
export const VIEW_PAGE = "VIEW_PAGE";
export const LOYALTY_ACCOUNT_CREATED = "LOYALTY_ACCOUNT_CREATED";
export const ADD_TO_FAVORITES = "ADD_TO_FAVORITES";
export const FORM_SUBMISSION = "FORM_SUBMISSION";

// Big Js
export const UPPERCASE_ADD = 'ADD'
export const UPPERCASE_SUB = 'SUB'
export const UPPERCASE_DVISION = 'DVISION'
export const UPPERCASE_MULTI = 'MULTI'

// API Error Codes
export const CODE_EMAIL_VERIFICATION = 1;
export const CODE_PASSWORD_RESET = 2;
export const CODE_CLOSE_MODAL_SUCCESS = 30;
export const CODE_ROUTE_TO_SIGNUP = 31;


// API Error Code Messages
export const API_CODE_MESSAGES = {
  [CODE_EMAIL_VERIFICATION]: "Resend Verification Email",
  [CODE_PASSWORD_RESET]: "Resend Password Reset Email",
}

// Loyalty Offers
export const OFFER_TYPE_FIXED_DISCOUNT = "fixed_amount_discount";
export const OFFER_TYPE_PERCENT_DISCOUNT = "percent_discount";

// Loyalty Offers Eligibility
export const OFFER_REWARD_REDEEMED = "REWARD_REDEEMED";
export const OFFER_ELIGIBLE_ITEMS = "ELIGIBLE_ITEMS";
export const OFFER_ELIGIBLE_SUBTOTAL_RANGE = "ELIGIBLE_SUBTOTAL_RANGE";
export const OFFER_ELIGIBLE_LOCATIONS = "ELIGIBLE_LOCATIONS";
export const OFFER_DISCOUNTED_SUBTOTAL_ZERO = "OFFER_DISCOUNTED_SUBTOTAL_ZERO";

// Error Toast Messages
export const SOMETHING_WENT_WRONG = "Something went wrong!"

// Contact info form fields
export const PHONE_FIELD = "phone"
export const LOCATION_FIELD = "locations"
export const ID_FIELD = "id"

//Login types
export const GOOGLE_LOGIN = "google"
export const FACEBOOK_LOGIN = "facebook"
export const APPLE_LOGIN = "apple"
export const EMAIL_LOGIN = "email"

//Max name length check for location search 
export const MIN_LOCATION_NAME_LENGTH = 3
