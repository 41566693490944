import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import styleClasses from "../../ItemGrid.module.scss";
import CurbSideVehicleDetails from "./Curbside/CurbSideVehicleDetails";
import {
  DELIVERY_ORDER_TYPE,
  PICK_UP_METHOD_IN_STORE,
  ROUTE_CHECKOUT,
  ROUTE_LOCATIONS,
  PICK_UP_METHOD_GOOD_LIFE_LANE
} from "../../../../constants";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/store";
import {setShowCart, updatePickUpMethod} from "../../../../redux/slices/cartSlice";
import { useHistory } from "react-router";
import PickUpSlots from "./PickupSlots";
import { useStoreLocation } from "../../../../context/StoreLocation";
import { brazeLogCustomEventCheckoutStarted } from "../../../../helpers/brazeHelper";
import useCheckMobileScreen from "../../../../hooks/useCheckMobileScreen";

interface IOrderType {
  subTotal?: number;
  tax?: number;
  total?: number;
  closePickUpMethodModal?: (boolean) => void;
  editPickupType?: (boolean) => void;
  handleChangeOrderType?: boolean;
  isEditSlot? : boolean
}

export const OrderType = (props: IOrderType) => {
  
  const {tax, subTotal, total, closePickUpMethodModal, editPickupType, handleChangeOrderType, isEditSlot} = props
  const { locationInfo } = useStoreLocation();
  const {
    order_types: { Pickup },
  } = locationInfo.selectedStore;
  const doStoreHasGoodLifeLane = Pickup?.map(
    (pickUpTypes) => pickUpTypes.value
  ).includes(PICK_UP_METHOD_GOOD_LIFE_LANE);

  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const cart = useAppSelector((state) => state.cart);
  const isItMobile = useCheckMobileScreen();
  const pickUpMethodDispatch = useAppDispatch();
  const history = useHistory();
  const isShownVehicleDetails = (isShown: boolean) => {
    setShowVehicleDetails(isShown);
  }
  const closeShowVehicleModal = () => {
    closePickUpMethodModal(true)
    pickUpMethodDispatch(setShowCart(false))
    const slotDetails = cart?.orderDetails?.PickupSlotDetails;
    brazeLogCustomEventCheckoutStarted(cart, {tax, subTotal, total}, isItMobile, slotDetails)
    history.push(ROUTE_CHECKOUT)
  }
  let selectedPickupMethod = useAppSelector(
    (state) => ( state.cart.orderDetails?.PickupSlotDetails?.pickupMethod != DELIVERY_ORDER_TYPE ? state.cart.orderDetails?.PickupSlotDetails?.pickupMethod : PICK_UP_METHOD_IN_STORE )
  );
  const [pickupMethod, setPickupMethod] = useState<string>(selectedPickupMethod || PICK_UP_METHOD_IN_STORE)
  
  const defaultPickupMethod = selectedPickupMethod
    ? selectedPickupMethod
    : doStoreHasGoodLifeLane
    ? PICK_UP_METHOD_GOOD_LIFE_LANE
    : PICK_UP_METHOD_IN_STORE;
    
  useEffect(() => {
      handlePickupMethod(selectedPickupMethod);
  } , [selectedPickupMethod]);

  const handlePickupMethod = (pickUpMethod: string) => {
   pickUpMethodDispatch(updatePickUpMethod(pickUpMethod));
  };
  const handleChangeToDeliveryModal = () => {
     editPickupType(true)  
     closePickUpMethodModal(true)
  
  }

  const handleLocationSelect = () => {
    closePickUpMethodModal(true)
    pickUpMethodDispatch(setShowCart(false));
    history.push(ROUTE_LOCATIONS)
  }

  return (
    <div
      className={`${styleClasses.order_pickup_wrapper} order-pickup-wrapper my-4`}
    >
      {!showVehicleDetails &&
        <>
          <h5 className="f-s22 font-Cls text-center fw-normal pb-3 text-capitalize">
            Select a Pick-Up Method
          </h5>
          <Tab.Container 
            defaultActiveKey={defaultPickupMethod}
          >
            <Nav variant="pills" className="d-flex justify-content-center">
              {
                Pickup && Pickup?.map(pickUpMethod => {
                  return (
                    <Nav.Item key={pickUpMethod.id}>
                      <Nav.Link
                        eventKey={pickUpMethod.value}
                        className={`${styleClasses.order_type} mx-2 text-center `}
                        onClick={() => setPickupMethod(pickUpMethod.value)}
                      >
                        {pickUpMethod.title}
                        {pickUpMethod.description ? (
                          <p className="mb-0 font-rale f-s14 f-w6 font-Visby-cf">
                            {pickUpMethod.description}
                          </p>
                        ) : null}
                      </Nav.Link>
                    </Nav.Item>
                  )
                })
              }
            </Nav>
            <Tab.Content>
              <PickUpSlots
                tax={tax}
                subTotal={subTotal}
                total={total} 
                isEditSlot={isEditSlot} 
                closePickUpMethodModal={closePickUpMethodModal}
                isShownVehicleDetails={isShownVehicleDetails} 
                pickupMethod={pickupMethod} 
              />
            </Tab.Content>
            <div className="d-flex justify-content-center mb-3">
              <button
                  type="button"
                  className={`btn btn-custom p-0 f-w6 p-16 color-sky-blue-light font-Vcf`}
                  onClick={() => handleLocationSelect()}
              >
                Change Location
              </button>
            </div>
           { 
           !handleChangeOrderType && 
           <div className="d-flex justify-content-center mb-5">
                <button
                  type="button"
                  className={`btn btn-custom p-0 f-w6 p-16 color-sky-blue-light font-Vcf`}
                  onClick = {handleChangeToDeliveryModal}
                >
                  Change To Delivery
                </button>
              </div>
              }
          </Tab.Container>

        </>
      }

      {showVehicleDetails && <CurbSideVehicleDetails closeShowVehicleModal={closeShowVehicleModal} isShownVehicleDetails={isShownVehicleDetails} />}
    </div>
  );
};
