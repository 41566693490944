import React, { useRef, useEffect } from 'react';
import styleClasses from './Confirmation.module.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Container, Row, Col, NavLink} from 'react-bootstrap';
import { SectionDataModel } from "../../models/sections.model";
import Parser from 'html-react-parser';
import Video from "../../components/Video/video";

interface ConfirmationProps {
    Confirmation_data: SectionDataModel;
    modalContent?:string;
    isScrollable?:boolean;
}
const Confirmation: React.FunctionComponent<ConfirmationProps> = ({Confirmation_data, modalContent, isScrollable}) => {

    const divRef = useRef(null);
    const scrollToDiv = () => {
        if (divRef && isScrollable) {
            let prevValue = divRef?.current?.getBoundingClientRect().top;
            const handle = setInterval(() => {
                let nextValue = divRef?.current?.getBoundingClientRect().top;

                if (nextValue === prevValue) {
                    clearInterval(handle);
                    window.scrollTo({
                        top: nextValue - 180,
                        behavior: "smooth",
                    });
                } else {
                    prevValue = nextValue;
                }
            }, 100);
        }
    }

    useEffect(() => {
        scrollToDiv();
    }); // <-- empty array means 'run once'

    return (
        <Container className={styleClasses.confirmation_content_wrap + ' ' + Confirmation_data.Section_controller + ' text-center px-3 px-sm-3'} ref={divRef}>
            {/* Render section_title */}
            {Confirmation_data.section_title ? 
            <h4 className={styleClasses.title_wrap + ' text-uppercase mb-3'}>
               {Parser (Confirmation_data.section_title)}
            </h4> : ""}

            {/* Render section_details */}
            {Confirmation_data.section_details ? 
            <p className={styleClasses.text_sty + ' text_style1'}>
               {Parser(Confirmation_data.section_details)}
            </p> : ""}

            {/* Render sub_title */}
            {Confirmation_data.sub_title ? 
            <h6 className='text-uppercase mb-4'>{Parser(Confirmation_data.sub_title)}</h6>
            : ""}

            {/* Render video_source */}
            {Confirmation_data.video_source ? 
            <Video source={Confirmation_data.video_source} videoScale="56.25%" playBackSpeed="2" />
            : ""}
            
            <div className={Confirmation_data.button_class ? styleClasses.about_btn_wrap + ' ' + Confirmation_data.btn_controller + " text-center w-100" : " d-none"}>
                <NavLink className={Confirmation_data.button_class + ' btn btn-large'} href={Confirmation_data.button_link} target={Confirmation_data.button_target ? Confirmation_data.button_target : 'self'}>
                    {Confirmation_data.button_text}
                </NavLink>
            </div>
        </Container>
    );
}

export default Confirmation;
