//TODO: replace the axios import with the local axios file
import axios from "axios";
import * as CONSTANT from "../../constants";
import { syncCartOnLogin } from "../../helpers/cartHelperMethods/syncCart";
import { getVisitorId } from "../../helpers/helperMethods";
import { Toast_Func } from "../../helpers/toast.helper";
import {isLogoutAction, resetCart } from "../../redux/slices/cartSlice";
import {resetCheckout } from "../../redux/slices/checkoutSlice";
import { customerBusinessService } from "../../services";
import { brazeLogCustomEventLoyaltyAccountCreated, brazeSetUser } from "../../helpers/brazeHelper";

export async function login(dispatch, loginPayload, cartDispatch, updateAccountStatusModal, isCheckoutLogin = false) {
  try {
    dispatch({ type: "REQUEST_LOGIN" });
    //TODO: relace post call later
    const visitorId = getVisitorId()
    const newLoginPayload = { ...loginPayload }
    newLoginPayload["visitor_id"] = visitorId
    const response = await customerBusinessService.login(newLoginPayload)
    if (response.data.status) {
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data.data });
      localStorage.setItem(
        CONSTANT.CURRENT_USER,
        JSON.stringify({ ...response.data.data, visitor_id: getVisitorId() })
      );
      if(!isCheckoutLogin) syncCartOnLogin(cartDispatch);
    }
  } catch (error) {
    dispatch({
      type: "LOGIN_ERROR",
      //An array of error messages is sent form the API
      error: error?.response?.data?.data?.errors[0],
    });
    const errorsObject = error.response.data.errors;
    const errorCode = errorsObject?.code;
    if (errorCode) {
      const buttonText = CONSTANT.API_CODE_MESSAGES[errorCode]
      updateAccountStatusModal(
        errorsObject.header, 
        errorsObject.text, 
        errorCode,
        buttonText,
        loginPayload.email,
        true,
      );
    } else Toast_Func({ status: false, message: error.response.data.errors[0] });
  }

}

export function logout(dispatch, cartDispatch) {
  dispatch({ type: "LOGOUT" });
  cartDispatch(resetCart())
  cartDispatch(resetCheckout())
  localStorage.setItem(
    CONSTANT.CURRENT_USER,
    JSON.stringify({ visitor_id: getVisitorId(), logged_out: true })
  );
}

export function unauthorizeLogout(dispatch) {
  dispatch(resetCart())
  dispatch(isLogoutAction())
  dispatch(resetCheckout())
  localStorage.setItem(
    CONSTANT.CURRENT_USER,
    JSON.stringify({ visitor_id: getVisitorId() })
  );
}

export async function socialSignIn(dispatch, loginPayload, signInName, cartDispatch, setLocationCallback, isCheckoutLogin = false){
  try {
    let signInLink = ""
    switch (signInName) {
      case "GOOGLE_SIGNIN":
        signInLink = CONSTANT.GOOGLE_SIGNIN
            break;
      case "FACEBOOK_SIGNIN":
        signInLink = CONSTANT.FACEBOOK_SIGNIN
            break;
      case "APPLE_SIGNIN":
        signInLink= CONSTANT.APPLE_SIGNIN
    }
    dispatch({ type: "REQUEST_LOGIN" });
    //TODO: relace post call later
    const visitorId = getVisitorId()
    const newLoginPayload = { ...loginPayload }
    newLoginPayload["visitor_id"] = visitorId
    let response = await axios.post(
        `${CONSTANT.CUSTOMER_MICRO_SERVICE}${signInLink}`,
        newLoginPayload
    );

    if (response.data.status) {
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data.data });
      localStorage.setItem(
          CONSTANT.CURRENT_USER,
          JSON.stringify({ ...response.data.data, visitor_id: getVisitorId() })
      );
      if (response.data.data.is_new_user)
        brazeLogCustomEventLoyaltyAccountCreated(response.data.data);
      if(!isCheckoutLogin) syncCartOnLogin(cartDispatch);
    }
  } catch (error) {
    Toast_Func({ status: false, message: error.response.data.errors[0] });
  }
}

export async function guestLogin(dispatch,cartDispatch, loginPayload) {
  try {
    dispatch({ type: "REQUEST_LOGIN" });
    const response = await customerBusinessService.guestLogin(loginPayload)
    if (response.data.status) {
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data.data });
      brazeSetUser(response?.data?.data?.id)
      localStorage.setItem(
        CONSTANT.CURRENT_USER,
        JSON.stringify(response?.data?.data)
      );
      syncCartOnLogin(cartDispatch);
      return true;
    }
  } catch (error) {
    dispatch({
      type: "LOGIN_ERROR",
      error: error?.response?.data?.data?.errors[0],
    });
    return false;
  }
}