import { useQuery } from "react-query";
import { shouldRelease } from "../helpers/helperMethods";
import { locationMenuService } from "../services";

interface requestPayload {
  items: { id: number; size: string }[];
  location_id: number;
  business_date: string;
  hour: string;
}

const getUpsellItems = async (payload: requestPayload) => {
  const response = await locationMenuService.getUpsellItems(payload)
  return response.data.data;
};

export default function useSuggestedItems(payload: requestPayload) {
  const item = payload?.items?.length > 0;
  return useQuery(["upsellItems"], () => getUpsellItems(payload), {
    enabled: !!payload?.location_id && item && shouldRelease("APIs", "upsellItems"),
  });
}
