import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import {LocationModel} from "../models/location.model";
import {DELIVERY, GOOGLE_API_KEY, GOOGLE_MAP_DEFAULT_ZOOM} from "../constants";
import Geocode from "react-geocode";
import { IDoorDash } from "../models/tracking";


const MapMarker: any = ({ text }) => (
    <div
        style={{
            display: "inline-flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",
            transform: "translate(-50%, -50%)",
        }}
    >
        {text}
    </div>
);

const OrderTrackingMap: React.FC<{storeLocation?: LocationModel;
    customerDetails: {
        first_name: string;
        address: string;
        street_1: string;
        city: string;
        state: string;
        zipcode: string;
    };
    pickUpMethod:string;
    doorDash: IDoorDash;
}> = ({ customerDetails, storeLocation, pickUpMethod, doorDash }) => {
    const [customerAddressLatLng, setCusomterAddressLatLng] = useState<{lat: number, lng: number} | null>(null)

    useEffect(() => {
        Geocode.fromAddress(customerDetails?.address).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              setCusomterAddressLatLng({lat, lng})
            },
            (error) => {
            //   console.error(error);
            }
          );
    }, [])

    return(
        <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            defaultCenter={{ lat: storeLocation?.lat, lng: storeLocation?.long }}
            defaultZoom={GOOGLE_MAP_DEFAULT_ZOOM}
        >
            <MapMarker
                lat={storeLocation?.lat}
                lng={storeLocation?.long}
                text={<img src={require('../../src/images/location/location-marker-blue.svg')}  width="20" alt="location marker"/> }
            />
            {pickUpMethod === DELIVERY && doorDash?.lat && doorDash?.lng ? <MapMarker
                lat={doorDash?.lat}
                lng={doorDash?.lng}
                text={<img src={require('../../src/images/location/doordash.png')}  width="20" alt="doordash"/> }
            /> : null}
            {pickUpMethod === DELIVERY && customerAddressLatLng ? <MapMarker
                lat={customerAddressLatLng?.lat}
                lng={customerAddressLatLng?.lng}
                text={<img src={require('../../src/images/home.png')}  width="20" alt="home"/> }
            /> : null}
        </GoogleMapReact>
    )
}

export default OrderTrackingMap;