import React from "react";

export const UserCircleIconMobile = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.5"
        y="0.5"
        width="35"
        height="35"
        rx="17.5"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 35 -4.37114e-08)"
        fill="#4C86A0"
      />
      <path
        d="M18 18C15.2386 18 13 15.7614 13 13C13 10.2386 15.2386 8 18 8C20.7614 8 23 10.2386 23 13C23 15.7614 20.7614 18 18 18Z"
        fill="white"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M28 25C27.0614 23.1946 25.6304 21.6784 23.8654 20.6195C22.1005 19.5605 20.0704 19 18 19C15.9296 19 13.8995 19.5605 12.1346 20.6195C10.3696 21.6784 8.93864 23.1945 8 25"
        fill="white"
      />
      <path
        d="M28 25C27.0614 23.1946 25.6304 21.6784 23.8654 20.6195C22.1005 19.5605 20.0704 19 18 19C15.9296 19 13.8995 19.5605 12.1346 20.6195C10.3696 21.6784 8.93864 23.1945 8 25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 25L28 25" stroke="white" />
      <rect
        x="-0.5"
        y="0.5"
        width="35"
        height="35"
        rx="17.5"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 35 -4.37114e-08)"
        stroke="white"
      />
    </svg>
  );
};

export const CateringUsericon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill="#AA182C" />
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="white" />
      <path
        d="M17.9992 11C13.36 11 9.59961 14.306 9.59961 18.3846C9.59961 18.5918 9.60972 18.7969 9.62916 19H26.3701C26.3895 18.7969 26.3996 18.5918 26.3996 18.3846C26.3988 14.306 22.6384 11 17.9992 11Z"
        fill="white"
        stroke="#AA182C"
        stroke-miterlimit="10"
      />
      <path
        d="M28.4145 22H7.58591C7.373 22 7.2002 21.8208 7.2002 21.6V20.4C7.2002 20.1792 7.373 20 7.58591 20H28.4145C28.6274 20 28.8002 20.1792 28.8002 20.4V21.6C28.8002 21.8208 28.6274 22 28.4145 22Z"
        fill="white"
        stroke="white"
        stroke-miterlimit="10"
      />
      <rect
        x="15.8496"
        y="10.25"
        width="4.3"
        height="0.5"
        fill="white"
        stroke="white"
        stroke-width="0.5"
      />
    </svg>
  );
};

export const FabIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66669 10H33.3334"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66669 20H33.3334"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66669 30H33.3334"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const FabCloseIcon = () => {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.782715 1.36621L21.2173 23.3903M21.2173 1.36621L0.782715 23.3903"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const CateringDirectionIcon = () => {
  return (
    <svg
      width="53"
      height="18"
      viewBox="0 0 53 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.842 8.7481L18.9534 9.20677L18.9665 9.23213L18.981 9.2567L19.842 8.7481ZM25.8344 1.60665L25.6258 2.58466L25.8344 1.60665ZM0.507877 8.06703C0.0271297 8.33888 -0.142224 8.94897 0.129619 9.42972L4.55956 17.264C4.83141 17.7447 5.44151 17.9141 5.92225 17.6422C6.403 17.3704 6.57235 16.7603 6.30051 16.2795L2.36278 9.31576L9.32657 5.37804C9.80732 5.10619 9.97667 4.4961 9.70483 4.01535C9.43298 3.5346 8.82289 3.36525 8.34214 3.63709L0.507877 8.06703ZM52.6523 10.0232C48.8761 10.1748 45.2416 10.66 41.8613 11.158C38.4608 11.659 35.3529 12.1669 32.5431 12.3876C26.8976 12.8312 22.9667 12.0716 20.703 8.23949L18.981 9.2567C21.8765 14.1585 26.9302 14.8348 32.6998 14.3815C35.5976 14.1538 38.8049 13.6299 42.1528 13.1367C45.5209 12.6404 49.0681 12.1687 52.7325 12.0216L52.6523 10.0232ZM20.7306 8.28942C20.1093 7.08573 20.0093 6.15048 20.1467 5.44548C20.2839 4.74146 20.6801 4.15094 21.2651 3.67483C22.484 2.68273 24.37 2.3168 25.6258 2.58466L26.043 0.628651C24.2171 0.2392 21.7086 0.735156 20.0026 2.12368C19.1251 2.83788 18.4263 3.81737 18.1836 5.06297C17.9411 6.3076 18.1764 7.70162 18.9534 9.20677L20.7306 8.28942ZM25.6258 2.58466C26.7851 2.83191 27.4041 3.60611 27.4934 4.49693C27.5854 5.41506 27.1286 6.70047 25.5768 7.8637C22.4501 10.2075 15.0854 11.8095 1.26756 7.97394L0.732624 9.90107C14.719 13.7834 22.8818 12.3834 26.7764 9.46401C28.7353 7.99563 29.6625 6.08415 29.4834 4.29743C29.3016 2.4834 27.9942 1.0448 26.043 0.628651L25.6258 2.58466Z"
        fill="white"
      />
    </svg>
  );
};

export const LocationIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7284_1440)">
        <path
          d="M17.7871 27.1593L23.4441 21.5023C23.4441 21.5023 23.4441 21.5023 23.4441 21.5023C24.796 20.1504 25.7166 18.428 26.0895 16.5528C26.4625 14.6777 26.271 12.7341 25.5394 10.9678C24.8077 9.20144 23.5687 7.69175 21.979 6.62958C20.3894 5.56742 18.5204 5.00049 16.6086 5.00049C14.6967 5.00049 12.8278 5.56742 11.2381 6.62958C9.64844 7.69175 8.40944 9.20144 7.67778 10.9678C6.94612 12.7341 6.75466 14.6777 7.12761 16.5528C7.50055 18.4279 8.42112 20.1503 9.77292 21.5022L9.77302 21.5023M17.7871 27.1593L9.0659 22.2094L9.77302 21.5023M17.7871 27.1593L17.7868 27.1597C17.551 27.3952 17.1867 27.739 16.8507 28.0174C16.7622 28.0908 16.681 28.1551 16.6088 28.2094C16.5367 28.1552 16.4557 28.0909 16.3675 28.0177C16.0317 27.7393 15.6676 27.3954 15.4316 27.1596C15.4315 27.1595 15.4314 27.1594 15.4313 27.1593M17.7871 27.1593H15.4313M9.77302 21.5023L15.4313 27.1593M9.77302 21.5023L15.4313 27.1593"
          stroke="#3D3935"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.6086 18.6665C18.8178 18.6665 20.6086 16.8756 20.6086 14.6665C20.6086 12.4574 18.8178 10.6665 16.6086 10.6665C14.3995 10.6665 12.6086 12.4574 12.6086 14.6665C12.6086 16.8756 14.3995 18.6665 16.6086 18.6665Z"
          stroke="#3D3935"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7284_1440">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.608643)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LocationFilledIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7284_129)">
        <path
          d="M24.1512 22.2094L18.4939 27.8668C17.9939 28.3663 16.9419 29.3335 16.6086 29.3335C16.2752 29.3335 15.2246 28.3663 14.7246 27.8668L9.0659 22.2094C7.57419 20.7177 6.55835 18.817 6.14682 16.7479C5.73528 14.6788 5.94655 12.5341 6.7539 10.5851C7.56125 8.63603 8.92843 6.97015 10.6825 5.79811C12.4367 4.62606 14.4989 4.00049 16.6086 4.00049C18.7182 4.00049 20.7805 4.62606 22.5346 5.79811C24.2887 6.97015 25.6559 8.63603 26.4632 10.5851C27.2706 12.5341 27.4818 14.6788 27.0703 16.7479C26.6588 18.817 25.6429 20.7177 24.1512 22.2094Z"
          fill="#4C86A0"
        />
        <path
          d="M16.6086 18.6667C18.8178 18.6667 20.6086 16.8759 20.6086 14.6667C20.6086 12.4576 18.8178 10.6667 16.6086 10.6667C14.3995 10.6667 12.6086 12.4576 12.6086 14.6667C12.6086 16.8759 14.3995 18.6667 16.6086 18.6667Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7284_129">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.608643)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DeliveryIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.27555 22.6667C7.27555 23.3739 7.5565 24.0522 8.0566 24.5523C8.5567 25.0524 9.23498 25.3333 9.94222 25.3333C10.6495 25.3333 11.3277 25.0524 11.8278 24.5523C12.3279 24.0522 12.6089 23.3739 12.6089 22.6667M7.27555 22.6667C7.27555 21.9594 7.5565 21.2811 8.0566 20.781C8.5567 20.281 9.23498 20 9.94222 20C10.6495 20 11.3277 20.281 11.8278 20.781C12.3279 21.2811 12.6089 21.9594 12.6089 22.6667M7.27555 22.6667H4.60889V14.6667M12.6089 22.6667H20.6089M20.6089 22.6667C20.6089 23.3739 20.8898 24.0522 21.3899 24.5523C21.89 25.0524 22.5683 25.3333 23.2756 25.3333C23.9828 25.3333 24.6611 25.0524 25.1612 24.5523C25.6613 24.0522 25.9422 23.3739 25.9422 22.6667M20.6089 22.6667C20.6089 21.9594 20.8898 21.2811 21.3899 20.781C21.89 20.281 22.5683 20 23.2756 20C23.9828 20 24.6611 20.281 25.1612 20.781C25.6613 21.2811 25.9422 21.9594 25.9422 22.6667M25.9422 22.6667H28.6089V17.3333C28.6089 16.6261 28.3279 15.9478 27.8278 15.4477C27.3277 14.9476 26.6495 14.6667 25.9422 14.6667H24.6089M4.60889 14.6667L7.27555 8H19.2756L24.6089 14.6667M4.60889 14.6667H24.6089M16.6089 14.6667V8"
        stroke="#4C86A0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.1089 15L28.6089 22.5H26.1089C26.1089 22 25.1089 20 23.1089 20C21.5089 20 20.7756 21.6667 20.6089 22.5H12.6089C12.6089 21.5 11.6089 20 9.60889 20C8.00889 20 7.27555 21.6667 7.10889 22.5H4.60889V14.5H12.6089H18.1089L28.1089 15Z"
        fill="#4C86A0"
      />
    </svg>
  );
};

export const CartBagIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="34"
      viewBox="0 0 36 34"
      fill="none"
    >
      <path
        d="M33.6089 7.95654H3.00019C2.23179 7.95654 1.60889 8.57945 1.60889 9.34785V31.6087C1.60889 32.3771 2.23179 33 3.00019 33H33.6089C34.3773 33 35.0002 32.3771 35.0002 31.6087V9.34785C35.0002 8.57945 34.3773 7.95654 33.6089 7.95654Z"
        stroke="#3D3935"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3481 13.5217V7.95652C11.3481 6.11154 12.0811 4.34212 13.3857 3.03752C14.6903 1.73292 16.4597 1 18.3047 1C20.1496 1 21.9191 1.73292 23.2237 3.03752C24.5283 4.34212 25.2612 6.11154 25.2612 7.95652V13.5217"
        stroke="#3D3935"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const UserCircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M16.6086 28.5C9.98122 28.5 4.60864 23.1274 4.60864 16.5C4.60864 9.87258 9.98123 4.5 16.6086 4.5C23.2361 4.5 28.6086 9.87258 28.6086 16.5C28.6086 23.1274 23.2361 28.5 16.6086 28.5Z"
        stroke="#3D3935"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M16.6086 20.5C13.8472 20.5 11.6086 18.2614 11.6086 15.5C11.6086 12.7386 13.8472 10.5 16.6086 10.5C19.3701 10.5 21.6086 12.7386 21.6086 15.5C21.6086 18.2614 19.3701 20.5 16.6086 20.5Z"
        stroke="#3D3935"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M24.6335 25.4218C23.8803 23.9408 22.7319 22.6971 21.3155 21.8284C19.8991 20.9598 18.27 20.5 16.6084 20.5C14.9469 20.5 13.3178 20.9598 11.9014 21.8284C10.485 22.6971 9.33659 23.9407 8.58333 25.4217"
        stroke="#3D3935"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const LocationOutlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="27"
      viewBox="0 0 25 27"
      fill="none"
    >
      <path
        d="M8.60856 11.6668C8.60856 12.7276 9.02999 13.745 9.78013 14.4952C10.5303 15.2453 11.5477 15.6668 12.6086 15.6668C13.6694 15.6668 14.6868 15.2453 15.437 14.4952C16.1871 13.745 16.6086 12.7276 16.6086 11.6668C16.6086 10.6059 16.1871 9.58849 15.437 8.83834C14.6868 8.08819 13.6694 7.66677 12.6086 7.66677C11.5477 7.66677 10.5303 8.08819 9.78013 8.83834C9.02999 9.58849 8.60856 10.6059 8.60856 11.6668Z"
        stroke="#3D3935"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1512 19.2094L14.4939 24.8668C13.9939 25.3663 13.316 25.6469 12.6092 25.6469C11.9025 25.6469 11.2246 25.3663 10.7246 24.8668L5.0659 19.2094C3.57419 17.7176 2.55835 15.817 2.14682 13.7479C1.73528 11.6788 1.94655 9.53412 2.7539 7.58507C3.56125 5.63602 4.92843 3.97015 6.68254 2.79811C8.43665 1.62606 10.4989 1.00049 12.6086 1.00049C14.7182 1.00049 16.7805 1.62606 18.5346 2.79811C20.2887 3.97015 21.6559 5.63602 22.4632 7.58507C23.2706 9.53412 23.4818 11.6788 23.0703 13.7479C22.6588 15.817 21.6429 17.7176 20.1512 19.2094Z"
        stroke="#3D3935"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const UserCircleFilledIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.10889" y="1" width="31" height="31" rx="15.5" fill="#4C86A0" />
      <rect x="1.10889" y="1" width="31" height="31" rx="15.5" stroke="white" />
      <path
        d="M16.6089 17.168C14.3997 17.168 12.6089 15.3771 12.6089 13.168C12.6089 10.9588 14.3997 9.16797 16.6089 9.16797C18.818 9.16797 20.6089 10.9588 20.6089 13.168C20.6089 15.3771 18.818 17.168 16.6089 17.168Z"
        fill="white"
        stroke="white"
        stroke-miterlimit="10"
      />
      <path
        d="M24.6089 22.7673C23.858 21.3229 22.7132 20.11 21.3012 19.2629C19.8893 18.4157 18.2652 17.9673 16.6089 17.9673C14.9526 17.9673 13.3285 18.4157 11.9166 19.2628C10.5046 20.11 9.3598 21.3229 8.60889 22.7673"
        fill="white"
      />
      <path
        d="M24.6089 22.7673C23.858 21.3229 22.7132 20.11 21.3012 19.2629C19.8893 18.4157 18.2652 17.9673 16.6089 17.9673C14.9526 17.9673 13.3285 18.4157 11.9166 19.2628C10.5046 20.11 9.3598 21.3229 8.60889 22.7673"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M8.60889 22.7671L24.6089 22.7671" stroke="white" />
    </svg>
  );
};

export const VideoPlayIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="white" />
      <g clipPath="url(#clip0_7231_104608)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.7558 13.6773C15.0532 13.2997 14.1936 13.7966 14.1936 14.5804V25.4193C14.1936 26.2262 15.099 26.72 15.8015 26.2965L25.3058 20.5652C25.9932 20.1507 25.968 19.1654 25.2601 18.785L15.7558 13.6773Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7231_104608">
          <rect
            width="12.9032"
            height="12.9032"
            fill="white"
            transform="translate(13.5483 13.5483)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ChevronIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M6.35303 9L12.353 15L18.353 9"
        stroke="#3D3935"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <g clipPath="url(#clip0_7565_158)">
        <path
          d="M7.33331 16H26"
          stroke="#3D3935"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.6666 21.3333L26 16"
          stroke="#3D3935"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.6666 10.6667L26 16.0001"
          stroke="#3D3935"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7565_158">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.666626)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NotesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <g clipPath="url(#clip0_4821_11192)">
        <path
          d="M18 14H30M18 22H30M26 30H30M10 42V10C10 8.93913 10.4214 7.92172 11.1716 7.17157C11.9217 6.42143 12.9391 6 14 6H34C35.0609 6 36.0783 6.42143 36.8284 7.17157C37.5786 7.92172 38 8.93913 38 10V42L32 38L28 42L24 38L20 42L16 38L10 42Z"
          stroke="#3D3935"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4821_11192">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RewardFaqIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 7H15M9 11H15M9 15H13M5 5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5Z"
        stroke="#3D3935"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const LoyaltyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_7735_1923)">
        <path
          d="M6 9C6 10.5913 6.63214 12.1174 7.75736 13.2426C8.88258 14.3679 10.4087 15 12 15C13.5913 15 15.1174 14.3679 16.2426 13.2426C17.3679 12.1174 18 10.5913 18 9C18 7.4087 17.3679 5.88258 16.2426 4.75736C15.1174 3.63214 13.5913 3 12 3C10.4087 3 8.88258 3.63214 7.75736 4.75736C6.63214 5.88258 6 7.4087 6 9Z"
          stroke="#3D3935"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 15L15.4 20.89L16.998 17.657L20.596 17.889L17.196 12"
          stroke="#3D3935"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.8021 12L3.4021 17.89L7.0001 17.657L8.5981 20.889L11.9981 15"
          stroke="#3D3935"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7735_1923">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GeoLocationWhiteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_7745_1526)">
        <path
          d="M17.657 16.6572L13.414 20.9002C13.039 21.2748 12.25 22.0002 12 22.0002C11.75 22.0002 10.962 21.2748 10.587 20.9002L6.343 16.6572C5.22422 15.5384 4.46234 14.1129 4.15369 12.5611C3.84504 11.0092 4.00349 9.40071 4.60901 7.93893C5.21452 6.47714 6.2399 5.22774 7.55548 4.3487C8.87107 3.46967 10.4178 3.00049 12 3.00049C13.5822 3.00049 15.1289 3.46967 16.4445 4.3487C17.7601 5.22774 18.7855 6.47714 19.391 7.93893C19.9965 9.40071 20.155 11.0092 19.8463 12.5611C19.5377 14.1129 18.7758 15.5384 17.657 16.6572Z"
          fill="white"
        />
        <path
          d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z"
          fill="#4C86A0"
        />
      </g>
      <defs>
        <clipPath id="clip0_7745_1526">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DeliveryIconMobile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5 17C5 17.5304 5.21071 18.0391 5.58579 18.4142C5.96086 18.7893 6.46957 19 7 19C7.53043 19 8.03914 18.7893 8.41421 18.4142C8.78929 18.0391 9 17.5304 9 17M5 17C5 16.4696 5.21071 15.9609 5.58579 15.5858C5.96086 15.2107 6.46957 15 7 15C7.53043 15 8.03914 15.2107 8.41421 15.5858C8.78929 15.9609 9 16.4696 9 17M5 17H3V11M9 17H15M15 17C15 17.5304 15.2107 18.0391 15.5858 18.4142C15.9609 18.7893 16.4696 19 17 19C17.5304 19 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17M15 17C15 16.4696 15.2107 15.9609 15.5858 15.5858C15.9609 15.2107 16.4696 15 17 15C17.5304 15 18.0391 15.2107 18.4142 15.5858C18.7893 15.9609 19 16.4696 19 17M19 17H21V13C21 12.4696 20.7893 11.9609 20.4142 11.5858C20.0391 11.2107 19.5304 11 19 11H18M3 11L5 6H14L18 11M3 11H18M12 11V6"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.625 11.25L21 16.875H19.125C19.125 16.5 18.375 15 16.875 15C15.675 15 15.125 16.25 15 16.875H9C9 16.125 8.25 15 6.75 15C5.55 15 5 16.25 4.875 16.875H3V10.875H9H13.125L20.625 11.25Z"
        fill="white"
      />
    </svg>
  );
};

export const CartBagItemAdded = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="34"
      viewBox="0 0 36 34"
      fill="none"
    >
      <path
        d="M33.6089 7.95654H3.00019C2.23179 7.95654 1.60889 8.57945 1.60889 9.34785V31.6087C1.60889 32.3771 2.23179 33 3.00019 33H33.6089C34.3773 33 35.0002 32.3771 35.0002 31.6087V9.34785C35.0002 8.57945 34.3773 7.95654 33.6089 7.95654Z"
        fill="#4C86A0"
        stroke="#4C86A0"
        stroke-width="1.55882"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3481 13.5217V7.95652C11.3481 6.11154 12.0811 4.34212 13.3857 3.03752C14.6903 1.73292 16.4597 1 18.3047 1C20.1496 1 21.9191 1.73292 23.2237 3.03752C24.5283 4.34212 25.2612 6.11154 25.2612 7.95652V13.5217"
        stroke="#4C86A0"
        stroke-width="1.55882"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-2 h-2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  );
};

export const MinusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-2 h-2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
    </svg>
  );
};

export const CZLogo = ({ className }) => {
  return (
    <svg
      width="170"
      height="56"
      viewBox="0 0 170 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_23010_154541)">
        <path
          d="M19.345 50.4655H11.5082L31.6007 25.2238V20.2076H0.994681V33.3438H5.60035C5.96206 26.8264 8.05993 24.8604 14.1305 24.8604H19.8876L0 50.1557V54.9157H33.156V40.7966H27.9716C27.6582 47.9336 25.7773 50.4655 19.345 50.4655Z"
          fill="#3D3935"
        />
        <path
          d="M92.7044 39.7123C97.4126 39.7123 100.758 39.2953 103.218 37.3294C105.364 35.6255 106.57 33.1413 106.57 30.1923C106.57 23.1089 102.127 20.2076 92.7044 20.2076H72.0211H71.0505H56.3835V24.6042C56.8537 24.5506 57.3239 24.5506 57.7459 24.5506C61.1459 24.5506 61.4594 25.7898 61.4594 29.9302V43.9957C61.4594 48.8034 59.5243 50.823 54.2374 50.823C49.686 50.823 48.2211 48.5472 48.2211 43.5787V29.674C48.2211 25.7957 48.3236 24.5566 51.7778 24.5566C52.3023 24.5566 52.875 24.5566 53.5562 24.6102V20.2136H33.6807V25.1404C34.3136 25.1404 34.7778 25.1345 35.1998 25.1345C38.0814 25.1345 38.5456 26.5821 38.5456 30.4068V45.086C38.5456 51.9668 43.6214 56 52.459 56C62.1406 56 67.1562 52.0204 67.1562 44.6749V29.9838C67.1562 27.4519 67.2165 26.04 67.5722 25.5276C68.1147 24.7591 69.3867 24.7234 71.5087 24.7234L72.9796 24.7591C75.8551 24.7591 76.1746 25.2238 76.1746 27.7617V47.3617C76.1746 49.8936 75.8612 50.3583 72.9796 50.3583H71.0445V54.9098H91.758V50.3583H89.2441C86.3626 50.3583 86.0009 49.8936 86.0009 47.3617V39.7123H92.7044ZM86.0069 24.7591H89.2502C94.0608 24.7591 96.2612 26.1055 96.2612 29.7753C96.2612 33.9098 94.2175 35.2085 89.2502 35.2085H86.0069V24.7591Z"
          fill="#3D3935"
        />
        <path
          d="M158.389 33.3498C151.167 31.5923 147.297 31.6936 147.297 28.3336C147.297 25.9566 149.341 24.3004 153 24.3004C158.805 24.3004 162.73 27.3506 163.357 32.5217H168.173V19.8502H163.833L162.784 22.2808C160.119 20.2613 156.611 19.3855 152.216 19.3855C143.065 19.3855 137.935 23.5259 137.935 30.4545C137.935 33.5583 139.189 36.0902 141.492 37.9013C144.054 39.9208 147.767 40.7966 152.584 41.9345C157.346 43.0723 160.427 43.3285 160.427 46.7481C160.427 49.2323 158.124 50.8885 154.097 50.8885C148.394 50.8885 144.054 47.3736 143.216 41.7319H138.508V50.3702H136.958C134.758 50.3702 134.131 49.5421 133.347 47.1651L124.715 20.2196H114.256L105.249 47.0042C104.411 49.4349 103.995 50.3642 101.168 50.3642H99.9141V54.9157H115.292V50.3642H113.568C111.531 50.3642 110.747 50.2094 110.747 49.1251C110.747 48.8689 110.801 48.3506 111.006 47.7311L112.314 43.7515H122.412L123.768 48.0468C123.871 48.4579 124.028 48.8213 124.028 49.1311C124.028 50.1617 123.135 50.3702 120.833 50.3702H119.368V54.9217H138.496V54.9574H142.734L144.144 52.8485C146.706 54.6596 150.998 55.9523 155.441 55.9523C164.544 55.9523 169.988 51.4545 169.988 44.6749C170 38.6757 166.232 35.2621 158.389 33.3498ZM113.828 39.2477L117.439 28.28L120.941 39.2477H113.828Z"
          fill="#3D3935"
        />
        <path
          d="M115.371 8.06638C115.371 12.5226 111.796 16.1328 107.389 16.1328H62.6224C58.2096 16.1328 54.6348 12.5226 54.6348 8.06638C54.6287 3.61021 58.2096 0 62.6224 0H107.389C111.796 0 115.371 3.61021 115.371 8.06638ZM73.0032 6.97617C72.6355 3.91404 69.8805 3.36596 67.7103 3.36596C64.4369 3.36596 62.3933 4.76 62.3933 8.06042C62.3933 11.3608 64.4369 12.7549 67.7103 12.7549C70.5738 12.7549 72.7259 11.6826 73.0273 9.09106H70.3688C70.1277 10.497 68.9823 11.057 67.7947 11.057C65.4798 11.057 64.9855 9.26383 64.9855 8.05447C64.9855 6.84511 65.4798 5.05191 67.7947 5.05191C69.0667 5.05191 70.1397 5.68936 70.3688 6.97021H73.0032V6.97617ZM82.6607 12.5106H85.2951L81.3344 3.61617H78.3866L74.4319 12.5106H77.0663L77.7958 10.6817H81.9312L82.6607 12.5106ZM81.2741 9.06128H78.4468L79.8575 5.53447L81.2741 9.06128ZM87.4713 12.5106H89.9128V8.94808H95.1575V7.25021H89.9128V5.30808H95.5132V3.61617H87.4713V12.5106ZM97.8401 12.5106H106.292V10.8187H100.282V8.77532H105.942V7.14894H100.282V5.30808H106.244V3.61617H97.8401V12.5106ZM100.794 2.85957H102.807L104.622 1.09021H101.909L100.794 2.85957Z"
          fill="#3D3935"
        />
      </g>
      <defs>
        <clipPath id="clip0_23010_154541">
          <rect width="170" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};