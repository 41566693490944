import React from "react";
import { Col } from "react-bootstrap";
import { FULL_SIZE, HALF_SIZE, REACT_APP_CLOUD_FRONT, ROUTE_ORDER } from "../../../constants";
import styleClasses from "../../Header/Header.module.scss";
import useAddToOrder from "../../../hooks/useAddToOrder";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../../redux/store/store";
import { setShowCart } from "../../../redux/slices/cartSlice";
import { useStoreLocation } from "../../../context/StoreLocation";

interface ISuggestedItemCard {
  upSellItem?:any;
  btnText?:string;
  fetchUpsell?:() => void;
  onViewCartClick?:()=>void;
  onContinueClick?: ()=>void;
  closeModal?: ()=>void;
  setshowModal?:any;
  syncCart?: (locationId: string, persistOffer?: boolean) => any;
}

const fountainDrink = {
  id: 108,
  name: "FOUNTAIN DRINK"
}

const UpsellItemCard: React.FC<ISuggestedItemCard> = ({
  upSellItem,
  btnText,
  fetchUpsell,
  syncCart,
  onViewCartClick,
  closeModal,
  setshowModal
}) => {
  const { addToOrder, loading }= useAddToOrder();
  const dispatch = useAppDispatch();
  const history = useHistory()
  const { locationInfo } = useStoreLocation();
  const { name, in_cart } = upSellItem;
  const orderPayload = async () => {
    if(upSellItem.name.toLowerCase() === fountainDrink.name.toLowerCase() || upSellItem.id === fountainDrink.id){
      dispatch(setShowCart(false))
      // closeModal && closeModal() 
      setshowModal && setshowModal(false)
        history.push(
          {
            pathname: `${ROUTE_ORDER}/${upSellItem.id}`,
            state: {OpenOtherModalAsItComingFromUpsell:true, is_recommended:true },
            search: `category_id=${upSellItem?.categories?.length > 0 && upSellItem?.categories[0]?.id}`
          });
          return
    }
    const item_size = upSellItem.is_half_item ? HALF_SIZE.upper_case : FULL_SIZE.upper_case;
    const payload = {
      item_id: upSellItem?.id,
      display_price: displayPrice(),
      item_size: item_size,
      name: upSellItem?.name,
      modifiers: [],
      item_calories: upSellItem?.calories,
      category_name: upSellItem?.category_name,
      make_time_minutes: upSellItem?.make_time_minutes,
      isUpsellItem: true,
      is_recommended_item:true, 
      redeem_points: item_size === HALF_SIZE.id ? upSellItem.half_loyalty_points
        : upSellItem.full_loyalty_points
    }
    await addToOrder(payload);
    !onViewCartClick && await syncCart(locationInfo?.selectedStore?.id);
    !onViewCartClick && fetchUpsell();
    onViewCartClick && onViewCartClick() 
  }

  const displayPrice = () => {
    if(!upSellItem) return 0
    if(upSellItem?.is_half_item){
      return upSellItem.half_brink_item_price.toFixed(2)
    }else{
      return upSellItem.price.toFixed(2)
    }
  }

  return (
    <>
      <Col sm="4" xs="4" className={styleClasses.suggestion_column}>
        <div
          className={`${styleClasses.other_products_item} px-1 text-center ${
            btnText === 'Added' && styleClasses.active
          }`}
        >
          <div className={styleClasses.cart_img_wrap + ' mb-3'}>
            <img
              // src={`${REACT_APP_CLOUD_FRONT}${image}`}
              src={
                upSellItem.id
                  ? `${REACT_APP_CLOUD_FRONT}/Items/${upSellItem.id}/item-${upSellItem.id}.webp`
                  : `${REACT_APP_CLOUD_FRONT}/images/Menu/${upSellItem.name.toLocaleLowerCase()}.webp`
              }
              className="img-fluid"
              alt={name}
            />
          </div>
          <h5
            className={`${styleClasses.card_inner_lis_des} f-s14 f-sm-s11 lh-18 font-rale f-w5`}
          >
            {name}
          </h5>
          <button
            type="button"
            className={
              styleClasses.pro_item_btn +
              `btn-large item-select-btn lh-lg mx-md-2 btn h-auto f-s12 mb-2 font-rale text-capitalize mw-auto w-100 py-1 px-2 ${
                !in_cart && 'outline'
              }`
            }
            onClick={orderPayload}
            disabled={btnText == 'Added' || loading}
          >
            {btnText}
          </button>
            <h6 className="f-s12 ls-normal font-Visby-cf d-flex justify-content-end mb-0 lh-normal">
              {/* <span>{`${parseInt(calories)} cal`}</span>{' '}  */}
              <span className={`${btnText == 'Added' && 'clr-dark-red'}`}>{`\$${displayPrice()}`}</span>
            </h6>
        </div>
      </Col>
    
    </>
  );
};

export default UpsellItemCard;