import produce from "immer";
import { v4 as uuidv4 } from "uuid";
import * as CONSTANT from "../constants";
import { Codes } from "../models/item.model";
import { ETry2ComboItemsName } from "../models/order.model";
import { itemBuckets } from "../priceCalculation/buckets";
import { BY_DEFAULT_ADDED_V2 } from "../priceCalculation/constants";
import {ADD, COMPLIMENTARY_MODIFIER, CURRENT_USER, GUEST_USER, MAX_COUNT_FOR_PROFILE_INFO, NO} from "../constants";
import notReleasing from '../not-releasing.json'
import {Toast_Func} from "./toast.helper";
import _clonedeep from 'lodash.clonedeep'
import { dmasOperationType } from "../models/cart.model";
import BigJs from 'big.js'

export const addDaysToDate = (date: Date, days: number) =>
  date.setDate(date.getDate() + days);

export const getUTCFullDate = (date: Date) => {
  const utcYear = date.getUTCFullYear();
  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0');
  const utcDate = String(date.getUTCDate()).padStart(2, '0');
  return `${utcYear}-${utcMonth}-${utcDate}`;
}

export const getUserId = (): string => {
  return JSON.parse(localStorage.getItem(CONSTANT.CURRENT_USER))?.id
    ? JSON.parse(localStorage.getItem(CONSTANT.CURRENT_USER)).id
    : "";
};

export const getUser = (): any => {
  return localStorage.getItem(CONSTANT.CURRENT_USER) ? JSON.parse(localStorage.getItem(CONSTANT.CURRENT_USER)) : {};
};

export const validateCount = (profileData) => {
  if(profileData.length >=  MAX_COUNT_FOR_PROFILE_INFO)
  {
    Toast_Func({status:false, message: "You have reached the max limit."})
    return false
  }
  else return true
}

export const isCartItemsUnavailable = (cartItems: any): boolean => {
  let itemsNotAvailable = false;
  cartItems?.forEach((item, index) => {
    if (item?.is_try_2_combo || item?.is_kids_combo) {
      item?.modifiers?.findIndex((subItem, index) => {
        if (!subItem?.available && subItem?.is_item) {
          itemsNotAvailable = true;
          return;
        }
        else if(item?.unavailable_modifiers?.includes(subItem?.modifier_name) && subItem?.modifier_type === COMPLIMENTARY_MODIFIER
                  && subItem.quantity > 0 )
        {
          itemsNotAvailable = true;
          return;
        }
      })
    } else {
      if (!item?.available) {
        itemsNotAvailable = true;
        return;
      }
    }
  });
  return itemsNotAvailable;
};

export const isCartModifiersUnavailable = (cartItems: any): boolean => {
  let itemsNotAvailable = false;
  if(!itemsNotAvailable) {
    cartItems?.forEach((item, index) => {
      if (item?.is_try_2_combo || item?.is_kids_combo) {
        item?.modifiers?.filter((subItem, index) => {
          subItem?.modifiers?.filter((modifier: any) => {
            if(subItem?.unavailable_modifiers?.includes(modifier?.modifier_name) && modifier?.code === ADD && modifier?.quantity > 0){
              itemsNotAvailable = true
            }
          })
        });
      } else {
        item?.modifiers?.filter((modifier: any) => {
          if(item?.unavailable_modifiers?.includes(modifier?.modifier_name) && modifier?.code === ADD && modifier?.quantity > 0){
            itemsNotAvailable = true
          }
        });
      }
    })
  }
  return itemsNotAvailable;
}

export const isItemModifiersUnavailable = (item: any): boolean => {
  let itemsNotAvailable = false;
  if(!itemsNotAvailable) {
      if (item?.is_try_2_combo || item?.is_kids_combo) {
        item?.modifiers?.filter((subItem, index) => {
          if(item?.unavailable_modifiers?.includes(subItem?.modifier_name) && subItem?.modifier_type === COMPLIMENTARY_MODIFIER
              && subItem.quantity > 0 )
          { itemsNotAvailable = true; }
          else {
            subItem?.modifiers?.filter((modifier: any) => {
              if(subItem?.unavailable_modifiers?.includes(modifier?.modifier_name) && modifier?.code === ADD && modifier?.quantity > 0){
                itemsNotAvailable = true }
            })
          }
        });
      } else {
        item?.modifiers?.filter((modifier: any) => {
          if(item?.unavailable_modifiers?.includes(modifier?.modifier_name) && modifier?.code === ADD && modifier?.quantity > 0){
            itemsNotAvailable = true
          }
        });
      }
  }
  return itemsNotAvailable;
}

 export const notAvailableAddedModifiers = (item: any) => {
  const unavailableModifiers = []
  if (item?.is_try_2_combo || item?.is_kids_combo) {
    item?.modifiers?.filter((subItem, index) => {
      if(item?.unavailable_modifiers?.includes(subItem?.modifier_name) && subItem?.modifier_type === COMPLIMENTARY_MODIFIER
          && subItem.quantity > 0 )
      { unavailableModifiers.push(subItem?.modifier_name) }
      else {
        subItem?.modifiers?.filter((modifier: any) => {
          if(subItem?.unavailable_modifiers?.includes(modifier?.modifier_name) && modifier?.code === ADD && modifier?.quantity > 0){
            unavailableModifiers.push(modifier?.modifier_name)
          }
        })
      }
    });
  } else {
    item?.modifiers?.filter((modifier: any) => {
      if(item?.unavailable_modifiers?.includes(modifier?.modifier_name) && modifier?.code === ADD && modifier?.quantity > 0){
        unavailableModifiers.push(modifier?.modifier_name)
      }
    });
  }
  return unavailableModifiers
}
/*
 * Generates random number from 1000 to 9999
 */
export const randomNumber = () => {
  return Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);
};

/*
 * Generates unique id for each item added to Cart
 */
export const generateUniqueID = (): string => {
  const userID = getUserId();
  return `${Date.now()}${userID ? userID : randomNumber()}`;
};

export const substitutedAddedModifiers = (item: any): any[] => {
  return item?.modifiers?.length > 0
    ? item.modifiers.filter(
        (modifier: any) =>
          modifier.hasOwnProperty("code") &&
          (modifier.code === CONSTANT.ADD || modifier.code === CONSTANT.SUB || modifier.code === CONSTANT.NO)
      )
    : [];
};

export const setVisitorId = (): void => {
  const visitor_id = localStorage.getItem(CONSTANT.CURRENT_USER)
    ? JSON.parse(localStorage.getItem(CONSTANT.CURRENT_USER)).visitor_id
    : "";
  !visitor_id &&
    localStorage.setItem(
      CONSTANT.CURRENT_USER,
      JSON.stringify({ visitor_id: uuidv4() })
    );
};

export const getVisitorId = (): string => {
  const visitor_id = localStorage.getItem(CONSTANT.CURRENT_USER)
    ? JSON.parse(localStorage.getItem(CONSTANT.CURRENT_USER)).visitor_id
    : "";
  return visitor_id;
};

export const getLocationId = (): number | string => {
  return localStorage.getItem(CONSTANT.SELECTED_STORE)
    ? JSON.parse(localStorage.getItem(CONSTANT.SELECTED_STORE)).id
    : "";
};

export const getSelectedStore = () => {
  return localStorage.getItem(CONSTANT.SELECTED_STORE)
    ? JSON.parse(localStorage.getItem(CONSTANT.SELECTED_STORE))
    : "";
};

export const attachUniqueIdWithModifier = (item: any) => {
  return produce(item, (draftItem) => {
    draftItem?.modifiers?.forEach((modifier: any) => {
      if (modifier?.modifiers?.length) {
        modifier.modifiers.forEach((modifier: any) => {
          modifier.id = generateUniqueID();
        });
      }

      modifier.id = generateUniqueID();
    });
  });
};

export const isMobileDevice = () => {
  let mobileDeviceRegex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileDeviceRegex.test(navigator.userAgent);
};

export const isAndroidDevice = () => {
  let userAgent = navigator.userAgent || navigator.vendor;
  return /android/i.test(userAgent);
};

export const isIOSDevice = () => {
  let userAgent = navigator.userAgent || navigator.vendor;
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};

// export const getCurrentDate = () => {
//   return format(new Date(), "yyyy-MM-dd H:mm:ss");
// };

export const multiDimensionalArrayLength = (array) => {
  let length = 0;
  for (let i = 0; i < array.length; i++) {
    length += array[i].length;
  }
  return length;
};

function getClass(css, class_for:string, style_class_is:string) {
  switch (class_for) {
    case 'card':
      if (style_class_is === "add_on_item_wrap") return css.add_on_item_wrap;
      if (style_class_is === "selected") return css.selected;
      if (style_class_is === "exclude") return css.exclude;
      break;
    default:
      break;
  }
}

export function give_me_my_classes(style_class_is,Styles) {
  let classes= '';
  style_class_is.map((style_class_is:string) => {
    classes = classes + getClass(Styles, 'card', style_class_is)+ ' ';
  });
  return classes;
}

export function give_me_my_class(style_class_is, Styles){
  return getClass(Styles, 'card', style_class_is)
}

export const roundOffLimit = (limit:number) => {
  if(limit > 9){
    return '9+'
  }else{
    return limit
  }
}

export const addExtendableLimit = (payload,type:string, extendableLimitValue:number) => {
  if(type === CONSTANT.BY_DEFAULT){
    return payload;
  }else{
    payload.extendableLimitValue = extendableLimitValue;
    return payload;
  }
}

export const creatingModifiersForItems = (payload, numberOfitems:number) => {
  for (let x = 0; x < payload.length; x++) {
    if(x < numberOfitems){
      payload[x].modifiers = removeCore(_clonedeep(payload[x]?.modifiers));
      payload[x].modifiers = addZeroCore(payload[x].modifiers, x);
    }else{
      payload[x].modifiers = addAndRemoveByDefaultAddedComplementaryModifiers(payload[x]?.modifiers, x)
    }
  }
  
  return removeComplementaryAsAnItemAndAddAsModifiers(_clonedeep(payload), numberOfitems);
}

const removeComplementaryAsAnItemAndAddAsModifiers = (payload, numberOfitems:number) => {
  let complementaryModifiers = [];
  for (let x = 0; x < payload.length; x++) {
    if(x === numberOfitems){
      complementaryModifiers = _clonedeep(payload[x].modifiers);
      payload.splice(x,1);
    }
  }
  for (let y = 0; y < complementaryModifiers.length; y++) {
    payload.push(complementaryModifiers[y])
  }
  return payload
}

const addAndRemoveByDefaultAddedComplementaryModifiers = (modifiers, itemNo) => {
  const bucket = itemBuckets.getSingleBucket({name:CONSTANT.COMPLIMENTARY_MODIFIER,fromItem:itemNo, isFromItemComingAsAnIndex:true});
  const removeModifier = [];
  const zeroComplementary = _clonedeep(bucket[BY_DEFAULT_ADDED_V2]?.modifiers);
  for (let i = 0; i < modifiers.length; i++) {
    if ((modifiers[i]?.type === CONSTANT.BY_DEFAULT || modifiers[i]?.quantity >= 1) && modifiers[i].is_selected) {
      const index = zeroComplementary?.findIndex(mod => mod.modifier_id === modifiers[i].modifier_id);
      index !== -1 && zeroComplementary.splice(index,1)
      if (modifiers[i].quantity > 1) {
        modifiers[i].quantity = modifiers[i].quantity - 1;
      } else if(index !== -1) {
        removeModifier.push(modifiers[i].modifier_id);
      }
    }else{
      modifiers.push()
    }
  }
  const finalModifiers = removeObjectsFromArray(modifiers, removeModifier);
  for (let j = 0; j < zeroComplementary.length; j++) {
    zeroComplementary[j].code = Codes.NO;
    zeroComplementary[j].quantity = 0;
    finalModifiers.push(zeroComplementary[j])
  }
  return finalModifiers
}

const removeObjectsFromArray = (modifiers,removeModifier) => {
  for (let i = 0; i < removeModifier.length; i++) {
    const index = modifiers.findIndex(
      (mod) => mod.modifier_id === removeModifier[i]
    );
    index !== -1 && modifiers.splice(index, 1);
  }
  return modifiers;
};

const removeCore = (modifiers) => {
  const removeModifier = [];
  for (let i = 0; i < modifiers.length; i++) {
    if (modifiers[i].core) {
      if (modifiers[i].quantity > 1) {
        modifiers[i].quantity = modifiers[i].quantity - 1;
      } else {
        removeModifier.push(modifiers[i].modifier_id);
      }
    } else if (modifiers[i].is_selected ) {
      if (modifiers[i].quantity > 1) {
        modifiers[i].quantity = modifiers[i].quantity - 1;
      } else {
        removeModifier.push(modifiers[i].modifier_id);
      }
    }
  }
  return removeObjectsFromArray(modifiers,removeModifier);
};

const addZeroCore = (modifiers, itemNo) => {
  const bucket = itemBuckets.getSingleBucket({name:CONSTANT.CORE_MODIFIERS,fromItem:itemNo, isFromItemComingAsAnIndex:true});
  const zeroCore = findZeroCore(bucket);
  const updatedModifiers = modifiers.concat(zeroCore);
  return updatedModifiers;
};

const findZeroCore = (bucket) => {
  const memoryChip = bucket?.memoryChip;
  const removedCore = [];
  if (memoryChip) {
    const keys = Object.keys(memoryChip);
    for (let i = 0; i < keys.length; i++) {
      const currentCoreGroup = memoryChip[keys[i]];
      if (currentCoreGroup.changes) {
        if (currentCoreGroup['changes'].zeroCore) {
          removedCore.push.apply(removedCore, currentCoreGroup['changes'].zeroCore);
        }
      }
    }
  }
  return removedCore;
};

export function capitalizeFirstLetter(string) {
  const finalString = string?.toLowerCase();
  return finalString?.charAt(0)?.toUpperCase() + finalString?.slice(1);
}

export function capitalizeSentence(sentence){
  const words = sentence?.toLowerCase()?.split(' ');
  const capitalizedSentence = words?.map(word =>
    word?.charAt(0)?.toUpperCase() + word?.slice(1)
  )?.join(' ');

  return capitalizedSentence;
}

export function addCode(type:string,payload){
  if(type === CONSTANT.INCREASE){
    payload.code = Codes.ADD
  }
  if(type === CONSTANT.DECREASE){
    payload.code = Codes.NO
  }
  if(type === CONSTANT.BY_DEFAULT){
    payload.code = Codes.ADD
  }
  return payload
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export const selectedIngredient = (modifier,selectedIngredients) => {
  return selectedIngredients.find(
    (selected) => selected.modifier_id === modifier.id && !selected.core
  );
};

export const showMakeItTryToCombo = (categoryName:string) => {
  return (<any>Object).values(ETry2ComboItemsName).includes(categoryName)
}

export const itemSize = (size:string, half_brink_item_id:number) => {
  if(!half_brink_item_id){
    return CONSTANT.FULL_SIZE.id
  }
  if(size){
    return size
  }
}

export const isGuestUser = (authInfo: any) => {
  return authInfo.type === GUEST_USER
}

export const getUserData = (authInfo: any, profileData: any) => {
  return isGuestUser(authInfo) ? getUser() : profileData
}

export const shouldRelease = (
  type: "Features" | "Routes" | "APIs",
  key: "redeem" | "upsellItems"
) => {
  return !notReleasing[type][key];
};
export const nullValueVerify = (value) => {
  if (value === undefined || (Array.isArray(value) && value.length === 0))
    return true;
  return false;
};
export const convertMonth = ( month: number ) => {
  if( month.toString().length < 2){
    return '0' + month
  } else return month;
}

export const scrollToElementByRef = (ref: any, yOffset: number = -210 ) => {
  const ScrollYCordinates = ref?.current.getBoundingClientRect().top + window.scrollY+ yOffset;
  window.scrollTo({ top: ScrollYCordinates, behavior: "smooth" });     
}

export const getResetFilters = (filters) => filters.map((filter) => { return {...filter, selected: false} })

// Filter items on the basis of selected filter
export const getFilteredItems = (items, selectedFilters) => {
  if (selectedFilters.length > 0) {
    return items?.filter((item) =>
      item.tags.find((tag) =>
        selectedFilters.find((filter) =>
          tag.name.toLowerCase().includes(filter.name.toLowerCase())
        )
      )
    );
  }
  return items;
};


export const dmasNumber = (
  num1: number = 0,
  num2: number = 0,
  operation: dmasOperationType,
): number => {
  num1 = num1 != null && num1 >= 0 ? num1 : 0;
  num2 = num2 != null && num2 >= 0 ? num2 : 0;
  const result = {
    [CONSTANT.UPPERCASE_ADD]: () => BigJs(num1).plus(num2),
    [CONSTANT.UPPERCASE_SUB]: () => BigJs(num1).minus(num2),
    [CONSTANT.UPPERCASE_MULTI]: () => BigJs(num1).times(num2),
    [CONSTANT.UPPERCASE_DVISION]: () => BigJs(num1).div(num2),
  };
  return result[operation]().toNumber();
};

export const roundNumber = (num: number, figure: number): number => {
  if (typeof num === 'number' && num && figure) {
    return new BigJs(num).round(figure).toNumber();
  } else if (typeof num === 'string' && num && figure) {
    try {
      num = parseFloat(num);
    } catch (error) {
      return 0;
    }
    return new BigJs(num).round(figure).toNumber();
  }
  return 0;
};

/**
 * Rounds towards nearest neighbour. If equidistant, rounds away from zero.
 * @param {number} num
 * @param {number} roundUptoDecimalPlaces
 * @returns {number}
 */
export const roundNumberHalfUpStrategy = (num: number, roundUptoDecimalPlaces: number) => {
  const figurePlusOneDecimalPlaceNumber = new BigJs(num).round(roundUptoDecimalPlaces + 1).toNumber();
  return new BigJs(figurePlusOneDecimalPlaceNumber).round(roundUptoDecimalPlaces).toNumber();
};

export const persistZero = (number: number) => {
  let num = number;
  if (isNaN(num)) {
    return 0.0;
  }
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  const roundedNumber = Number(num?.toFixed(2));
  return roundedNumber?.toFixed(2);
};

export const updateUserPhone = (phone: string) => {
  let user = getUser()
  user.phone = phone
  localStorage.setItem(
      CURRENT_USER,
      JSON.stringify({ ...user, isInfo: true })
  );
}