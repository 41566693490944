import {
    CURBSIDE,
    CURBSIDE_DESTINATION_ID,
    DELIVERY,
    DELIVERY_DESTINATION_ID,
    DELIVERY_ORDER_TYPE,
    GOOD_LIFE_LANE,
    GOOD_LIFE_LANE_DESTINATION_ID,
    IN_STORE,
    IN_STORE_DESTINATION_ID, KIDS_COMBO_ITEM,
    PICK_UP_METHOD_CURBSIDE,
    PICK_UP_METHOD_GOOD_LIFE_LANE,
    PICK_UP_METHOD_IN_STORE, PICK_UP_ORDER_TYPE, TRY_TO_COMBO_ITEM
} from "../../constants";
import * as CONSTANT from "../../constants";

export const getDestination = (orderType: string) => {
    const destination = {
        [DELIVERY_ORDER_TYPE]: { destination_id: DELIVERY_DESTINATION_ID, order_type: DELIVERY },
        [PICK_UP_METHOD_IN_STORE]: { destination_id: IN_STORE_DESTINATION_ID, order_type: IN_STORE },
        [PICK_UP_METHOD_CURBSIDE]: { destination_id: CURBSIDE_DESTINATION_ID, order_type: CURBSIDE},
        [PICK_UP_METHOD_GOOD_LIFE_LANE]: { destination_id: GOOD_LIFE_LANE_DESTINATION_ID, order_type: GOOD_LIFE_LANE}
    }
    return destination[orderType]
}

export const getOrderType = (destinationId: number) => {
    const destination = {
        [DELIVERY_DESTINATION_ID]: DELIVERY_ORDER_TYPE,
        [IN_STORE_DESTINATION_ID]: PICK_UP_ORDER_TYPE,
        [CURBSIDE_DESTINATION_ID]: PICK_UP_ORDER_TYPE,
        [GOOD_LIFE_LANE_DESTINATION_ID]: PICK_UP_ORDER_TYPE,
    };
    return destination[destinationId]
}

const getPayments = (paymentCards, tipAmount, orderType) => {
    return paymentCards?.map((payment) => {
       return  {
            id: payment.id,
           amount: payment?.amount?.toFixed(2)
        }
    })
}

const getGiftCards = (giftCards) => {
    return giftCards?.map((payment) => {
        return  {
            id: payment.id,
            amount: payment?.amount?.toFixed(2)
        }
    })
}

export const placeOrderPayload = ({storeLocation, cart, checkout, customer,deliveryTipType, tipAmount ,isExternalPayment=false, externalPaymentId=[], isItMobile=false}) => {
    const destination: any = getDestination(cart.orderDetails?.PickupSlotDetails.pickupMethod)
    const orderDetails: any = cart.orderDetails
    let payload : any = {
        customer_id: customer.id || customer.visitor_id,
        customer: {
            id: customer.id || customer.visitor_id,
            first_name: customer.first_name,
            last_name: customer.last_name,
            email: customer.email,
            phone: customer.phone,
            vehicle: {
                vehicle_information_id: orderDetails.vehicleDetails?.id || null,
                make: orderDetails.vehicleDetails?.make || null,
                model: orderDetails.vehicleDetails?.model || null,
                color: orderDetails.vehicleDetails?.color || null,
                is_saved: 1
            },
            address: {
                address_id: orderDetails.delivery_address?.id || null,
                address_name: orderDetails.delivery_address?.address_name || null,
                full_address: null,
                street_name: orderDetails.delivery_address?.street_name || null,
                zipcode: orderDetails.delivery_address?.zipcode || null,
                city: orderDetails.delivery_address?.city || null,
                state: orderDetails.delivery_address?.state || null,
                apartment_no: orderDetails.delivery_address?.apartment_no || null,
                is_saved: 1
            }
        },
        location_id: storeLocation.id,
        destination_id: destination.destination_id,
        payment_methods: isExternalPayment? [] :getPayments(checkout.paymentMethods, tipAmount, cart.orderDetails?.PickupSlotDetails.pickupMethod),
        gift_cards: isExternalPayment ? [] :getGiftCards(checkout.giftCard),
        digital_payments: isExternalPayment ? externalPaymentId : [],
        status: "open",
        source: isItMobile ? "mobile_web_source" : "web_source",
        slot: orderDetails.PickupSlotDetails?.isAsapOrder ? "ASAP" : orderDetails.PickupSlotDetails?.time,
        business_date: orderDetails.PickupSlotDetails?.date,
        mins: orderDetails.PickupSlotDetails?.isAsapOrder ? orderDetails.PickupSlotDetails?.asapMinutes.toString() : "0",
        delivery_tip: deliveryTipType?.tip || null,
        delivery_tip_format: deliveryTipType?.type || null
    }
    return payload
}

export const timeSlotPayload = (cart: any) => {
    let items = []
    cart.items?.map((item) => {
        if(item.item_type === TRY_TO_COMBO_ITEM || item.item_type === KIDS_COMBO_ITEM){
            item.modifiers.map((modifier) => {
                if(modifier.modifier_type !== CONSTANT.COMPLIMENTARY_MODIFIER)
                {
                    items.push({
                        category_id: modifier.category_id,
                        make_time_minutes: modifier.make_time_minutes,
                        quantity: item.quantity
                    })
                }
            })
        }
        else{
            items.push(
                {
                    category_id: item.category_id,
                    make_time_minutes: item.make_time_minutes,
                    quantity: item.quantity
                }
            )
        }
    })
    let finalPayload = {
        items: items,
        delivery: cart.orderType === DELIVERY_ORDER_TYPE ? 1 : 0
    }
    return cart.orderType === DELIVERY_ORDER_TYPE ?
        {
            ...finalPayload,
            dropoff_address: {
                ...cart.orderDetails?.delivery_address,
                zip_code: cart.orderDetails?.delivery_address?.zipcode
            }
        }
        :
        finalPayload
}

export const getPlaceOrderModalClass = (orderType: string) => {
    return orderType === DELIVERY_ORDER_TYPE ?
        "theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal full-modal-ipad"
        :
        "chose-item-modal theme-modal-style app_container_wrap no-padding border-radius-15 border-radius-sm-8 full-modal-ipad"
}

export const getPlaceOrderModalSize = (orderType: string) => {
    return orderType === DELIVERY_ORDER_TYPE ?
        "sm"
        :
        "xl"
}
