import React from "react";
import { Modal } from "react-bootstrap";
import useUnload from "../../hooks/useUnload";
import Button from "../Buttons/Button";
import { Helmet } from "react-helmet";
import {getStructuredItemList} from "../../helpers/scripts/seoScript";
import {toFixedNumber} from "../../priceCalculation/helper";

interface CancelOrderModalProps {
  showModal: boolean;
  loading: boolean;
  handleClose: () => void;
  cancelOrder: () => void;
  order: any;
}
const CancelOrderModal: React.FC<CancelOrderModalProps> = ({
  showModal,
  handleClose,
  cancelOrder,
  loading,
  order
}) => {

  useUnload(e => {
    if (loading) {    
      e.preventDefault();
      e.returnValue = true;
      return true;     
    }  
    return null
  });
  let orderDiscountType =  order?.discounts?.[0]?.type?.toString()
  const closePopup = () =>{
    if( loading ) return;
    handleClose()
  }
  return (
    <>
      {
          showModal &&
          <Helmet>
            <script>
              {
                `
                document.getElementById("cancel_order_button").addEventListener("click", function () {
                  gtag("event", "refund", {
                    currency: "USD",
                    transaction_id:  ${order.id},
                    value: ${toFixedNumber(order?.total_price + order?.tip)},
                    coupon: ${JSON.stringify(orderDiscountType)},
                    shipping: ${order?.delivery_fee},
                    tax: ${order?.tax},
                    items: ${JSON.stringify(getStructuredItemList(order?.items, order?.location_id))}
                  });
                });             
                `
              }
            </script>
          </Helmet>
      }
      <Modal
        show={showModal}
        onHide={closePopup}
        centered
        backdrop="static"
        className="theme-modal-style app_container_wrap no-padding border-radius-8 no-border"
      >
        <Modal.Header className="pt-0" id="close_button2">
          <button
            type="button"
            className="btn modal-close-button pe-0 blue-icon"
            onClick={closePopup}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body className="text-center py-5">
          <h6 className="font-Cls ls-normal mb-sm-4 mb-3 f-s22">Cancel Order</h6>
          <p className="f-s14 mb-4">
            Are you sure you want to cancel<br/>this order?
          </p>
          <div className="text-center">
            <Button
              type="button"
              className="btn btn-large mb-sm-4 mb-3 d-block mx-auto"
              onClick={cancelOrder}
              disabled={loading}
              id={"cancel_order_button"}
              loading={loading}
            >
              Yes, Cancel
            </Button>
            <button
              type="button"
              onClick={handleClose}
              disabled={loading}
              className="btn btn-custom f-s16 font-Vcf clr-dark-blue p-0 ls-05"
            >
              No, Keep the Order
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CancelOrderModal;
