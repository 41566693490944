import { Formik } from 'formik'
import React, {useEffect, useState} from 'react'
import { Row } from 'react-bootstrap'
import { DeliveryAddressFromFields } from '../../../models/forms.model'
import { FormField } from '../../FormFields/FormField'
import { useAppSelector } from "../../../redux/store/store";
import useAddAddress from "../../../react-query-hooks/useAddAddress";
import {useAuthState} from "../../../context/UserAuthentication";
import {Toast_Func} from "../../../helpers/toast.helper";
import {useQueryClient} from "react-query";
import {isGuestUser} from "../../../helpers/helperMethods";
import { handleIsLiveLocationRedirection } from '../../../helpers/checkout/LocationHelper'
import styleClasses from "../ItemGrid.module.scss";
import GooglePlacesSearchInput from '../../GooglePlacesSearch/GooglePlacesSearch'
import InputCheckbox from "../../FormFields/Checkbox/InputCheckbox";

interface IVisitorDeliveryAddress {
    setNewAddedAddressForDelivery: (delivery_address) => void;
    checkNearestStoreLocation: (delivery_address) => any;
    saveSelectedOrderType?: string;
    handleFormSubmission?: any
    handleLocation?: ( values, address ) => void;
    isItMobile?: boolean;
    isFindingNearest?: boolean;
    isNearestStoreAvailable?: boolean;
    closeNewAddressForm: () => void
}

const VisitorDeliveryAddress = (props: IVisitorDeliveryAddress) => {
    const { checkNearestStoreLocation, setNewAddedAddressForDelivery,
        saveSelectedOrderType, closeNewAddressForm} = props
    const deliveryAddress = useAppSelector((state) => state.cart?.orderDetails?.delivery_address || "");
    const initialFormState: DeliveryAddressFromFields = {
        address_name: "",
        optional_address_details: "",
    };
    const [street_name, setStreet_name] = useState(deliveryAddress?.street_name || '')
    const [isTypedAddress, setIsTypedAddress] = useState<boolean>(false)
    const [city, setCity] = useState(deliveryAddress?.city || '')
    const [state, setState] = useState(deliveryAddress?.state || '')
    const [zipcode, setZipcode] = useState(deliveryAddress?.zipcode || '')
    const [country, setCountry] = useState(deliveryAddress?.zipcode || '')
    const [fullAddress, setFullAddress] = useState(deliveryAddress?.fullAddress || '')
    const [isSaveAddress, setIsSaveAddress] = useState(false);
    let { mutateAsync: addAddress } = useAddAddress();
    let { authInfo } = useAuthState();
    const queryClient = useQueryClient();
    const [isAddressChanged, setIsAddressChanged] = useState(false)



    useEffect(() => {
        if(deliveryAddress){
            initialFormState.address_name= !deliveryAddress?.id ? deliveryAddress?.address_name : "";
            initialFormState.street_name= !deliveryAddress?.id ? deliveryAddress?.street_name : "";
            initialFormState.city= !deliveryAddress?.id ? deliveryAddress?.city : "";
            initialFormState.state= !deliveryAddress?.id ? deliveryAddress?.state : "";
            initialFormState.zipcode= !deliveryAddress?.id ? deliveryAddress?.zipcode : "";
        }
    }, [deliveryAddress])
    const saveAddressToUserProfile = (values: DeliveryAddressFromFields, delivery_address) => {
        const { street_name } = delivery_address;
        let { address_name } = values
        address_name = address_name?.trim()
        let modifiedValues = {
            ...delivery_address,
            customer_id: authInfo.userId,
            isDefault: false,
            ...(address_name ? { address_name } : { address_name: street_name }),
        }
        return addAddress(
            {
                deliveryAddress: modifiedValues,
                authentication_token: authInfo.authentication_token,
            },
            {
                onSuccess: (data, variables, context) => {
                    queryClient.refetchQueries(["profile", authInfo.userId]);
                    delivery_address = {
                        ...delivery_address,
                        id: data.id
                    }
                    setNewAddedAddressForDelivery({value: data.address_name, label: data.address_name, id: data.id,
                        isDefault: data.is_default,street: data.street_name, ...data})
                    closeNewAddressForm()
                },
                onError: (error, variables, context) => {
                    Toast_Func({ status: false, message: error[0] });
                },
            }
        )
    }

    const handleAddressChange= (e) => {
        setStreet_name(e.streetName)
        setCity(e.cityName)
        setState(e.state)
        setZipcode(e.postalCode)
        setFullAddress(e.address)
        setCountry(e.country)
    }

    const handleFormSubmission = async (values: DeliveryAddressFromFields, { setSubmitting }: any) => {
        if(fullAddress?.trim() === '' || isTypedAddress || street_name?.trim() === '' || city?.trim() === '' || state?.trim() === '' || zipcode?.trim() === ''){
            Toast_Func({
              status: false,
              message: "Address should contain valid / nearby street city and state.",
            });
            setSubmitting(false);
            return;
          }

        const address_name = !isGuestUser(authInfo)
        ? values?.address_name || street_name
        : street_name;
        const delivery_address = {
          address_name,
          city,
          state,
          street: street_name,
          zipcode,
          street_name,
          country,
          apartment_no: values.optional_address_details,
          full_address: fullAddress,
        };
        if(!isGuestUser(authInfo) && isSaveAddress) {
            await saveAddressToUserProfile(values, delivery_address)
        }
        else {
            const response = await checkNearestStoreLocation(delivery_address)
            if(!response) {
                setSubmitting(false)
            } else {
                if( response && !response.is_live ) {
                    handleIsLiveLocationRedirection(saveSelectedOrderType, response, props.isItMobile)
                } else {
                props.handleLocation(response, {...delivery_address, isGuestAddress: isGuestUser(authInfo)})
                setSubmitting(false)
            }
        }
        }
    setSubmitting(false)
    };
    return (
        <div>
            {
                deliveryAddress ?
                    <button className="ps-0 btn btn-custom f-s14 font-rale   align-items-center gap-2 d-flex mb-2" onClick={ () => closeNewAddressForm()} >
                        <img src={require("../../../../src/images/arrow-back.svg")} alt='back' />
                        Back
                    </button> : null
            }
            <p className= {`${styleClasses.delivery_alert_text} clr-red-dark text-center f-s14 pb-4`} hidden={props.isNearestStoreAvailable}>
              Option unavailable. Unfortunately, you’re too far from the nearest Cafe Zupas location.
            </p>
            <div className="px-md-5 px-0">
                <div>
                    <Formik
                        initialValues={initialFormState}
                        onSubmit={handleFormSubmission}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form className= {`position-relative new_form_design`} onSubmit={handleSubmit}>
                                {!isGuestUser(authInfo) &&
                                    <FormField
                                    labelText={"Name Your Saved Address"}
                                    name="address_name"
                                    value={values?.address_name}
                                    type="text"
                                    onChange={(e) => {
                                        handleChange(e)
                                    }
                                }
                                    errors={errors}
                                    touched={touched}
                                    placeholder="Name"
                                />}
                                <Row>
                                <GooglePlacesSearchInput
                                    defaultAddress={fullAddress}
                                    handleAddressChange={handleAddressChange}
                                    setIsAddressChanged={setIsAddressChanged}
                                    setIsTypedAddress={setIsTypedAddress}
                                />
                                </Row>
                                <div className='my-3'>
                                    <FormField
                                        labelText={"Apt, Floor, Suite, Building, Company (Optional)"}
                                        name="optional_address_details"
                                        value={values.optional_address_details}
                                        type="text"
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        placeholder="Apt, Floor, Suite, Building, Company"
                                        maxLength={100}
                                    />
                                </div>
                                {
                                    !isGuestUser(authInfo) ?
                                        <>
                                            <InputCheckbox
                                                id="save_card"
                                                name="save_card"
                                                onChange={(e) => {
                                                    setIsSaveAddress(!isSaveAddress)
                                                }}
                                                checked={isSaveAddress}
                                                labelText="Save address information to my account"
                                            />

                                        </> : null
                                }
                                <div className="d-flex justify-content-center mt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-large py-2 f-s16 mt-3 mb-5 w-100 mx-md-5 lh-lg"
                                        disabled={isSubmitting }
                                    >
                                        { props.isFindingNearest ? "Searching for nearest store..." : "Continue"}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default VisitorDeliveryAddress