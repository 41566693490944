import React, {Suspense, useEffect} from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import Spinner from "../../components/Spinner/Spinner";
import Header from "../../components/Header/Header";
import MenuDetailsContainer from "../../containers/menu/menuDetails";
import RequireAuth from "../../components/RequireAuth/RequireAuth";
import * as CONSTANT from "../../constants";
import ScrollToTop from "../../components/ScrollToTop";
import { getUser, getVisitorId, shouldRelease} from "../../helpers/helperMethods";
import {SELECTED_STORE} from "../../constants";
import {locationMenuService} from "../../services";
import {setLocation, useStoreLocation} from "../../context/StoreLocation";
import RouteWithReactHelmet from "../../components/ReactHelmetHOC/ReactHelmetWrapper";
import { bugSnagInititalize } from "../../helpers/bugSnagHelper";

const ErrorBoundaryBugSnag = bugSnagInititalize();

const HomeContainer = React.lazy(() => import("../home/home"));
const PublicOrderTrackingContainer = React.lazy(() => import("../publicOrderTracking/publicOrderTracking"));
const SelectRestaurantContainer = React.lazy(
  () => import("../selectRestaurant/selectRestaurant")
);
const OurKitchenContainer = React.lazy(
  () => import("../ourKitchen/ourKitchen")
);
const CateringContainer = React.lazy(() => import("../catering/catering"));
const DeliveryOrder = React.lazy(
  () => import("../../components/OnlineOrderSection/DeliveryOrder")
);
const CateringRequestFormContainer = React.lazy(
  () => import("../catering/cateringRequestForm")
);
const FundraisingContainer = React.lazy(
  () => import("../fundraising/fundraising")
);
const PickUpOrder = React.lazy(
  () => import("../../components/OnlineOrderSection/PickUpOrder")
);
const LocationInfoContainer = React.lazy(
  () => import("../location/locationInfo")
);
const menuDetailsContainer = React.lazy(() => import("../menu/menuDetails"));
const ItemGridContainer = React.lazy(
  () => import("../../components/ItemGrid/ItemGrid")
);
const OrderLandingContainer = React.lazy(
  () => import("../../containers/orderLanding/orderLanding")
);
const OrderContainer = React.lazy(() => import("../../containers/order/order"));
const CustomizeContainer = React.lazy(
  () => import("../../containers/order/customize")
);
const AccountContainer = React.lazy(
  () => import("../../containers/account/accountDetails")
);
const LoginContainer = React.lazy(() => import("../account/login"));
const CreateAccountContainer = React.lazy(
  () => import("../account/createAccount")
);
const ForgotPasswordContainer = React.lazy(
  () => import("../account/forgotPassword")
);
const NewPasswordContainer = React.lazy(() => import("../account/newPassword"));
const ProfileDetailsContainer = React.lazy(
  () => import("../account/profileDetails")
);
const CheckoutContainer = React.lazy(
  () => import("../../containers/order/checkout")
);
const CheckoutCurbsideContainer = React.lazy(
  () => import("../order/checkoutCurbside")
);
const OrderConfirmationContainer = React.lazy(
  () => import("../../containers/order/orderConfirmation")
);
const OrderConfirmationDeliveryContainer = React.lazy(
  () => import("../../containers/order/orderConfirmationDelivery")
);
const OrderConfirmationGuestContainer = React.lazy(
  () => import("../../containers/order/orderConfirmationGuest")
);
const Try2Container = React.lazy(() => import("../../containers/try2/try2"));
const Try2MenuContainer = React.lazy(
  () => import("../../containers/try2/try2Menu")
);
const Try2OrderContainer = React.lazy(
  () => import("../../containers/try2/order")
);
const Try2CutomizationContainer = React.lazy(
  () => import("../../containers/try2/customize")
);
const Try2ComboContainer = React.lazy(
  () => import("../../containers/try2/try2Combo")
);
const Try2SelectionContainer = React.lazy(
  () => import("../../containers/try2/try2selected")
);
const AddIngredients = React.lazy(
  () => import("../../containers/order/ingredients")
);
const OrderTracking = React.lazy(
  () => import("../../containers/orderTracking/index")
);
const CareerContainer = React.lazy(() => import("../career/career"));
const TalkToUsContainer = React.lazy(() => import("../talkToUs/talkToUs"));
const ContactUsContainer = React.lazy(() => import("../talkToUs/ContactUs"));
const PunchContainer = React.lazy(() => import("../Punch/punchRegister"));
const LocationContainer = React.lazy(() => import("../location/location"));
// const AllLocations = React.lazy(() => import("../location/all_locations"));
const OrderOnlineContainer = React.lazy(
  () => import("../orderOnline/orderOnline")
);
const CateringOrderContainer = React.lazy(
  () => import("../cateringOrder/cateringOrder")
);
const RewardsDetails = React.lazy(
  () => import("../rewardsDetails/rewardsDetails")
);
const MenuContainer = React.lazy(() => import("../menu/menu"));
const MenuCateringContainer = React.lazy(
  () => import("../menuCatering/menuCatering")
);
const Terms = React.lazy(() => import("../terms/terms"));
const PrivacyPolicy = React.lazy(
  () => import("../privacyPolicy/privacyPolicy")
);
const DownloadApp = React.lazy(
  () => import("../downloadApp/downloadApp")
);
const KidsCombo = React.lazy(() => import("../kidsCombo"));
const WelcomeScreen = React.lazy(() => import("../account/welcomeScreen"));
const RewardsRedeem = React.lazy(
  () => import("../rewardsDetails/RedeemRewards")
);

const ViewMoreRecentOrder = React.lazy(
  () => import("../../components/Tabs/RecentOrder/ViewMoreRecentOrder")
);
const PrintRecipt = React.lazy(
  () => import("../../components/Tabs/RecentOrder/PrintRecipt/PrintRecipt")
);

const LoyaltyFAQ = React.lazy(() => import("../loyaltyFAQ/loyaltyFAQ"));

const Page404 = React.lazy(() => import("../../components/Page404"));


const Routes: React.FunctionComponent = () => {
  const history = useHistory();
  const checkVisitorId = () => {
    if (!getVisitorId() && !getUser().id) history.go(0);
  }
  useEffect(() => {
    window.addEventListener('focus', checkVisitorId)
  },[history])

  const locationInfo = JSON.parse(localStorage.getItem(SELECTED_STORE))
  const { dispatch } = useStoreLocation();

  const refetchLocation = async () => {
    const response = await locationMenuService.getLocationDetails(locationInfo?.id)
    if(response) setLocation(dispatch, response?.data?.data)
  }

  const user = getUser();

  useEffect(() => {
    if (locationInfo?.id) {
      refetchLocation()
    }
  }, [])
  return (
    <Suspense fallback={<Spinner lightBg={true} />}>
      <Header />
      <ErrorBoundaryBugSnag FallbackComponent={Page404}>
        <ScrollToTop>
          <Switch>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_HOME}
              exact={true}
              routeComponent={HomeContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_FAQ}
              exact={true}
              routeComponent={LoyaltyFAQ}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_SELECT_RESTAURANT}
              exact={true}
              routeComponent={SelectRestaurantContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_DELIVERY_ORDER_FORM}
              exact={true}
              routeComponent={DeliveryOrder}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_PICKUP_ORDER_FORM}
              exact={true}
              routeComponent={PickUpOrder}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_OUR_KITCHEN}
              exact={true}
              routeComponent={OurKitchenContainer}
            ></RouteWithReactHelmet>
            {/* <RouteWithReactHelmet
              path={CONSTANT.ROUTE_CATERING}
              exact={true}
              routeComponent={CateringContainer}
            ></RouteWithReactHelmet> */}
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_CATERING_REQUEST_FORM}
              exact={true}
              routeComponent={CateringRequestFormContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_FUNDRAISING}
              exact={true}
              routeComponent={FundraisingContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_FUNDRAISING}
              exact={true}
              routeComponent={FundraisingContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_CAREERS}
              exact={true}
              routeComponent={CareerContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_TALK_TO_US}
              exact={true}
              routeComponent={ContactUsContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_LOCATIONS}
              exact={true}
              routeComponent={LocationContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_LOCATIONS_INFO}`}
              exact={true}
              routeComponent={LocationInfoContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_LOCATION_COPY}/info/:id`}
              exact={true}
              routeComponent={LocationContainer}
            ></RouteWithReactHelmet>
            {/* <RouteWithReactHelmet
              path={CONSTANT.ROUTE_ALL_LOCATIONS}
              exact={true}
              routeComponent={AllLocations}
            ></RouteWithReactHelmet> */}
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_ORDER_ONLINE}
              exact={true}
              routeComponent={OrderOnlineContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_CATERING_ORDER}
              exact={true}
              routeComponent={CateringOrderContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_MENU}
              exact={true}
              routeComponent={MenuContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_MENU}/:selected_category?`}
              exact={true}
              routeComponent={MenuContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_MENU}/:id`}
              exact={true}
              routeComponent={MenuContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_MENU_CATERING}
              exact={true}
              routeComponent={MenuCateringContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.REWARDS_DETAILS_ROUTE}
              exact={true}
              routeComponent={RewardsDetails}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.TERMS_ROUTE}
              exact={true}
              routeComponent={Terms}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.TERMS_ROUTE_HTML}
              exact={true}
              routeComponent={Terms}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.PRIVACY_POLICY_ROUTE}
              exact={true}
              routeComponent={PrivacyPolicy}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.DOWNLOAD_APP}
              exact={true}
              routeComponent={DownloadApp}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_MENU_DETAILS}/:id`}
              exact={true}
              routeComponent={MenuDetailsContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_CATERING_MENU_DETAILS}/:id`}
              exact={true}
              routeComponent={MenuDetailsContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_Item_Grid}
              exact={true}
              routeComponent={ItemGridContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_ORDER_LANDING}
              exact={true}
              routeComponent={OrderLandingContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_ORDER}/:id`}
              exact={true}
              routeComponent={OrderContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_CUSTOMIZE}/:id`}
              exact={true}
              routeComponent={CustomizeContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path="/"
              exact={true}
              routeComponent={HomeContainer}
            ></RouteWithReactHelmet>

            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_LOGIN}
              exact={true}
              routeComponent={LoginContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_ACCOUNT}
              exact={true}
              routeComponent={AccountContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_CREATE_ACCOUNT}
              exact={true}
              routeComponent={CreateAccountContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_FORGOT_PASSWORD}
              exact={true}
              routeComponent={ForgotPasswordContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_NEW_PASSWORD}
              exact={true}
              routeComponent={NewPasswordContainer}
            ></RouteWithReactHelmet>
            <RequireAuth
              path={CONSTANT.ROUTE_PROFILE_DETAILS}
              exact={true}
              component={ProfileDetailsContainer}
            ></RequireAuth>
            {(process.env.REACT_APP_ENABLE_GUEST_CHECKOUT ||
              user?.type !== CONSTANT.GUEST_USER) && (
              <RouteWithReactHelmet
                path={CONSTANT.ROUTE_CHECKOUT}
                exact={true}
                routeComponent={CheckoutContainer}
              ></RouteWithReactHelmet>
            )}
            {/* <RouteWithReactHelmet
              path={CONSTANT.ROUTE_CHECKOUT_DELIVERY}
              exact={true}
              routeComponent={CheckoutDeliveryContainer}
            ></RouteWithReactHelmet> */}
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_CHECKOUT_CURBSIDE}
              exact={true}
              routeComponent={CheckoutCurbsideContainer}
            ></RouteWithReactHelmet>
            {/* <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_TRY2}/:id`}
              exact={true}
              routeComponent={Try2Container}
            ></RouteWithReactHelmet> */}
            {/* <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_TRY2_ORDER}/:id`}
              exact={true}
              routeComponent={Try2OrderContainer}
            ></RouteWithReactHelmet> */}
            {/* <RouteWithReactHelmet
              path={CONSTANT.ROUTE_TRY2_MENU}
              exact={true}
              routeComponent={Try2MenuContainer}
            ></RouteWithReactHelmet> */}
            {/* <RouteWithReactHelmet
              path={CONSTANT.ROUTE_TRY2_ORDER}
              exact={true}
              routeComponent={Try2OrderContainer}
            ></RouteWithReactHelmet> */}
            {/* <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_TRY_2_ORDER_CUSTOMIZATION}/:id`}
              exact={true}
              routeComponent={Try2CutomizationContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_TRY2_COMBO}
              exact={true}
              routeComponent={Try2ComboContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_TRY2_SELECTION}
              exact={true}
              routeComponent={Try2SelectionContainer}
            ></RouteWithReactHelmet> */}
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_ADD_INGREDIENTS}
              exact={true}
              routeComponent={AddIngredients}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_ORDER_CONFIRMATION}
              exact={true}
              routeComponent={OrderConfirmationContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_ORDER_CONFIRMATION_DELIVERY}
              exact={true}
              routeComponent={OrderConfirmationDeliveryContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_ORDER_CONFIRMATION_GUEST}
              exact={true}
              routeComponent={OrderConfirmationGuestContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.PUNCH_SIGNUP_FORM}
              exact={true}
              routeComponent={PunchContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_KIDS_COMBO}
              exact={true}
              routeComponent={KidsCombo}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_WELCOME_SCREEN}
              exact={true}
              routeComponent={WelcomeScreen}
            ></RouteWithReactHelmet>
            {shouldRelease("Routes", "redeem") && (
              <RouteWithReactHelmet
                path={CONSTANT.ROUTE_REWARDS_REDEEM}
                exact={true}
                routeComponent={RewardsRedeem}
              ></RouteWithReactHelmet>
            )}
            <RequireAuth
              path={CONSTANT.VIEW_MORE_RECENT_ORDER}
              exact={true}
              component={ViewMoreRecentOrder}
            ></RequireAuth>
            <RouteWithReactHelmet
              path={CONSTANT.PRINT_RECEIPT}
              exact={true}
              routeComponent={PrintRecipt}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={`${CONSTANT.ROUTE_ORDER_TRACKING}/:id`}
              exact={true}
              routeComponent={PublicOrderTrackingContainer}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet
              path={CONSTANT.ROUTE_THANK_YOU}
              exact={true}
              routeComponent={OrderTracking}
            ></RouteWithReactHelmet>
            <RouteWithReactHelmet path="/404" routeComponent={Page404} />
            <Redirect to="/menu" />
          </Switch>
        </ScrollToTop>
      </ErrorBoundaryBugSnag>
    </Suspense>
  );
};

export default Routes;
