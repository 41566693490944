import React, { useState, useEffect } from "react";
import styleClasses from "./NewFooter.module.scss";
import Image from "../Image/Image";
import { Container, Row, Col, Navbar, NavLink } from "react-bootstrap";
import NavbarComponent from "../NavbarComponent/NavbarComponent";
import * as CONSTANT from "../../constants";
import useKeypress from "../../hooks/useKeypress";
import PopUpModel from "../../components/Footer/PopUpModel";
import { NavlinkModel } from "../../models/navlink.model";

interface footerProps {
  bgColor?: string;
  textColor?: string;
  mt?: boolean;
  footer_img_controller?: string;
  sectionController?: string;
  hidemurals?:string;
  stickybtn?:string
}
interface UserFormData {
  first_name: string;
  last_name: string;
  email: string;
  location: string;
}

const NewFooter: React.FunctionComponent<footerProps> = ({
  bgColor,
  textColor,
  footer_img_controller,
  sectionController,
  hidemurals,
  stickybtn
}) => {
  useKeypress("Escape", () => {
    setShow(false);
  });

  const [footer_Nav] = useState<boolean>(true);
  const [isDeviceIos, setIsDeviceIos] = React.useState(false);
  const [show, setShow] = useState(false);

  const navLinksArray: NavlinkModel[] = [
    // { link: "#popup", text: 'EMAIL SIGN UP' },
    { link: "/talk", text: "CONTACT US", alternateURL:{exist:false, link: null} },
    { link: "/assets/Cafe-Zupas_Nutrition.pdf", text: "NUTRITIONAL INFO", alternateURL:{exist:false, link: null} },
    { link: "/assets/catering-2024.pdf", text: "CATERING PRINT MENU", alternateURL:{exist:false, link: null} },
    { link: "/privacyPolicy", text: "PRIVACY POLICY", alternateURL:{exist:false, link: null} },
    { link: "/terms", text: "TERMS & CONDITIONS", alternateURL:{exist:false, link: null} },
    { link: `${CONSTANT.TRANSPARENCY_IN_COVERAGE}`, text: "TRANSPARENCY IN COVERAGE", alternateURL:{exist:false, link: null} },
  ];
  const socialIconsArray = [
    {
      link: `${CONSTANT.CAFEZUPAS_FACEBOOK_URL}cafezupas/`,
      icon: `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/icons/facebook-icon.svg`,
      socail_alt_text: `facebook`,
    },
    {
      link: `${CONSTANT.CAFEZUPAS_INSTAGRAM_URL}cafezupas/`,
      icon: `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/icons/Instagram-icon.svg`,
      socail_alt_text: `instargram`,
    },
    {
      link: `${CONSTANT.CAFEZUPAS_PINTEREST_URL}cafezupas/_created/`,
      icon: `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/icons/pinterest-icon.svg`,
      socail_alt_text: `pinterest`,
    },
    {
      link: `${CONSTANT.CAFEZUPAS_TWITTER_URL}cafezupas`,
      icon: `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/icons/twitter-icon.svg`,
      socail_alt_text: `twitter`,
    },
  ];
  const [moboImages, setmoboImages] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleClick = () => setShow(true);

  useEffect(() => {
    let isMounted = true;
    var userAgent = navigator.userAgent || navigator.vendor;
    var x = window.matchMedia("(min-width: 768px)");
    if (x.matches) {
      setmoboImages(true);
    } else {
      setmoboImages(false);
    }
    if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
      setIsDeviceIos(true);
    } else {
      setIsDeviceIos(false);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={sectionController}>
      <div
        className={`${styleClasses.footer_img_wrap} ${footer_img_controller} ${hidemurals}`}
      >
        <Image
          className="w-100"
          source={
            moboImages
              ? isDeviceIos
                ? `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Footer/Footer.jpeg`
                : `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Footer/Footer.webp`
              : isDeviceIos
              ? `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Footer/footer-art-mobo.jpeg`
              : `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Footer/footer-art-mobo.webp`
          }
          alt="footer art"
        />
      </div>

      <div className={`${styleClasses.footer_div} ${bgColor} ${stickybtn}`}>
        <Container fluid className="px-4 px-lg-5">
          <Row className="align-items-start">
            <Col
              lg={4}
              className={`${styleClasses.footer_newsletter} clr-sec-white text-start pe-5 d-none d-lg-block`}
            >
              <h5>ALWAYS KNOW WHAT’S NEW!</h5>
              <p className="text-style1 clr-sec-white mb-3 mb-lg-5">
                Nourish the Good Life with exclusive Café Zupas offers, updates
                and seasonal menus sent straight to your inbox.
              </p>
              <button
                type="button"
                onClick={handleClick}
                className="btn btn-large btn-white"
              >
                JOIN OUR EMAIL LIST
              </button>
            </Col>
            <Col
              lg={8}
              className={`${styleClasses.footer_menu_links_wrap} content_center_box d-flex align-items-start flex-column justify-content-center justify-content-lg-around flex-lg-row footer_menu_links_wrap ps-lg-5`}
            >
              <div className={`${styleClasses.nav_menu_items_wrapper} `}>
                <NavbarComponent
                  modalOpen={handleClick}
                  isHeader={false}
                  Navlinks={navLinksArray}
                  footer_controller="d-none  text-start pt-0"
                  footer_mobile_controller=" d-none justify-content-start"
                  footer_Nav={footer_Nav}
                  classes="color-white footer-font"
                  expand="md"
                ></NavbarComponent>
              </div>
              <div
                className={`${styleClasses.social_icon_container} d-flex align-items-center align-items-lg-start flex-lg-column justify-content-between`}
              >
                <h6
                  className={`${styleClasses.cap_text} mb-0 clr-white-clr me-2`}
                >
                  Stay Connected:
                </h6>
                <Navbar
                  expand="md"
                  className="footer_Nav_links_wrap w-auto align-items-center"
                >
                  {socialIconsArray.map((item, j) => (
                    <NavLink href={item.link} key={j}>
                      <img
                        src={item.icon}
                        className={styleClasses.social_icons_items}
                        alt={item.socail_alt_text}
                      />
                    </NavLink>
                  ))}
                </Navbar>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Row>
        <Col xs="12" sm="12" md="6">
          <PopUpModel showPopup={show} closePopup={handleClose}/>
        </Col>
      </Row>
    </div>
  );
};

export default NewFooter;
