import React, { useEffect, useRef, useState } from "react";
import Steppers from "../OnlineOrderTracking/Stepper";
import styleClasses from "./liveTracking.module.scss";
import {
  COMPLETE,
  CURBSIDE,
  DELIVERED,
  DELIVERY,
  GOOD_LIFE_LANE,
  IN_STORE,
  BUFFER_TIME_QUEUE_CREATION,
  QUEUE_FOR_DOOR_DASH,
  QUEUE_FOR_ORDER_STATUS,
} from "../../constants";
import { handleOrderType } from "../../helpers/tracking/orderTracking";
import DeliveryAddress from "./DeliveryAddress";
import {
  formatOrderHeadline,
  formatOrderTime,
  getStatusQueueApp,
  PickUpMethods,
} from "../../helpers/tracking";
import StoreAddress from "./StoreAddress";
import { orderMicroService } from "../../services";
import Button from "../Buttons/Button";
import OrderTrackingMap from "../OrderTrackingMap";
import useTimeZone from "../../react-query-hooks/useTimeZone";
import { IDoorDash, IOrderStatus } from "../../models/tracking";
import { Toast_Func } from "../../helpers/toast.helper";
import useGetOrderStatus from "../../react-query-hooks/useGetOrderStatus";

interface IOrderProcessProps {
  order: any;
  orderId: any;
  isFetching: boolean;
  isPublic?: boolean;
  orderStatus: IOrderStatus;
  setOrderStatus: (orderStatus: IOrderStatus) => void;
  doorDash: IDoorDash;
  setDoorDash: (doorDash: IDoorDash) => void;
  isOrderCancelledOrFailed: boolean;
  encryptedOrderId?: any;
}

const EstimatedHealdine = {
  [IN_STORE]: "Estimated Ready For Pickup Time",
  [CURBSIDE]: "Estimated Ready For Pickup Time",
  [GOOD_LIFE_LANE]: "Estimated Ready For Pickup Time",
  [DELIVERY]: "Estimated Arrival",
};

const OrderProcess: React.FC<IOrderProcessProps> = ({
  order,
  orderId,
  isFetching,
  isPublic,
  orderStatus,
  setOrderStatus,
  doorDash,
  setDoorDash,
  isOrderCancelledOrFailed,
  encryptedOrderId,
}) => {
  const [isCustomerHere, setIsCustomerHere] = useState<boolean>(false);
  const [fetchingIAmHere, setFetchingIAmHere] = useState<boolean>(false);

  const pickUpMethod = PickUpMethods[order?.destination_id];
  const timeZone = order?.store_details?.time_zone;
  const { data: storeDateTime } = useTimeZone({ timeZone: timeZone || "" });
  const [areQueuesCreated, setAreQueuesCreated] = useState(false);
  const {
    data: orderStatusData,
    isFetching: fetchingStatus,
    isLoading,
    refetch,
  } = useGetOrderStatus({
    orderId: order?.id,
    trackingType: areQueuesCreated ? "trackingorder" : null
  });
  const {
    data: dasherStatusData,
    isFetching: fetchingDasherStatus,
    refetch: refetchDasherstatus,
  } = useGetOrderStatus({
    orderId: order?.id,
    trackingType: areQueuesCreated && pickUpMethod === DELIVERY ? "doordash" : null
  });
  let queueCreationSetTimeOut = useRef(null);
  useEffect(() => {
    if (storeDateTime && !areQueuesCreated && order) {
      const storeDateTimeObj = new Date(storeDateTime);
      const orderFireTime = new Date(order?.fire_time);
      const isOrderFired = storeDateTimeObj.getTime() > orderFireTime.getTime();
      const milliSecondsTillOrderFireTime =
        orderFireTime.getTime() - storeDateTimeObj.getTime();
      if (isOrderFired && milliSecondsTillOrderFireTime < 0  || order?.brink_order_id) {
        setAreQueuesCreated(true);
      } else
        queueCreationSetTimeOut.current = setTimeout(
          () => {
            setAreQueuesCreated(true);
          },
          milliSecondsTillOrderFireTime + BUFFER_TIME_QUEUE_CREATION // adding a minute buffer for queues creation
        );
    }
  }, [storeDateTime, order]);

  useEffect(() => {
    setIsCustomerHere(false);
    setOrderStatus(null);
    let orderStatusApp = null;
    if (areQueuesCreated) {
      orderStatusApp = getStatusQueueApp({
        orderId: orderId,
        setState: setOrderStatus,
        queueFor: QUEUE_FOR_ORDER_STATUS,
      });
      orderStatusApp.start();
    }
    return () => {
      if (orderStatusApp?.isRunning) {
        orderStatusApp.stop();
      }
      if (queueCreationSetTimeOut.current) {
        clearTimeout(queueCreationSetTimeOut.current);
      }
    };
  }, [orderId, areQueuesCreated]);

  useEffect(() => {
    setDoorDash(null);
    let doorDashApp;
    if (pickUpMethod === DELIVERY) {
      doorDashApp = getStatusQueueApp({
        orderId: orderId,
        setState: setDoorDash,
        queueFor: QUEUE_FOR_DOOR_DASH,
      });
      doorDashApp.start();
    }
    return () => {
      if (doorDashApp?.isRunning) {
        doorDashApp.stop();
      }
    };
  }, [orderId, isFetching]);

  useEffect(() => {
    setOrderStatus(orderStatusData);
  }, [orderStatusData]);

  useEffect(() => {
    if (pickUpMethod === DELIVERY) 
    {
      setDoorDash(dasherStatusData);
    }
  }, [dasherStatusData]);
  const finalOrderStatus =
    orderStatus?.status || order?.tracking_details?.status;

  const finalDoorDash = doorDash || order?.delivery_details;

  const estimatedDeliveryTime =
    doorDash?.estimated_delivery_time || order?.estimated_delivery_time;
  const promiseTime = estimatedDeliveryTime || order?.promise_time;

  const isOrderDelivered =
    finalOrderStatus === COMPLETE && finalDoorDash?.status === DELIVERED;

  const isCustomerReallyHere =
    isCustomerHere || order?.is_customer_here || isOrderCancelledOrFailed;

  const handleIAmHereClick = async () => {
    setFetchingIAmHere(true);
    try {
      const response = encryptedOrderId
        ? await orderMicroService.notifyIAmHereUpdateStatus(encryptedOrderId, {
            state: "completed",
          })
        : await orderMicroService.notifyIAmHere(order?.id, {
            state: "completed",
          });

      if (response?.status === 200) {
        setIsCustomerHere(true);
      }
    } catch (error) {
      Toast_Func({
        status: false,
        message: error?.response?.data?.message || "Something went wrong!",
      });
    } finally {
      setFetchingIAmHere(false);
    }
  };

  return (
    <div>
      <h2 className="mb-0 f-s18 f-sm-only-s22 clr-dark-gray text-uppercase font-Visby-cf fw-bold text-center">
        {isOrderDelivered ? (
          <span className="clr-dark-blue">Your order has arrived!</span>
        ) : (
          <>
            {EstimatedHealdine[pickUpMethod]} -{" "}
            <span className="clr-dark-blue">
              {formatOrderTime(promiseTime)}{" "}
            </span>
            {isOrderCancelledOrFailed && (
              <span className="clr-dark-red">
                {`(${finalOrderStatus?.toUpperCase()})`}
              </span>
            )}
          </>
        )}
      </h2>
      <hr className="mb-3 mt-md-2 mt-0" />
      {pickUpMethod === CURBSIDE && (
        <div className="text-center mt-4 mb-3">
          <Button
            type="button"
            onClick={() => handleIAmHereClick()}
            disabled={isCustomerReallyHere}
            loading={fetchingIAmHere}
            className="btn btn-large"
          >
            I’m Here!
          </Button>
        </div>
      )}
      <h3 className="f-s16 f-sm-only-s20 fw-normal mb-0 text-start clr-dark-grey">
        Details
      </h3>
      <p
        className={`f-s14 ${
          isOrderCancelledOrFailed ? "clr-dark-red" : "clr-dark-gray"
        } mb-0 text-uppercase font-Cls ls-1 fw-normal text-start`}
      >
        {formatOrderHeadline(
          pickUpMethod,
          finalOrderStatus,
          finalDoorDash?.status
        )}
      </p>
      <Steppers
        orderStatus={finalOrderStatus}
        StepperCircle={`${styleClasses.live_tracking_circle}`}
        TitleHide="d-none"
        isOrderDelivered={isOrderDelivered}
        isRenderedInLiveTracker={true}
        isPublic={isPublic}
        orderTypeImage={handleOrderType(pickUpMethod)?.image}
      />
      <hr className="mb-3" />
      {pickUpMethod === DELIVERY ? (
        <DeliveryAddress customerDetails={order?.customer} />
      ) : (
        <StoreAddress storeDetails={order?.store_details} />
      )}

      <div
        className={`${styleClasses.map_wrapper} my-3 mb-md-5 me-md-4 px-md-2 position-relative`}
      >
        <OrderTrackingMap
          doorDash={finalDoorDash}
          storeLocation={order?.store_details}
          customerDetails={order?.customer}
          pickUpMethod={pickUpMethod}
        />
        <hr className="d-md-none d-block my-3" />
      </div>
    </div>
  );
};

export default OrderProcess;
