import React, { useState, useEffect, useRef, MouseEvent } from 'react'
import { Col, Image } from 'react-bootstrap'
import styleClasses from "../../../../src/components/ItemGrid/ItemGrid.module.scss"
import { item } from '../../../containers/order/fakeData'
import * as CONSTANT from "../../../constants";
import FavoriteButton from '../../../containers/try2/Customization/FavoriteButton'
import TagSymbol from '../../Molecules/Symbols/Symbol'
import ItemPrices from '../ItemPrices';
import { useAuthState } from '../../../context/UserAuthentication';
import { availableForSelectedOrderType, verifyStatus } from '../../../helpers/customizedItem/customizedItem.helper';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import { useHistory, useLocation } from 'react-router';

interface IItemDescriptionDetail {
  item: any
  favourites: { id: number; item_id: number; isSelected: boolean; }[]
  favoriteButtonHandler: any
  showDetailsHandler: any
  locationInfo: any
  orderType: any
  catering_menus: string
  filteredIitems: any[]
  discountApplied: any
  getClories: (item: any) => string
  orderClickHandler: any
}

const ItemDescriptions: React.FC<IItemDescriptionDetail> = (props) => {

  const { item, favourites, favoriteButtonHandler, showDetailsHandler, locationInfo, orderType, catering_menus, filteredIitems, discountApplied, getClories, orderClickHandler } = props;

  const [divHeight, setDivHeight] = useState(0);
  const itemHeight = useRef(null);
  const heightSetRef = useRef(null);
  const { authInfo } = useAuthState();
  const [isDeviceIos, setIsDeviceIos] = React.useState<boolean>(false);
  const [isItemActive, setIsItemActive] = React.useState<boolean>(false);
  const isItMobile = useCheckMobileScreen();
  const history = useHistory()


  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
      setIsDeviceIos(true);
    } else {
      setIsDeviceIos(false);
    }
  }, []);

  useEffect(() => {
    if (checkIsItemActive()) setIsItemActive(true)
    else setIsItemActive(false)
  }, [item])

  const showFavrtButton = (item) => {
    if (authInfo.userId && authInfo.type !== CONSTANT.GUEST_USER && item?.name?.toLowerCase() !== CONSTANT.CREATE_YOUR_OWN_SALAD.toLowerCase()) {
      return true
    }
    return false
  }
  useEffect(() => {
    if ((!divHeight || divHeight <= 0) && itemHeight?.current?.scrollHeight && filteredIitems.length && heightSetRef?.current === null) {
      setDivHeight(itemHeight?.current?.scrollHeight);
      heightSetRef.current = true;
    }
  }, [filteredIitems, history.location.pathname]);

  const attachClickHandlerWithItemWidget = (item: any) => {
    if (item.is_active && verifyStatus(item, "item_location")) {
      if (availableForSelectedOrderType(item, orderType)) {
        return true
      }
    }
    return false
  }

  const checkBadge = (item: any) => {
    let discountItem = null;
    if (discountApplied) {
      discountItem = discountApplied.find(
        (rewardItem) => rewardItem.item_id == item.id
      );
    }
    if (discountItem)
      return (
        <Image
          src={`${CONSTANT.REACT_APP_CLOUD_FRONT}/images/icons/redeem-badge.svg`}
          className={`${styleClasses.badge_img} img-fluid`}
          alt="redeem badge"
        />
      );
    else return null;
  };

  const checkIsItemActive = () => {
    return item.is_active &&
      verifyStatus(item, "item_location") &&
      availableForSelectedOrderType(item, orderType)
  }

  return (
    <>
      <Col
        key={item.id}
        lg="4"
        md="6"
        sm="12"
        className={`${styleClasses.car_col} p-0 gap-item`}
      >
        <div
          className={`${styleClasses.item_main_div} d-flex flex-column h-100`}
          onClick={(e) => attachClickHandlerWithItemWidget(item) ? orderClickHandler(e, item) : ""}
        >
          <div className={styleClasses.item_img_wrap}>
            {checkBadge(item)}
            <Image
              src={
                isDeviceIos
                  ? `${CONSTANT.REACT_APP_CLOUD_FRONT}/Items/${item.id}/item-${item.id}.jpeg`
                  : `${CONSTANT.REACT_APP_CLOUD_FRONT}/Items/${item.id}/item-${item.id}.webp`
              }
              width="200"
              height="200"
              rounded
              className={isItemActive ? '' : `${styleClasses.is_not_active}`}
              alt= {item.name}
            />
            {showFavrtButton(item) && (
              <FavoriteButton
                favIcon={true}
                buttonHandler={favoriteButtonHandler}
                className={`${styleClasses.favbtn} d-none d-md-block`}
                item={item}
                favourite={favourites.some(
                  (fav) => fav.item_id === item.id
                )}
              />
            )}
          </div>
          <div
            className={`${styleClasses.item_title_wrap} menu-title d-flex align-items-start justify-content-start text-start`}
          >
            <div className="d-flex flex-fill">
              <h6 className="sub_title1 sub_title1_item">
                {item.name}
              </h6>
              <div className='d-none d-md-block'>
                <TagSymbol tags={item.tags} />
              </div>

            </div>
            {showFavrtButton(item) && (
              <FavoriteButton
                favIcon={true}
                buttonHandler={favoriteButtonHandler}
                className={`${styleClasses.favbtn} position-static d-md-none`}
                item={item}
                favourite={favourites.some(
                  (fav) => fav.item_id === item.id
                )}
              />
            )}
          </div>
          <div className='d-md-none my-1'>
              <TagSymbol tags={item.tags} />
            </div>
          {/* Exlude, feedback point number 302 */}
          {/* <div
                      className={`${styleClasses.item_calor_wrap} menu-calories d-flex justify-content-start`}
                    >
                      <h6 className="h-14 clr-text-grey font-rale mb-3">
                        {getClories(item)}
                      </h6>
                    </div> */}
          <p
            ref={itemHeight}
            className={`${styleClasses.item_subtitle_wrap} text-start mb-0 p-16`}
          >
            {item.description}
          </p>
          <p className={`${styleClasses.showMore} text-end p-16`}>
            {divHeight > 58 && <button
              type="button"
              className={`${styleClasses.item_subtitle_button} btn btn-custom p-0 f-w6 p-16`}
              onClick={(e) => showDetailsHandler(e, item)}
            >
              Show more
            </button>}
          </p>
          {/* Exlude, feedback point number 302 */}
          {/* <h6 className="mb-4 text-start h-16 font-Vcf ls-normal text-uppercase">
                      {locationInfo?.selectedStore && <ItemPrices getItem={item} />}
                    </h6> */}

          {/* Order Button */}
          {isItemActive ? (
            <button
            id="order-button"
              className={
                catering_menus
                  ? "btn btn-large w-100 mx-auto mw-auto"
                  : "btn btn-large w-100 mx-auto mw-auto"
              }
            >
              ORDER
            </button>
          ) : (
            <button
              disabled={true}
              className={
                catering_menus
                  ? "btn btn-large w-100 mx-auto mw-auto"
                  : "btn btn-large w-100 mx-auto mw-auto"
              }
            >
              {item?.is_delivery ? 'Unavailable' : 'Unavailable for delivery'}
            </button>
          )}
        </div>
      </Col>
    </>
  )
}

export default ItemDescriptions