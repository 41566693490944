import { Formik } from "formik";
import React, {useEffect, useState} from "react";
import { ISelectedAddress } from "../../../models/forms.model";
import { FormField } from "../../FormFields/FormField";
import useProfile from "../../../react-query-hooks/useProfile";
import {useAuthState} from "../../../context/UserAuthentication";
import {IItemPathToRoute} from "../../../models/item.model";
import {useHistory} from "react-router-dom";
import {INearestLocationQueryParams, LocationModel} from "../../../models/location.model";
import {selectDeliveryAddressSchema} from "../../../validationSchemas/selectDeliveryAddressSchema";
import VisitorDeliveryAddress from "./VisitorDeliveryAddress";
import {useAppDispatch, useAppSelector} from "../../../redux/store/store";
import { locationMenuService } from "../../../services";
import {getVisitorId, isGuestUser, validateCount} from "../../../helpers/helperMethods";
import styleClasses from "../ItemGrid.module.scss";
import { handleIsLiveLocationRedirection } from "../../../helpers/checkout/LocationHelper";
import { updateIsOrderTypeSelected, updateOrderDetails, updateOrderType } from "../../../redux/slices/cartSlice";
import { setLocation, useStoreLocation } from "../../../context/StoreLocation";
import { useQueryClient } from "react-query";
import { IGetCartPayload } from "../../../models/order.model";
import { GUEST_USER } from "../../../constants";

interface IItemDelivery {
  itemPath: IItemPathToRoute;
  shownDeilveryLocation: (isShown) => void;
  closeOpenOrderModal: () => void;
  nearestLocation: (isNearest) => void,
  isNearestStoreAvailable: boolean,
  showItemDeliveryDateTime?: boolean,
  handleShowDeliveryMethodModal?: (boolean) => void,
  saveSelectedOrderType?: string,
  handleOrderType?: (addressType: string ) => void;
  isItMobile: boolean;
  isCheckout: boolean;
}

export const ItemDelivery = ({
  shownDeilveryLocation,
  closeOpenOrderModal,
  nearestLocation,
  isNearestStoreAvailable,
  showItemDeliveryDateTime,
  handleShowDeliveryMethodModal,
  saveSelectedOrderType,
  handleOrderType,
  isItMobile,
  isCheckout,
  itemPath
}: IItemDelivery) => {
  
  const queryClient = useQueryClient();
  const orderTypeDispatch = useAppDispatch();
  const { authInfo } = useAuthState();
  const history = useHistory();
  const [userAddresses, setUserAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [isFindingNearest , setIsFindingNearest] = useState(false);

  const {dispatch: locationDispatch } = useStoreLocation()
  const selectedDeliveryAddress = useAppSelector((state) => state.cart?.orderDetails?.delivery_address || "");
  const [newAddedAddressForDelivery, setNewAddedAddressForDelivery] = useState(
      selectedDeliveryAddress ?
          {value: selectedDeliveryAddress.address_name, label: selectedDeliveryAddress.address_name, id: selectedDeliveryAddress.id,
            isDefault: selectedDeliveryAddress.is_default,street: selectedDeliveryAddress.street_name, ...selectedDeliveryAddress}
          : null
  )
  const isGuestAddressSelected = selectedDeliveryAddress?.isGuestAddress;
  const showNewAddressForm = () =>{
    if (isGuestAddressSelected) return false;
    if(selectedDeliveryAddress) return !selectedDeliveryAddress.id
    else return isGuestUser(authInfo)
  }
  const [addGuestAddress, setAddGuestAddress] = useState<boolean>(showNewAddressForm())
  const visitor_id = getVisitorId()

  const {
    isFetching,
    data: userProfileData,
  } = useProfile(authInfo.userId, authInfo.type);

  const checkNearestStoreLocation = async (address) =>{
    setIsFindingNearest(true)
    const payload : INearestLocationQueryParams = {
      delivery: true,
      city: address.city,
      state: address.state,
      street: address.street,
      zipcode: address.zipcode,
      delivery_address: authInfo.type === GUEST_USER ? address?.fullAddress ?? address?.full_address : address?.full_address 
    }
    if( isCheckout ) payload.is_checkout = 1
    const response = await locationMenuService.getNearbyLocations(payload)
    if(response?.data?.data[0]) {
      nearestLocation(true)
      setIsFindingNearest(false)
      return response.data.data[0]
    } else {
      nearestLocation(false)
      setIsFindingNearest(false)
      return null
    }
  }

  const closeNewAddressForm = () => {
    nearestLocation(true);
    shownDeilveryLocation(false)
    setAddGuestAddress(false)
  }
  const setAddress = (address) => {
    setSelectedAddress(address)
  }

  const handleLocation = (nearestLoc, deliveryAddress ) => {
    if( !nearestLoc.is_live ) {
      handleIsLiveLocationRedirection(saveSelectedOrderType, nearestLoc, isItMobile)
  } else {
      orderTypeDispatch(updateOrderDetails({"delivery_address" : deliveryAddress}))
      orderTypeDispatch(updateIsOrderTypeSelected(true))
      setLocation(locationDispatch, nearestLoc);
      const payload : IGetCartPayload = {
          location_id: nearestLoc.id.toString()
      }
      if( authInfo.userId ) payload.customer_id = authInfo.userId
      else payload.visitor_id = visitor_id
      queryClient.refetchQueries(["get-cart", nearestLoc.id]);
      closeOpenOrderModal()
      if(saveSelectedOrderType) {
        orderTypeDispatch(updateOrderType(saveSelectedOrderType));
      }
      if(showItemDeliveryDateTime || isCheckout) {
        handleShowDeliveryMethodModal(true)
      } 
    
      if(itemPath === null){
        history.go(0)
      }else{
        history.push(itemPath)
      }
  }
  }

  const handleFormSubmission = async (values, {setSubmitting}) => {
    if (isGuestAddressSelected) {
      closeOpenOrderModal() 
      return;
    }
    setSubmitting(true)
    const response = await checkNearestStoreLocation(selectedAddress)
    if(!response) {
      setSubmitting(false)
    } else {
      if( response && !response.is_live ) {
          handleIsLiveLocationRedirection(saveSelectedOrderType, response, isItMobile)
      } else {
        handleLocation(response, selectedAddress)
        setSubmitting(false)
    }
  }
}

const selectedDelieveryAddress = (allAddressArray:any) => {
  let address = newAddedAddressForDelivery;
  if(newAddedAddressForDelivery){
    address = allAddressArray?.find(address => address?.id === newAddedAddressForDelivery?.id)
  }
  return address
}

  useEffect(() => {
    if(!isGuestUser(authInfo)){
      if(!isFetching && userProfileData?.addresses?.length > 0) {
        const allAddressArray = userProfileData.addresses
                .map((address) => ({value: address.address_name, label: address.address_name, id: address.id, isDefault: address.is_default,street: address.street_name, ...address}))
        setUserAddresses(allAddressArray)
        setSelectedAddress(newAddedAddressForDelivery ? selectedDelieveryAddress(allAddressArray) : allAddressArray.find(address=> address.isDefault == 1))
      }
    }else if(newAddedAddressForDelivery) setSelectedAddress(newAddedAddressForDelivery)
  }, [userProfileData, newAddedAddressForDelivery]);

  const initialFormState: ISelectedAddress = {
    selected_address: newAddedAddressForDelivery ||
        userAddresses.find(address=> address.isDefault == 1)
  };
  return (
    <div>
      <div>
        { !addGuestAddress  && initialFormState?.selected_address?.hasOwnProperty("isDefault") ?
          <>
            <p className= {`${styleClasses.delivery_alert_text} clr-red-dark text-center f-s14 pb-4`} hidden={isNearestStoreAvailable}>
              Option unavailable. Unfortunately, you’re too far from the nearest Cafe Zupas location.
            </p>
              <Formik
                  initialValues={initialFormState}
                  onSubmit={handleFormSubmission}
                  validationSchema={selectDeliveryAddressSchema}
              >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) =>{
                    return (
                    <form className="new_form_design w-75 my-0 mx-auto delivery_location_select full_width_mob" onSubmit={handleSubmit}>
                      
                      {!isGuestAddressSelected && <FormField
                          isSearchable={true}
                          options={userAddresses}
                          labelText={"Delivery LOCATION"}
                          name="selected_address"
                          type="select"
                          placeholder={"Select a delivery address"}
                          value={values.selected_address}
                          onChange={(address) => {
                            setFieldValue("selected_address", address);
                            setAddress(address)
                            checkNearestStoreLocation(address)
                          }}
                          errors={errors}
                          touched={touched}
                          inputFieldClass={"custom_select custom_multi_select mb-0"}
                      />}
                      {selectedAddress && 
                        <div className="my-4">
                          <span className="d-block f-s14 font-Visby-cf f-w5">{selectedAddress.address_name}</span>
                          <span className="d-block f-s14 font-Visby-cf f-w5">{selectedAddress.street_name}, {selectedAddress?.apartment_no}</span>
                          <span className="d-block f-s14 font-Visby-cf f-w5">{selectedAddress.city}, {selectedAddress.state} {selectedAddress.zipcode}</span>
                        </div>
                      }
                      <div className="d-flex justify-start-center my-3">
                        <button
                            type="button"
                            className={`btn btn-custom p-0 f-w6 p-16 color-sky-blue-light font-Vcf`}
                            onClick={() => {if(validateCount(userAddresses))
                            {
                              shownDeilveryLocation(true)
                              nearestLocation(true)
                              setAddGuestAddress(true)
                            }}}
                        >
                          {isGuestAddressSelected ? "Change Address" : "Add a New Address"}
                        </button>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-large lh-lg py-2 mt-4 f-s16 w-100 mb-5"
                        disabled={ isSubmitting || !isNearestStoreAvailable || isFindingNearest }
                      >
                        { isFindingNearest ? "Searching for nearest store..." : "Continue"}
                      </button>
                    </form>
                )}}
              </Formik>
            </>
            :
            <VisitorDeliveryAddress
                handleLocation={handleLocation}
                isItMobile={isItMobile}
                checkNearestStoreLocation={checkNearestStoreLocation}
                saveSelectedOrderType={saveSelectedOrderType}
                isFindingNearest={isFindingNearest}
                isNearestStoreAvailable={isNearestStoreAvailable}
                setNewAddedAddressForDelivery={setNewAddedAddressForDelivery}
                closeNewAddressForm={closeNewAddressForm}
            />
        }
      </div>
    </div>
  );
};
