import { logCustomEvent, changeUser, initialize } from "@braze/web-sdk";
import * as CONSTANT from "../constants";
import { toFixedNumber } from "../priceCalculation/helper";
import { getSelectedStore, getUser } from "./helperMethods";
import { PickUpMethods } from "./tracking";

export const brazeInititalize = () => {
    if (Boolean(process.env.REACT_APP_ENABLE_BRAZE_TRACKING)) {
        initialize(process.env.REACT_APP_BRAZE_API_KEY, {
          baseUrl: process.env.REACT_APP_BRAZE_BASE_URL,
          enableLogging: Boolean(process.env.REACT_APP_BRAZE_ENABLE_CONSOLE_LOGGING),    
        })
    }     
}

export const brazeSetUser = (userId: string) => {
    if (!Boolean(process.env.REACT_APP_ENABLE_BRAZE_TRACKING)) return;
    changeUser(userId);
}
    
export const brazeLogCustomEvent = (eventName, eventProperties = null) => {
    if (!Boolean(process.env.REACT_APP_ENABLE_BRAZE_TRACKING)) return;
    if (eventProperties)
        logCustomEvent(eventName, eventProperties);
    else 
        logCustomEvent(eventName);
}

const getItemImageURL = (itemId: number) => `${CONSTANT.REACT_APP_CLOUD_FRONT}/Items/${itemId}/item-${itemId}.webp`

const getMappedObject = (item: any, isOrderCancelledEvent: boolean, isCombo: boolean = false) => {
    return {
        itemName: item.name || item.modifier_name,
        ...(!isCombo && { price: item.price }),
        ...(item.item_size && { size: item.item_size }),
        ...(!isCombo && !isOrderCancelledEvent && {
            image: getItemImageURL(item.item_id),
        }),
        quantity: item.quantity,
        ...(item.modifiers && {
        modsAdded: item?.modifiers?.map((modifier) => modifier.modifier_name),
        }),
    };
};

const getComboMappedItems = (comboItem: any, isOrderCancelledEvent: boolean) => {
    const comboMappedObject = {
        itemName: comboItem.name,
        price: comboItem.price,
        size: comboItem.item_size,
        ...(!isOrderCancelledEvent && {
            image: getItemImageURL(comboItem.item_id),
        }),
        quantity: comboItem.quantity,
        items: comboItem.modifiers.map(modifier_item => getMappedObject(modifier_item, isOrderCancelledEvent, true))
    }
    return comboMappedObject;
}

const getMappedItems = (items: any[], isOrderCancelledEvent: boolean = false) => {
    if (!items?.length) return [];
    const mappedItems = [];
    items?.forEach(item => {
        if (
            [CONSTANT.TRY_TO_COMBO_ITEM, CONSTANT.KIDS_COMBO_ITEM].includes(item.combo_type) ||
            item.isKidsCombo ||
            item.isTry2Combo ||
            item.is_kids_combo ||
            item.is_try_2_combo
            )
            mappedItems.push(getComboMappedItems(item, isOrderCancelledEvent))
        else 
            mappedItems.push(getMappedObject(item, isOrderCancelledEvent))
    });
    return mappedItems;
}

const getAccountType = (userType: string) =>
  userType === CONSTANT.GUEST_USER
    ? CONSTANT.GUEST_USER
    : CONSTANT.LOYALTY_USER;

const getIsLoyaltyRedeemed = (orderItems) => {
    return !!orderItems?.find(item => item.discount);
}

export const brazeLogCustomEventLoyaltySignIn = (isItMobile: boolean, ) => {
    const user = getUser()
    const loyaltySignInPayload = {
        CzId: user.id,
        email: user.email,
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
    }
    brazeLogCustomEvent(CONSTANT.LOYALTY_SIGN_IN, loyaltySignInPayload)
}

export const brazeLogCustomEventOrderPlaced = (order: any, isItMobile: boolean) => {
    const promiseTime = order?.promise_time?.split(" ")
    const user = getUser()
    const categoriesOrdered = order?.items?.map(item => item.category_name)
    const pickUpMethod = PickUpMethods[order?.order_types?.id]
    const orderPlacedPayload = {
        firstName: order?.customer?.first_name, 
        lastName: order?.customer?.last_name, 
        contact: order?.customer?.phone, 
        email: order?.customer?.email, 
        storeId: order?.location?.id, 
        storeName: order?.location?.name, 
        orderType: order?.order_types?.name, 
        orderDate: promiseTime?.[0],
        orderTime: promiseTime?.[1],
        zipCode: order?.customer?.address?.zipcode || order?.location?.zip, 
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
        isTipGiven: Boolean(order?.tip),
        isLoyaltyRedeemed: getIsLoyaltyRedeemed(order?.items),
        categoriesOrdered: categoriesOrdered,
        items: getMappedItems(order?.items),
        subtotal: order?.sub_total,
        tax: order?.tax,
        discount: order?.discount,
        tipAmount: order?.tip,
        ...(pickUpMethod === CONSTANT.DELIVERY && {
            deliveryFee: CONSTANT.DELIVERY_FEE,
            serviceFee: CONSTANT.SERVICE_FEE,
        }),
        total: order?.total_price,
    }
    brazeLogCustomEvent(CONSTANT.ORDER_PLACED, orderPlacedPayload)
}

export const brazeLogCustomEventOrderCancelled = (order: any, isItMobile: boolean) => {
    const promiseTime = order?.promise_time?.split(" ")
    const user = getUser()
    const categoriesOrdered = order?.items?.map(item => item.category_name)
    const pickUpMethod = PickUpMethods[order?.order_types?.id]
    const orderCancelledPayload = {
        firstName: order?.customer?.first_name, 
        lastName: order?.customer?.last_name, 
        contact: order?.customer?.phone, 
        email: order?.customer?.email, 
        storeId: order?.store_details?.id, 
        storeName: order?.store_details?.name, 
        orderType: order?.order_types?.name, 
        orderDate: promiseTime?.[0],
        orderTime: promiseTime?.[1],
        zipCode: order?.customer?.zipcode || order?.store_details?.zip, 
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
        isTipGiven: Boolean(order?.tip),
        isLoyaltyRedeemed: getIsLoyaltyRedeemed(order?.items),
        categoriesOrdered: categoriesOrdered,
        items: getMappedItems(order?.items, true),
        subtotal: order?.sub_total,
        tax: order?.tax,
        discount: order?.discount,
        tipAmount: order?.tip,
        ...(pickUpMethod === CONSTANT.DELIVERY && {
            deliveryFee: CONSTANT.DELIVERY_FEE,
            serviceFee: CONSTANT.SERVICE_FEE,
        }),
        total: order?.total_price,
    }
    brazeLogCustomEvent(CONSTANT.ORDER_CANCELLED, orderCancelledPayload)
}

interface IOrderAmount {
    tax: number;
    subTotal: number;
    total: number;
}
export const brazeLogCustomEventCheckoutStarted = (
    cart: any, 
    orderAmount: IOrderAmount, 
    isItMobile: boolean, 
    slotDetails: any,
) => {
    const user = getUser()
    const selectedStore = getSelectedStore()
    const categoriesOrdered = cart?.items?.map(item => item.category_name)
    const checkoutStartedPayload = {
        firstName: user.first_name, 
        lastName: user?.last_name, 
        contact: user?.phone, 
        email: user?.email, 
        storeId: selectedStore.id, 
        storeName: selectedStore?.name, 
        orderType: slotDetails?.pickupMethod || cart?.orderType,
        isAsapOrder: slotDetails?.isAsapOrder,
        orderDate: slotDetails?.date,
        orderTime: slotDetails?.isAsapOrder ? slotDetails?.asapMinutes : slotDetails?.time,
        zipCode: cart?.orderType === CONSTANT.DELIVERY_ORDER_TYPE ? cart?.orderDetails?.delivery_address?.zipcode : selectedStore.zip, 
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
        categoriesOrdered: categoriesOrdered,
        items: getMappedItems(cart?.items),
        subtotal: orderAmount?.subTotal,
        tax: orderAmount?.tax,
        ...( cart?.orderType === CONSTANT.DELIVERY_ORDER_TYPE && {
            deliveryFee: CONSTANT.DELIVERY_FEE,
            serviceFee: CONSTANT.SERVICE_FEE,
        }),
        total: orderAmount?.total,
    }
    brazeLogCustomEvent(CONSTANT.CHECKOUT_STARTED, checkoutStartedPayload)
}

export const brazeLogCustomEventAddedToCart = (cart: any, itemAdded: any,isItMobile: boolean) => {
    const user = getUser()
    const selectedStore = getSelectedStore()
    const addedToCartPayload = {
        storeId: selectedStore.id, 
        storeName: selectedStore?.name, 
        orderType: cart?.orderType,
        zipCode: cart?.orderType === CONSTANT.DELIVERY_ORDER_TYPE ? cart?.orderDetails?.delivery_address?.zipcode : selectedStore.zip, 
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
        items: getMappedItems(itemAdded),
        total: toFixedNumber(itemAdded[0]?.price * itemAdded[0]?.quantity),
    }
    brazeLogCustomEvent(CONSTANT.ADDED_TO_CART, addedToCartPayload)
}

export const brazeLogCustomEventViewPage = (pageName: string, isItMobile: boolean) => {
    const user = getUser()
    const viewPagePayload = {
        pageName,
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
    }
    brazeLogCustomEvent(CONSTANT.VIEW_PAGE, viewPagePayload)
}

export const brazeLogCustomEventLoyaltyAccountCreated = (userInfo) => {
    let dateOfBirth = "";
    if (userInfo.birthday) {
        const splitBirthday = userInfo.birthday.split("-");
        dateOfBirth = `${splitBirthday[1]}/${splitBirthday[2]}/${splitBirthday[0]}`;
    }
    let favoriteLocations = [];
    if (userInfo?.favorite_location)
        favoriteLocations = userInfo?.favorite_location?.map(location => location.label);
    const loyaltyAccountCreatedPayload = {
        firstName: userInfo.first_name, 
        lastName: userInfo.last_name, 
        contact: userInfo.phone || "", 
        email: userInfo.email, 
        dateOfBirth, 
        favoriteLocations,
    }      
    brazeLogCustomEvent(CONSTANT.LOYALTY_ACCOUNT_CREATED, loyaltyAccountCreatedPayload)
}

export const brazeLogCustomEventAddToFavorites = (item: any, isItMobile: boolean) => {
    const user = getUser();
    const addToFavoritesPayload ={
        itemName: item.name,
        itemTag: item.tags.map(tag => tag.name),
        itemDescription: item.description,
        itemCategory: item.category_name || "",
        price: item.price,
        image: getItemImageURL(item.id),
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
    }
    brazeLogCustomEvent(CONSTANT.ADD_TO_FAVORITES, addToFavoritesPayload);
}

export const brazeLogCustomEventFormSubmission = (formName: string, isItMobile: boolean, formFields: any) => {
    const user = getUser();
    const formSubmissionPayload = {
        formName, 
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
        data: {
            ...formFields,
        }
    }
    brazeLogCustomEvent(CONSTANT.FORM_SUBMISSION, formSubmissionPayload);
}