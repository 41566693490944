import * as Constants from '../constants';
import { IModifiersAgainstAnItem } from '../models/items.model';
import {
    IBucketSelectedIngredients
} from '../models/try2Combo';
import {
    itemBuckets
} from './buckets';
import {
    ADDITIONALPRICE,
    COMPLIMENTARY_MODIFIERS,
    ADDITIONAL_ADDED,
    REQUIRED_MODIFIERS,
    ADDITIONAL_REMOVED
} from './constants';
import {
    IAddQuantityToSelectedModifier,
    IBucket,
    ICurrentModifier,
    IItemBucket,
    IMemoryChip,
    ISingleBucket,
    IUpdateItemBucketModifier,
} from './priceCalculation';
import _clonedeep from 'lodash.clonedeep'

export const createBucketsObjects = (keys: string[]): IBucket => {
    let object: IBucket = {};
    keys.forEach((key: string) => {
        object[key] = {
            originalPrice: 0,
            additionalPrice: 0,
            max: 0,
            byDefaultAdded: {
                originalPrice: 0,
                additionalPrice: 0,
                modifiers: [],
            },
            byDefaultAddedV2: {
                modifiers: [],
            },
            additionalAdded: {
                originalPrice: 0,
                additionalPrice: 0,
                modifiers: [],
            },
            additionalRemoved: {
                originalPrice: 0,
                additionalPrice: 0,
                modifiers: [],
            },
            modifiers: [],
            writable: true,
        };
    });
    return object;
};

export const lastIndexOf = (array: any, key: any): number => {
    for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].modifier_id === key.modifier_id) return i;
    }
    return -1;
};

export const isExistAsByDefault = (
    modifiers: IBucketSelectedIngredients[],
    currentModifier: ICurrentModifier
): boolean => {
    let flag = false;
    for (let index = 0; index < modifiers.length; index++) {
        if (modifiers[index].modifier_id === currentModifier.modifier_id) {
            flag = true;
            break;
        }
    }
    return flag;
};

export const isModifierGroupExistInMemoryChip = (
    modifier_group_id: number,
    fromItem: number
): boolean => {
    const memoryChip: IMemoryChip = itemBuckets.getSingleBucket(
        {name:Constants.CORE_MODIFIERS,
        fromItem, isFromItemComingAsAnIndex:true}
    )?.memoryChip;
    if(memoryChip){
        if (
            memoryChip[modifier_group_id] === undefined ||
            memoryChip[modifier_group_id] === null
        ) {
            return false;
        } else {
            return true;
        }
    }else{
        return false
    }
};

export const temporarilyAddedPrice = (
    oldBucket: IItemBucket[],
    newBucket: IItemBucket[],
    item: string
): number => {
    let oldBucketAdditionalprice: number = 0;
    let newBucketAdditionalprice: number = 0;
    oldBucket.forEach((currentItem: IItemBucket) => {
        if (currentItem.item.toString() === item) {
            oldBucketAdditionalprice = itemAdditionalPrice(
                oldBucketAdditionalprice,
                currentItem,
                Constants.ADD_ONS,
                ADDITIONALPRICE
            );
            oldBucketAdditionalprice = itemAdditionalPrice(
                oldBucketAdditionalprice,
                currentItem,
                Constants.REQUIRED_MODIFIERS,
                ADDITIONALPRICE
            );
            oldBucketAdditionalprice = itemAdditionalPrice(
                oldBucketAdditionalprice,
                currentItem,
                Constants.CORE_MODIFIERS,
                ADDITIONALPRICE
            );
            oldBucketAdditionalprice = itemAdditionalPrice(
                oldBucketAdditionalprice,
                currentItem,
                Constants.COMPLIMENTARY_MODIFIER,
                ADDITIONALPRICE
            );
        }
    });
    newBucket.forEach((currentItem: IItemBucket) => {
        if (currentItem.item.toString() === item) {
            newBucketAdditionalprice = itemAdditionalPrice(
                newBucketAdditionalprice,
                currentItem,
                Constants.ADD_ONS,
                ADDITIONALPRICE
            );
            newBucketAdditionalprice = itemAdditionalPrice(
                newBucketAdditionalprice,
                currentItem,
                Constants.REQUIRED_MODIFIERS,
                ADDITIONALPRICE
            );
            newBucketAdditionalprice = itemAdditionalPrice(
                newBucketAdditionalprice,
                currentItem,
                Constants.CORE_MODIFIERS,
                ADDITIONALPRICE
            );
            newBucketAdditionalprice = itemAdditionalPrice(
                newBucketAdditionalprice,
                currentItem,
                Constants.COMPLIMENTARY_MODIFIER,
                ADDITIONALPRICE
            );
        }
    });
    return toFixedNumber(newBucketAdditionalprice - oldBucketAdditionalprice);
};

export const itemAdditionalPrice = (
    bucketAdditionalPrice: number,
    currentItem: IItemBucket,
    modifiers_type: string,
    priceType: string
): number => {
    let price: number = 0;
    let currentItemAdditionalPrice: number =
        currentItem.bucket[modifiers_type][priceType];
    if (currentItemAdditionalPrice > 0) {
        price = toFixedNumber(bucketAdditionalPrice + currentItemAdditionalPrice);
    } else {
        price = bucketAdditionalPrice;
    }
    return price;
};

export const toFixedNumber = (number: number): number => {
    return Number(number?.toFixed(2));
};

export const toFixedNumberDecimal = (number) => {
    return (Math.round(number * 100) / 100).toFixed(2)
};

export const floorNumberToDecimal = (number) => {
    let decimalPoints = number.toString().split('.')[1]
    if(decimalPoints?.length >  2)
    {
        return Math.floor(number* Math.pow(10, 2))/(Math.pow(10, 2));
    }
    else {
        return number
    }
}

export const roundToTwo = (number) => {
    return Number(Math.round(Number(number+'e'+2))+'e-'+2);
}
export const assignTypeToSelectedModifier = (
    modifier: ICurrentModifier
): void => {
    if (modifier.quantity === 0) {
        modifier.type = Constants.DECREASE;
    } else {
        modifier.type = Constants.INCREASE;
    }
};

export const keysOfAnObject = (object) => {
    return Object.keys(object);
};

export const retriveModifiersAgainstAnObjectKeys = (keys, bucket) => {
    const modifiers = [];
    for (let i = 0; i < keys.length; i++) {
        modifiers.push.apply(modifiers, bucket[keys[i]].modifiers);
    }
    return modifiers;
};

export const modifiersAgainstAnItem = ({itemType, itemId}: IModifiersAgainstAnItem) => {
    const bucket = _clonedeep(itemBuckets.getSingleItem({itemType, itemNo: itemId})?.bucket);
    const objectKeys = keysOfAnObject(bucket);
    return retriveModifiersAgainstAnObjectKeys(objectKeys, bucket)
}

export const updateItemBucketModifier = ({modifier_type, modifier, fromItem, treat_as}: IUpdateItemBucketModifier) => {
    itemBuckets.updateSpecificItemBucketModifiers(
        {name:modifier_type,
        modifier,
        fromItem}
    );
    itemBuckets.updateSpecificItemBucket({
        name:modifier_type,
        modifier,
        fromItem,
        as:treat_as
    }
    );
}

export const updateItemBucketCoreAndAddonsModifier = (modifier: ICurrentModifier, fromItem, treat_as: string) => {
  if (
      modifier?.modifier_type === Constants.ADD_ONS &&
      modifier?.treat_as !== Constants.CORE_RELATED
  ) {
      updateItemBucketModifier({modifier_type:Constants.ADD_ONS, modifier, fromItem, treat_as});
  }
  if (
      modifier?.modifier_type === Constants.CORE_MODIFIERS ||
      modifier?.treat_as === Constants.CORE_RELATED
  ) {
      updateItemBucketModifier({modifier_type:Constants.CORE_MODIFIERS, modifier, fromItem, treat_as});
  }
}

export const updatingItemBucketModifiers = (modifier: ICurrentModifier, fromItem: number, treat_as: string) => {
    if (modifier?.complimentary_modifier) {
        updateItemBucketModifier({modifier_type:COMPLIMENTARY_MODIFIERS, modifier, fromItem, treat_as});
    }
    if (
        modifier?.modifier_type ===
        Constants.REQUIRED_MODIFIERS
    ) {
        updateItemBucketModifier({modifier_type:REQUIRED_MODIFIERS, modifier, fromItem, treat_as});
    }
    if (
        modifier?.modifier_type === Constants.CORE_MODIFIERS ||
        modifier?.modifier_type === Constants.ADD_ONS
    ) {
      updateItemBucketCoreAndAddonsModifier(modifier,fromItem,treat_as)
    }
}

export const addQuantityToSelectedModifier = ({modifier_type, type, modifier, fromItem} : IAddQuantityToSelectedModifier) => {
    if (type === Constants.INCREASE) {
        modifier.quantity = 1;
        updateItemBucketModifier({modifier_type, modifier, fromItem, treat_as: ADDITIONAL_ADDED})
    } else {
        modifier.quantity = 0;
        updateItemBucketModifier({modifier_type, modifier, fromItem, treat_as: ADDITIONAL_REMOVED})
    }
}

export const updatingItemBucketTypeSelectedCoreAndAddonsModifiers = (modifier: ICurrentModifier, fromItem) => {
  if (
      modifier?.modifier_type === Constants.ADD_ONS &&
      modifier?.treat_as !== Constants.CORE_RELATED
  ) {
      const type = itemBuckets.selectedModifierType(
          Constants.ADD_ONS,
          modifier,
          fromItem
      );
      addQuantityToSelectedModifier({modifier_type: Constants.ADD_ONS, type, modifier, fromItem})
  }
  if (
      modifier?.modifier_type === Constants.CORE_MODIFIERS ||
      modifier?.treat_as === Constants.CORE_RELATED
  ) {
      const type = itemBuckets.selectedModifierType(
          Constants.CORE_MODIFIERS,
          modifier,
          fromItem
      );
      addQuantityToSelectedModifier({modifier_type: Constants.CORE_MODIFIERS, type, modifier, fromItem})
  }
}

export const updatingItemBucketTypeSelectedModifiers = (modifier: ICurrentModifier, fromItem: number) => {
    if (
        modifier?.modifier_type ===
        Constants.REQUIRED_MODIFIERS
    ) {
        const type = itemBuckets.selectedModifierType(
            REQUIRED_MODIFIERS,
            modifier,
            fromItem
        );
        addQuantityToSelectedModifier({modifier_type: REQUIRED_MODIFIERS, type, modifier, fromItem})
    }
    if (
        modifier?.modifier_type === Constants.CORE_MODIFIERS ||
        modifier?.modifier_type === Constants.ADD_ONS
    ) {
      updatingItemBucketTypeSelectedCoreAndAddonsModifiers(modifier,fromItem)
    }
}

export const basePrice = (currentModifier: ICurrentModifier, byDefaultSelectedModifiers: ICurrentModifier[]) => {
    return byDefaultSelectedModifiers.find((modifier: ICurrentModifier) => modifier.modifier_group_id === currentModifier.modifier_group_id)?.display_price;
}

export const addComplementaryPrice = (currentModifier: ICurrentModifier) => {
    if (currentModifier.quantity === 1 && currentModifier.type === Constants.INCREASE) {
        return false;
    }
    return true;
}

export const resetAdditionalPrice = (currentModifier: ICurrentModifier) => {
    if (
        currentModifier.additionalPrice === undefined ||
        currentModifier.additionalPrice === null
    ) {
        return true;
    } else {
        return false;
    }
};

export const addAdditionalPriceBasedOnActionType = (
    addedModifier: ICurrentModifier,
    currentModifier: ICurrentModifier,
    type: string
) => {
    if (type === Constants.INCREASE) {
        return toFixedNumber(
            currentModifier.additionalPrice + addedModifier.display_price
        );
    }
    if (type === Constants.DECREASE) {
        return toFixedNumber(
            currentModifier.additionalPrice - addedModifier.display_price
        );
    }
};

export const getSelectedBucketModifiers = (bucket:ISingleBucket) => {
    let modifiers:ICurrentModifier[] = _clonedeep(bucket?.modifiers);
    modifiers =[...modifiers,...bucket[ADDITIONAL_REMOVED]?.modifiers];
    return modifiers 
}

export const findModifierIndexBasedOnId = (modifiers:ICurrentModifier[], modifier:ICurrentModifier) => {
    return modifiers.findIndex((currentModifier: ICurrentModifier) => currentModifier.modifier_id === modifier.modifier_id)
}