import axios, { CancelTokenStatic, CancelTokenSource } from 'axios'
import { successResponseHandler } from '../axios/interceptors';
import { errorResponseHandler, requestHandler } from './interceptor';

export class HttpService {
	CancelToken: CancelTokenStatic
	source: CancelTokenSource
	baseURL: string
	instance: any;
	
	constructor() {
		this.CancelToken = axios.CancelToken
		this.source = this.CancelToken.source()
        this.baseURL = process.env.REACT_APP_SERVER_API;
		// Add a request, response interceptor
		(axios as any).interceptors.request.use(requestHandler);
		(axios as any).interceptors.response.use(successResponseHandler, errorResponseHandler)
	}

	/**
	 * Set Token On Header
	 * @param token
	 */
	static setToken(token: string | any, authentication_token: string | any ): void {
		(axios as any).defaults.headers['Authorization'] = `Bearer ${token}`;
		(axios as any).defaults.headers['token'] = `${authentication_token}`
	}

	/**
	 * Fetch data from server
	 * @param url Endpoint link
	 * @return Promise
	 */
	protected get = async (url: string, params?: any): Promise<any> =>
        await axios.get(`${this.baseURL}/${url}`, {
			params,
			cancelToken: this.source.token,
		})

	/**
	 * Write data over server
	 * @param url Endpoint link
	 * @param body Data to send over server
	 * @return Promise
	 */
	protected post = async (url: string, body: any, options = {}): Promise<any> => 
		await axios.post(`${this.baseURL}/${url}`, body, {
			...options,
			cancelToken: this.source.token,
		})

	/**
	 * Delete Data From Server
	 * @param url Endpoint link
	 * @param params Embed as query params
	 * @return Promise
	 */
	protected delete = (url: string, params?: any, data?: any): Promise<any> =>
		axios.delete(`${this.baseURL}/${url}`, { params, data })

	/**
	 * Update data on server
	 * @param url Endpoint link
	 * @param body Data to send over server
	 * @param params Embed as query params
	 * @return Promise
	 */
	protected put = (url: string, body?: any, params?: any): Promise<any> =>
		axios.put(`${this.baseURL}/${url}`, body, {
			...params,
			cancelToken: this.source.token,
		})

    /**
	 * Update data over server
	 * @param url Endpoint link
	 * @param body Data to send over server
	 * @return Promise
	 */
	protected patch = async (url: string, body: any, options = {}): Promise<any> => 
        await axios.patch(`${this.baseURL}/${url}`, body, {
			...options,
			cancelToken: this.source.token,
		})

	private updateCancelToken() {
		this.source = this.CancelToken.source()
	}

	cancel = () => {
		this.source.cancel('Explicitly cancelled HTTP request')
		this.updateCancelToken()
	}
}