import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styleClasses from "./liveTracking.module.scss";
import { isOrderCancelledOrFailed, PickUpMethods } from "../../helpers/tracking";
import useOrderDetails from "../../react-query-hooks/useGetOrderDetails";
import Spinner from "../Spinner/Spinner";
import { config } from "aws-sdk";
import OrderDetailsColumn from "./OrderDetailsColumn";
import OrderProcess from "./OrderProcess";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { IDoorDash, IOrderStatus } from "../../models/tracking";
import Scrollbars from "react-custom-scrollbars";

interface ILiveTrackingProps {
  orderIds: number[];
  handleClose: () => void;
  isFetchingOrders: boolean;
  titlespace?: string;
  selectedOrderNo: number;
  handleMobileOrderBackward: () => void;
  setIsActiveOrders: (boolean) => void
}


const Livetracking: React.FC<ILiveTrackingProps> = ({ orderIds, isFetchingOrders, handleClose, selectedOrderNo, handleMobileOrderBackward, titlespace, setIsActiveOrders }) => {
  const isItMobile = useCheckMobileScreen();
  const [orderNo, setOrderNo] = useState<number>(selectedOrderNo || 0)
  const {
    data: order,
    isFetching,
    isLoading,
    refetch,
  } = useOrderDetails({ orderId: orderIds[orderNo], isFetchingOrderHistory: isFetchingOrders });

  useEffect(() => {
    if (!isFetchingOrders)
      refetch();
  }, [orderNo, isFetchingOrders, selectedOrderNo]);

  const [orderStatus, setOrderStatus] = useState<IOrderStatus | null>({ status: "" });
  const finalOrderStatus =
    orderStatus?.status || order?.tracking_details?.status;

  const [doorDash, setDoorDash] = useState<IDoorDash | null>(null);
  const finalDoorDash: IDoorDash = doorDash || order?.delivery_details
  const doorDashStatus = finalDoorDash?.status

  const canGoBackward = orderNo > 0;
  const canGoForward = orderNo < orderIds?.length - 1;

  const pickUpMethod = PickUpMethods[order?.destination_id];
  config.update({
    region: process.env.REACT_APP_AWS_SQS_REGION,
    // accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    // secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  });

  const cartItems = order?.items;

  const handleForward = () => {
    if (canGoForward) setOrderNo(orderNo + 1);
  };

  const handleBackward = () => {
    if (canGoBackward) setOrderNo(orderNo - 1);
  };

  const isInactiveOrder = isOrderCancelledOrFailed(finalOrderStatus);

  if ((isFetching && isLoading) || isFetchingOrders || !order)
    return <Spinner modalSpinner={true} className={styleClasses.live_tracker_loader} />;

  return (
    <div className={`${styleClasses.live_tracking_modal} mt-1`}>
      {isItMobile && (
        <button
          onClick={handleMobileOrderBackward}
          className={`btn btn-custom f-s14  d-flex align-items-center gap-2  ${styleClasses.back_btn}`}
        >
          <img src={require("../../../src/images/arrow-back.svg")} alt="arrow back" />
          Back
        </button>
      )}
      <div className={styleClasses.Live_tracking_products_Scrollbar}>
        <Row className="flex-column flex-md-row">
          <Col className={`${styleClasses.border_column} `}>
            {canGoBackward && (
              <button className={`btn btn-custom  f-s14  d-md-block d-none ${styleClasses.back_icon}`}
              onClick={handleBackward}>
                <img
                  className={`cursor-pointer`}
                  src={require("../../../src/images/arrow-back.svg")}
                  onClick={handleBackward}
                  alt="arrow back"
                />
              </button>

            )}
            <OrderProcess
              order={order}
              orderId={orderIds[orderNo]}
              isFetching={isFetching}
              orderStatus={orderStatus}
              setOrderStatus={setOrderStatus}
              doorDash={doorDash}
              setDoorDash={setDoorDash}
              isOrderCancelledOrFailed={isInactiveOrder}
            />
          </Col>
          <Col className="px-0">
            {canGoForward && (
              <button className={`btn btn-custom  f-s14  d-md-block d-none ${styleClasses.front_icon}`}
                onClick={handleForward}>
                <img
                  className={`  cursor-pointer`}
                  src={require("../../../src/images/arrow-right.svg")}
                  alt="arrow back"

                />
              </button>

            )}
            <OrderDetailsColumn
              isOrderCancelledOrFailed={isInactiveOrder}
              doorDashStatus={doorDashStatus}
              cartItems={cartItems}
              order={order}
              pickUpMethod={pickUpMethod}
              refetch={refetch}
              handleClose={handleClose}
              orderNo={orderNo}
              titlespace={titlespace}
              setIsActiveOrders={setIsActiveOrders}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Livetracking;
